import React, { useEffect, useState } from "react";
import ToggleSideNav from "../UI/ToggleSideNav/ToggleSideNav";
import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
} from "@react-google-maps/api";
import userMethods from "../../services/userApi";

import markerIcon from "../../assets/images/device.svg";

function Tracking() {
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [records, setRecords] = useState([]);
  const mapContainerStyle = {
    height: "380px",
    width: "100%",
    borderRadius: 10,
  };

  const onMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const onCloseClick = () => {
    setSelectedMarker(null);
  };
  const getMapData = () => {
    userMethods
      // .getDeviceGPS(this.state.device_id)
      .getDeviceGPS()
      .then((result) => {
        setRecords(result.data.result ? result.data.result : []);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getMapData();
  }, []);

  return (
    <div className="routing-main-wrap">
      <div className="page-head">
        <h3>Tracking</h3>
        <ToggleSideNav batteryRefresh="false" />
      </div>
      <div className="rout-body">
        <div
          className={`map-container ${false ? "fullscreen" : ""}`}

          // style={{
          //   height: "480px",
          //   width: "900px",
          //   cursor: "pointer",
          // }}
        >
          <LoadScript
            googleMapsApiKey={"AIzaSyCz0pGDqkf3uB_x9qJfoLAANO-lqpuLKE8"}
          >
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={{
                lat: parseFloat(
                  records[0]?.latitude ? records[0]?.latitude : "45.4046987"
                ),
                lng: parseFloat(
                  records[0]?.longitude ? records[0]?.longitude : "12.2472504"
                ),
              }}
              zoom={2}
              options={{ maxZoom: 18, minZoom: 3 }}
            >
              {records?.map((marker, index) => (
                <Marker
                  key={index}
                  position={{
                    lat: parseFloat(marker.latitude),
                    lng: parseFloat(marker.longitude),
                  }}
                  onClick={() => onMarkerClick(marker)}
                  icon={markerIcon}
                />
              ))}

              {selectedMarker && (
                <InfoWindow
                  position={{
                    lat: parseFloat(selectedMarker.latitude),
                    lng: parseFloat(selectedMarker.longitude),
                  }}
                  onCloseClick={onCloseClick}
                >
                  <div className="map-modal">
                    <div className=" mt-2">
                      <div>
                        <div class="cus-dark-table">
                          {" "}
                          <div>
                            <label
                              style={{
                                fontSize: "12px",
                                color: "rgba(255, 255, 255, 0.4)",
                                fontFamily: "var(--fontregular)",
                                fontWeight: "normal",
                                padding: "10px",
                              }}
                            >
                              Address :{" "}
                              <span style={{ color: "white" }}>
                                {selectedMarker?.location}
                              </span>
                            </label>
                          </div>
                          <table>
                            <thead>
                              <tr>
                                <th>Device ID</th>
                                <th>Device Name</th>
                                <th>Enpoint Count</th>
                                <th>Device Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {selectedMarker.devices?.map((record) => (
                                <tr>
                                  <td> {record.device_id}</td>
                                  <td> {record.device_name}</td>
                                  <td> {record.endpoint_count}</td>

                                  <td
                                    className={
                                      record.status == "In Active"
                                        ? "text-danger"
                                        : "text-success"
                                    }
                                  >
                                    {record.status}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </InfoWindow>
              )}
            </GoogleMap>
          </LoadScript>
        </div>
      </div>
    </div>
  );
}

export default Tracking;
