import React, { Component, useState, useEffect } from "react";
import ToggleSideNav from "../../UI/ToggleSideNav/ToggleSideNav";
import BackButton from "../../UI/BackButton/BackButton";
import { withRouter } from "react-router-dom";
import logo from "../../../assets/images/cervais-s-logo.svg";
import fileSharingMethods from "../../../services/fileSharingApi";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { IconButton } from "@material-ui/core";
import PaymentModal from "./PaymentModal";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import battery from "../../../assets/images/battery.png";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Upgrade extends Component {
  state = {
    device_id: this.props.location.state.device_id,
    storagePlans: [], // storage plans for the user
    message: "",
    open: false,
    editModal: false,
    ConfirmationModal: false,
    modalTitle: "Payment", // title of the modal
    loader: false,
    clientSecret: "", //from api request
    publicKey: "", // from api request
    creditBalance: "", // from api request
    price: "",
    trype: "",
    plan_name: "",
  };

  componentDidMount = async () => {
    this.storagePlans();
  };

  handleClose = () => {
    this.setState({ editModal: false, ConfirmationModal: false });
  };

  triggerModal = (price, type) => {
    console.log(price, type);
    this.setState({
      ConfirmationModal: true,
      price: price.price_id,
      type: type,
      plan_name: price.product_name,
    });
  };

  handleOpen = async () => {
    this.setState({
      loader: true,
    });
    let pathObject = {
      price_id: this.state.price,
      device_id: this.state.device_id,
    };

    const response = await fileSharingMethods.stripePayment(pathObject);

    if (response.data.statusCode === 200 && response.data.message === "normal") {
      this.setState({
        clientSecret: response.data.result.paymentIntent,
        publicKey: response.data.result.publishableKey,
        loader: false,
      });
    }
    if (this.state.clientSecret != null) {
      this.setState({ editModal: true });
      if (this.state.type === "Payment") {
        this.setState({
          modalTitle: "Payment",
        });
      }
    } else if (this.state.clientSecret == null) {
      this.setState({ open: true, message: "Payment Sucessfull!" });
      this.props.history.replace({
        pathname: "/dashboard/dataSharing",
      });
    }
    if (response.data.statusCode === 200 && response.data.message === "upgrade") {
      this.setState({ open: true, message: response.data.result.type });
    }
    // else this.setState({ editModal: false, ConfirmationModal: false, open: true, message: response.data.message });
  };

  appearance = {
    theme: "stripe",
  };

  options = {
    // clientSecret: this.state.clientSecret,
    appearance: this.appearance,
  };

  storagePlans = async () => {
    let pathObject = {
      device_id: this.state.device_id,
    };
    const response = await fileSharingMethods.storagePlans(pathObject);
    if (response.data.statusCode === 200) {
      this.setState({
        storagePlans: response.data.result,
        creditBalance: response.data.result[0].balance,
      });
    }
  };

  render() {
    return (
      <div className="routing-main-wrap">
        <Snackbar
          open={this.state.open}
          autoHideDuration={3000}
          onClose={() => this.setState({ open: false })}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert
            // onClose={handleClose}
            severity="info"
            sx={{ width: "100%" }}
          >
            {this.state.message}
          </Alert>
        </Snackbar>

        {/* -----------------------------------------ConfirmModal */}
        {this.state.ConfirmationModal ? (
          <Modal
            open={this.state.ConfirmationModal}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <div className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw" role="document">
              <div className="modal-content modal_fp_content">
                <div className="modal-header forget_pw_header">
                  <button
                    type="button"
                    className="close modal_fp_close_btn"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.handleClose}
                  >
                    <span aria-hidden="true">
                      <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2.91937 9.00008L9.82886 1.70718L8.48899 0.292969L0.23964 9.00008L8.48899 17.7072L9.82886 16.293L2.91937 9.00008Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </button>
                  <h5 className="modal-title modal_fp_title " id="exampleModalLabel">
                    Confirm
                  </h5>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "10px ",
                    color: "white",
                    flexDirection: "column",
                  }}
                >
                  Are you sure you want to subscribe to the below cloud storage plan? <br />
                  <br />
                  <text style={{ color: "#83D5F2" }}>{this.state.plan_name}</text>
                </div>
                <div className="modal-body fp_modal_body">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body">
                    <div className="fp_label">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          paddingTop: "20px",
                        }}
                      >
                        <button
                          type="button"
                          // className="btn-fp-footer btn-Reset"
                          style={{
                            backgroundColor: "#c12a37",
                            color: "white",
                            borderRadius: "10px",
                            height: "38px",
                            width: "150px",
                            border: "1px solid #c12a37",
                          }}
                          onClick={this.handleOpen}
                        >
                          Confirm 
                          {/* {this.state.loader ? <i className="fa fa-spinner fa-spin"></i> : null} */}
                        </button>
                        <button
                          type="button"
                          // className="btn-fp-footer btn-Reset"
                          style={{
                            backgroundColor: "#c12a37",
                            color: "white",
                            borderRadius: "10px",
                            height: "38px",
                            width: "150px",
                            border: "1px solid #c12a37",
                          }}
                          onClick={this.handleClose}
                        >
                          Cancel 
                          {/* {this.state.loader ? <i className="fa fa-spinner fa-spin"></i> : null} */}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}

        {/* ------------------------------------------------PaymentModal */}
        {this.state.editModal ? (
          <Modal
            open={this.state.editModal}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <div className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw" role="document">
              <div style={{ backgroundColor: "white", color: "white" }} className="modal-content modal_fp_content">
                <div className="modal-header forget_pw_header">
                  <h5 div={{ color: "black" }} className="modal-title modal_fp_title " id="exampleModalLabel">
                    {this.state.modalTitle}
                  </h5>
                  <IconButton
                    style={{ fontSize: 48, color: "white" }}
                    aria-label="Close"
                    onClick={() => {
                      this.handleClose();
                      this.setState({ open: true, message: "Payment canceled" });
                    }}
                  >
                    <CloseRoundedIcon size="large" />
                  </IconButton>
                </div>
                <div className="modal-body fp_modal_body">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body">
                    <div className="fp_label">
                      {this.state.clientSecret && (
                        <Elements
                          options={{
                            clientSecret: this.state.clientSecret,
                            // options: this.options,
                            appearance: { theme: "stripe" },
                          }}
                          stripe={loadStripe(this.state.publicKey)}
                        >
                          <PaymentModal />
                        </Elements>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}
        <div className="page-head">
          <div className="page_head_btn ">
            <div className="aboutus-flex d-flex align-items-center">
              <BackButton getPath="/dashboard/dataSharing" count={0} />
              <h3 className="pd-l10">Storage Plan Upgrade</h3>
            </div>
          </div>
          {/* <ToggleSideNav batteryRefresh="false" /> */}
          <div>
            {/* <AddBoxIcon
            aria-owns={anchorEl ? "simple-menu" : undefined}
            aria-haspopup="true"
            onClick={this.handleClick}
          /> */}
            {/* Open Menu
        </Button> */}
            {/* -------------------------------header */}
            <div
              style={{
                display: "flex",
                width: 200,
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              <div style={{ color: "white" }}>Credit Balance : ${this.state.creditBalance}</div>
              {/* <img
                src={battery}
                alt="battery"
                height="25px"
                width="25px"
                align="left"
                style={{ paddingRight: "10px" }}
              /> */}
              <button type="button" className="menu-toggle-btn" onClick={this.toggleSideNav}>
                <svg
                  className="toggle-icon"
                  width="18"
                  height="12"
                  viewBox="0 0 18 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 12H9V10H0V12Z" fill="#E5E5E5" />
                  <path d="M0 7H18V5H0V7Z" fill="#E5E5E5" />
                  <path d="M9 0V2H18V0H9Z" fill="#E5E5E5" />
                </svg>
                <svg
                  className="close-menu"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                    fill="#ffffff"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        {this.state.storagePlans.length ? (
          this.state.storagePlans.map((data, index) => (
            // <div key={data.product_id} className="col-md-12">
            //   <div
            //     className="rout-body"
            //     onClick={() => this.handleOpen(data.price_id, "payment")}
            //   >
            //     <div className="row">
            //       <div className="col-12 d-flex mr-t50">
            //         <div className="device-details col-5 text-center">
            //           <img
            //             src={logo}
            //             alt="cervais"
            //             title="cervais"
            //             style={{ height: "40px" }}
            //           />

            //           <div className="registered-title mr-t30">
            //             <h6>{data.product_name} </h6>
            //           </div>
            //           <div className="upgrade-title mr-t20">
            //             <h6> $ {data.amount / 100} </h6>
            //           </div>
            //         </div>
            //         {/* <div className="col-2"></div> */}
            //       </div>
            //     </div>
            //   </div>
            // </div>
            <div class="row rout-body">
              <div class="col-sm-12" key={data.product_id}>
                <div class="card device-details text-center" onClick={() => this.triggerModal(data, "payment")}>
                  <div class="card-body ">
                    <img src={logo} alt="cervais" title="cervais" style={{ height: "40px" }} />
                    {data.active === 1 ? (
                      <div
                        style={{
                          color: "#00c853",
                          position: "absolute",
                          top: 20,
                          right: 25,
                        }}
                      >
                        Active
                      </div>
                    ) : null}
                    <div className="registered-title mr-t20">
                      <h6>{data.product_name} </h6>
                    </div>
                    <div className="upgrade-title mr-t20">
                      <h6> $ {data.amount / 100} </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <CircularProgress color="secondary" style={{ margin: "0% 0% 0% 50%" }} />
        )}
      </div>
    );
  }
}

export default withRouter(Upgrade);
