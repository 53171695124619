import React from "react";
import InfoIcon from "@material-ui/icons/Info";
import { IconButton, Tooltip } from "@material-ui/core";
const CustomToolTip = ({ message, ...rest }) => {
  return (
    <Tooltip title={message} arrow {...rest}>
      {/* <IconButton> */}
      <InfoIcon />
      {/* </IconButton> */}
    </Tooltip>
  );
};

export default CustomToolTip;
