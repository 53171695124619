import React, { Component } from "react";
import ToggleSideNav from "../UI/ToggleSideNav/ToggleSideNav";
import ReactApexChart from "react-apexcharts";
import tower from "../../assets/images/tower.png";
import wired_img from "../../assets/images/wired_img.png";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import moment from "moment";
import userMethods from "../../services/userApi";
import Modal from "@material-ui/core/Modal";
import test from "../mqtt/mqtt";
import mqtt from "mqtt";
import userApi from "../../services/userApi";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CustomToolTip from "../Common/CustomToolTip";
import {
  bandWidth,
  blockedDevices,
  connectedDevices,
  wirelessNetwork,
} from "../VulnerabilityScan/messages";
import { useAtom } from "jotai";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Home extends Component {
  state = {
    series: [],
    options: {},
    seriesDonut: [],
    optionsDonut: {},
    dashboardType: "day",
    showDate: true,
    displayedDate: moment(new Date()).format("MM/DD/yyyy"),
    showWeek: false,
    weeklyDate: "",
    showMonth: false,
    currentMonth: moment().format("MMMM"),
    globalDate: new Date(),
    startDate: moment(new Date()).format("MM/DD/yyyy"),
    endDate: moment(new Date()).format("MM/DD/yyyy"),
    networkType: "AllNetworks",
    barchartData: [],
    showChart: false,
    device_id: JSON.parse(window.sessionStorage.getItem("ConnectedDevice"))
      ?.device_id,
    bandwidthData: {},
    iotDevices: [],
    blocked_devices: [],
    btnLoader: false,
    showBlockTimeModal: false,
    blockDeviceList: {},
    blockingTime: "",
    connectedDevices: [],
    errorModal: "",
    lan_data_usage: "",
    cellular_data_usage: "",
    batteryData: "",
    deviceDetails: "",
    role_id: "",
    showLogout: false,
    open: false,
    message: "",
  };

  componentDidMount() {
    this.fetchAllDriskDevices();
    this.getGraphData();
    this.getHomeData();
    // this.run();
    this.test();
  }

  mqttResponse = () => {
    this.fetchAllDriskDevices();
    this.getGraphData();
    this.getHomeData();
  };

  run = () => {
    var mosquitto = mqtt.connect(`${process.env.REACT_APP_NODE_MQTT_URL}`, {
      port: 8083,
      username: "mqttdonald",
      password: "mqttdonald",
      clean: true,
      retain: false,
    });
    var device_id = JSON.parse(
      window.sessionStorage.getItem("ConnectedDevice")
    )?.device_id;
    // console.log(device_id);
    mosquitto.on("connect", function () {
      var successTopic = device_id + "/device/app";
      mosquitto.subscribe(successTopic, { retain: false, clean: true, qos: 2 });
      // console.log(successTopic);
    });
    mosquitto.on("message", (topic, sub_message) => {
      var payload = sub_message.toString();
      // console.log(JSON.parse(payload).value);
      if (payload) {
        sessionStorage.setItem("BatteryData", JSON.parse(payload).value);
      }
      // this.setState({
      //   batteryData: JSON.parse(payload).value,
      // });
    });
    // console.log(window.sessionStorage.getItem("BatteryData"));
  };

  test = () => {
    var mosquitto = mqtt.connect(`${process.env.REACT_APP_NODE_MQTT_URL}`, {
      port: 8083,
      username: "mqttdonald",
      password: "mqttdonald",
      clean: true,
      retain: false,
    });
    var user_id = JSON.parse(
      window.sessionStorage.getItem("UserDetails")
    )?.user_id;
    mosquitto.on("connect", function () {
      var successTopic = user_id + "/device/app";
      mosquitto.subscribe(successTopic, { retain: false, clean: true, qos: 2 });
      // console.log(successTopic);
    });

    mosquitto.on("message", function (topic, sub_message) {
      var payload = sub_message.toString();
      // console.log(payload);
      if (payload) {
        // new Home().fetchAllDriskDevices();
        // new Home().getGraphData();
        // new Home().getHomeData();
        window.location.reload();
      }
    });
  };

  fetchAllDriskDevices = async () => {
    // console.log("refresh");
    const response = await userMethods.getAllDriskDevices();
    var connectedArray = [];

    if (response.data.statusCode === 200) {
      var result = response.data.result.device_details;
      result.forEach((element) => {
        if (element.is_connected === "connected") {
          connectedArray.push(element);
        }
      });
      {
        connectedArray[0] != null
          ? this.setState({
              connectedDevices: connectedArray[0].name,
              role_id: connectedArray[0].role_id,
            })
          : this.setState({
              connectedDevices: "",
            });
      }
      // console.log(this.state.deviceDetails);
      if (this.state.role_id) {
        sessionStorage.setItem("role_id", this.state.role_id);
      }
      // console.log(JSON.parse(window.sessionStorage.getItem("role_id")));
      this.props.history.replace({
        pathname: "/dashboard/Home",
      });
    }
    if (
      response.data.statusCode === 401 &&
      response.data.message == "Session expires. Please login again"
    ) {
      this.setState({
        showLogout: true,
      });
    }
    // window.sessionStorage.clear();
  };

  logoutUser = () => {
    window.sessionStorage.clear();
    this.props.history.replace({
      pathname: "/",
    });
  };

  changeToPreviousDay = async () => {
    await this.setState({
      displayedDate: moment(new Date(this.state.displayedDate))
        .subtract(1, "day")
        .format("MM/DD/yyyy"),
    });

    this.getGraphData();
  };

  changeToNextDay = async () => {
    await this.setState({
      displayedDate: moment(new Date(this.state.displayedDate))
        .add(1, "days")
        .format("MM/DD/yyyy"),
    });

    this.getGraphData();
  };

  changeToPreviousMonth = () => {
    const additionOfMonths = 1;
    this.state.globalDate.setMonth(
      this.state.globalDate.getMonth() - additionOfMonths
    ); // For subtract use minus (-)

    this.setState({
      currentMonth: moment(this.state.globalDate.toString()).format("MMMM"),
    });

    this.getGraphData();
  };

  changeToNextMonth = () => {
    const additionOfMonths = 1;
    this.state.globalDate.setMonth(
      this.state.globalDate.getMonth() + additionOfMonths
    ); // For add use plus (+)

    this.setState({
      currentMonth: moment(this.state.globalDate.toString()).format("MMMM"),
    });

    this.getGraphData();
  };

  changeDashboardData = async (event) => {
    if (event.target.value === "day") {
      await this.setState({
        showWeek: false,
        showDate: true,
        showMonth: false,
        displayedDate: moment(new Date()).format("MM/DD/yyyy"),
        dashboardType: event.target.value,
      });

      this.getGraphData();
    } else if (event.target.value === "yesterday") {
      await this.setState({
        showWeek: false,
        showDate: true,
        showMonth: false,
        displayedDate: moment(new Date())
          .subtract(1, "day")
          .format("MM/DD/yyyy"),
        dashboardType: event.target.value,
      });

      this.getGraphData();
    } else if (event.target.value === "weekly") {
      var currentDate = moment();
      var weekStart = currentDate.clone().startOf("isoWeek");
      var days = [];

      for (var i = 0; i <= 6; i++) {
        days.push(moment(weekStart).add(i, "days").format("MM/DD/yyyy"));
      }

      await this.setState({
        showWeek: true,
        showDate: false,
        showMonth: false,
        weeklyDate: days[0] + " - " + days[6],
        dashboardType: event.target.value,
      });

      this.getGraphData();
    } else if (event.target.value === "monthly") {
      var date2 = new Date();

      date2.setMonth(date2.getMonth());

      await this.setState({
        showWeek: false,
        showDate: false,
        showMonth: true,
        currentMonth: moment().format("MMMM"),
        globalDate: date2,
        dashboardType: event.target.value,
      });

      this.getGraphData();
    } else if (event.target.value === "lastmonth") {
      var date3 = new Date();
      date3.setMonth(date3.getMonth() - 1);

      await this.setState({
        showWeek: false,
        showDate: false,
        showMonth: true,
        currentMonth: moment(date3.toString()).format("MMMM"),
        globalDate: date3,
        dashboardType: event.target.value,
      });

      this.getGraphData();
    }
  };

  changeNetworkType = async (event) => {
    await this.setState({
      networkType: event.target.value,
    });
    this.getGraphData();
  };

  getGraphData = async () => {
    var dashboardObject = {
      device_id: this.state.device_id,
      startDate: "",
      endDate: "",
      type: this.state.dashboardType,
      network: this.state.networkType,
    };

    if (this.state.dashboardType === "yesterday") {
      var yesterdayDate = moment(new Date())
        .subtract(1, "day")
        .format("MM/DD/yyyy");

      dashboardObject.startDate = yesterdayDate;
      dashboardObject.endDate = yesterdayDate;
    }

    if (this.state.dashboardType === "day") {
      var todayDate = this.state.displayedDate;
      var tomorrowDate = moment(this.state.displayedDate)
        .add(1, "day")
        .format("MM/DD/yyyy");

      dashboardObject.startDate = todayDate;
      dashboardObject.endDate = tomorrowDate;
    }

    if (this.state.dashboardType === "weekly") {
      var firstDay = this.state.weeklyDate.split(" - ")[0];
      var lastDay = this.state.weeklyDate.split(" - ")[1];

      dashboardObject.startDate = firstDay;
      dashboardObject.endDate = lastDay;
    }

    if (this.state.dashboardType === "monthly") {
      var year = this.state.globalDate.getFullYear();
      var month = this.state.globalDate.getMonth();
      var firstDate = new Date(year, month, 1);
      var lastDate = new Date(year, month + 1, 0);

      firstDate = moment(firstDate).format("MM/DD/yyyy");
      lastDate = moment(lastDate).format("MM/DD/yyyy");

      dashboardObject.startDate = firstDate;
      dashboardObject.endDate = lastDate;
    }

    if (this.state.dashboardType === "lastmonth") {
      var yearlast = this.state.globalDate.getFullYear();
      var monthlast = this.state.globalDate.getMonth();
      var lastfirstDate = new Date(yearlast, monthlast, 1);
      var lastlastDate = new Date(yearlast, monthlast + 1, 0);

      lastfirstDate = moment(lastfirstDate).format("MM/DD/yyyy");
      lastlastDate = moment(lastlastDate).format("MM/DD/yyyy");

      dashboardObject.startDate = lastfirstDate;
      dashboardObject.endDate = lastlastDate;
      dashboardObject.type = "monthly";
    }

    const response = await userMethods.getDashboardGraph(dashboardObject);

    if (response.data.message === "No information available") {
      this.setState({ showChart: false });
    }

    if (
      response.data.statusCode === 401 &&
      response.data.message != "Session expires. Please login again"
    ) {
      this.setState({
        open: true,
        message: response.data.message,
        loader: false,
      });
    }

    if (response.data.statusCode === 200) {
      this.setState({
        barchartData: response.data.result,
      });
      var download = [];
      var upload = [];
      var categories = [];

      if (this.state.barchartData.data && this.state.barchartData.data.length) {
        this.state.barchartData.data.forEach((element) => {
          download.push(element.data_download.split("MB")[0]);

          upload.push(element.data_upload.split("MB")[0]);

          if (
            dashboardObject.type === "weekly" ||
            dashboardObject.type === "day" ||
            dashboardObject.type === "yesterday"
          ) {
            categories.push(element.time);
          }

          // else if (dashboardObject.type === "monthly") {
          //   categories.push(element.date);
          // }
        });
      }

      this.setState({
        series: [
          {
            name: "data_download",
            data: download,
          },
          {
            name: "data_upload",
            data: upload,
          },
        ],
        options: {
          chart: {
            type: "bar",
            height: 150,
            stacked: true,
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: "bottom",
                  offsetX: -10,
                  offsetY: 0,
                },
              },
            },
          ],
          plotOptions: {
            bar: {
              borderRadius: 8,
              horizontal: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            type: "time",
            categories: categories,
            tickAmount: 5,
            labels: {
              show: true,
              rotate: -70,
              rotateAlways: false,
              showDuplicates: false,
              trim: false,
              style: {
                colors: "#fff",
                fontSize: "12px",
              },
            },
          },
          yaxis: { show: false },
          legend: {
            position: "right",
            offsetY: 40,
            show: false,
          },
          fill: {
            colors: ["#2196F3", "#C12A37"],
          },
        },
        showChart: true,
      });
    }
  };

  getHomeData = async () => {
    var dataObject = {
      device_id: this.state.device_id,
    };

    const response = await userMethods.getDashboardData(dataObject);

    if (response.data.statusCode === 200) {
      this.setState({
        bandwidthData: response.data.result.bandwidthdata,
        iotDevices: response.data.result.iotdevice,
        blocked_devices: response.data.result.blocked_devices,
        cellular_data_usage:
          response.data.result.bandwidthdata.cellular_data_usage,
        lan_data_usage: response.data.result.bandwidthdata.lan_data_usage,
      });
      // console.log(this.state.bandwidthData);
    } else {
      this.setState({
        bandwidthData: {},
        iotDevices: [],
      });
    }

    if (this.state.bandwidthData) {
      if (
        this.state.bandwidthData.left_data_graph != null &&
        this.state.bandwidthData.used_data_graph != null
      ) {
        this.setState({
          // seriesDonut: [100, 0],
          seriesDonut: [
            this.state.bandwidthData.left_data_graph
              ? (this.state.bandwidthData.left_data_graph / 1024)?.toFixed(2)
              : 0,
            this.state.bandwidthData.used_data_graph
              ? (this.state.bandwidthData.used_data_graph / 1024)?.toFixed(2)
              : 0,
          ],
        });
      }
      if (
        this.state.bandwidthData.left_data_graph === 0 &&
        this.state.bandwidthData.used_data_graph === 0
      ) {
        this.setState({
          seriesDonut: [100, 0],
          // seriesDonut: [this.state.bandwidthData.left_data, this.state.bandwidthData.used_data],
        });
      }
    }

    this.setState({
      optionsDonut: {
        labels: ["Data Left", "Data Used"],
        chart: {
          type: "donut",
          height: "100%",
        },
        colors: ["#C12A37", "#2196F3"],
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: (
                    this.state.bandwidthData.total_data_graph / 1024
                  ).toFixed(2)
                    ? (
                        this.state.bandwidthData.total_data_graph / 1024
                      ).toFixed(2)
                    : 0,
                  color: "white",
                },
                tooltip: {
                  enabled: true,
                },
              },
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 300,
              },
              legend: {
                position: "bottom",
                show: false,
              },
            },
          },
        ],
        fill: {
          colors: ["#C12A37", "#2196F3"],
        },
      },
    });
  };

  blockDevice = async () => {
    this.setState({ loader: true });

    let denyObject = {
      device_id: this.state.device_id,
      mac_address: this.state.blockDeviceList.mac_address,
      interval:
        this.state.blockingTime === ""
          ? this.setState({ errorModal: "Select duration", loader: false })
          : this.state.blockingTime,
    };

    const response = await userMethods.denyDevice(denyObject);

    if (response.data.statusCode === 200) {
      this.setState({ loader: false, showBlockTimeModal: false });
      this.getHomeData();
    }
  };

  unblockDevice = async (device) => {
    this.setState({ loader: true });
    let Device = device.mac_address;
    let denyObject = {
      device_id: this.state.device_id,
      mac_address: Device,
    };
    // console.log(denyObject);
    const response = await userMethods.unblockDevice(denyObject);

    if (response.data.statusCode === 200) {
      this.setState({ loader: false });
      this.getHomeData();
    }
  };

  openBlockDevicesList = (device) => {
    this.setState({
      blockDeviceList: device,
      showBlockTimeModal: true,
      errorModal: "",
    });
  };

  handleClose = () => {
    this.setState({ showBlockTimeModal: false, blockDeviceList: {} });
  };

  changeBlockingTime = (event) => {
    this.setState({
      blockingTime: event.target.value,
    });
  };

  redirectToIOTDevices = (device) => {
    this.props.history.push({
      pathname: `/dashboard/iotdetails`,
      state: { device: device },
    });
  };

  render() {
    // console.log(this.state);
    return (
      <div className="routing-main-wrap">
        <div className="page-head">
          <Snackbar
            open={this.state.open}
            autoHideDuration={3000}
            onClose={() => this.setState({ open: false })}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Alert
              // onClose={handleClose}
              severity="info"
              sx={{ width: "100%" }}
            >
              {this.state.message}
            </Alert>
          </Snackbar>
          <h3>Home</h3>
          {this.state.connectedDevices != null ? (
            <h3>{this.state.connectedDevices}</h3>
          ) : null}

          <ToggleSideNav batteryRefresh="true" />
        </div>
        {this.state.connectedDevices.length > 0 ? (
          <div className="rout-body">
            {/* --------- Header Tabs --------- */}
            <div className="header_tabs mr-l30">
              <div className="month_tab">
                <div className="monthtab_input_select">
                  <select
                    className="custom_select"
                    onChange={this.changeDashboardData}
                    value={this.state.dashboardType}
                  >
                    <option value="day" defaultValue>
                      Today
                    </option>
                    <option value="yesterday">Yesterday</option>
                    <option value="weekly">Week</option>
                    <option value="monthly">Current Month</option>
                    <option value="lastmonth">Last Month</option>
                  </select>
                </div>
              </div>

              <div className="month_name_tab">
                <div className="month_input_select">
                  {this.state.showDate ? (
                    <span>
                      <span className="drop-icons-left">
                        <ArrowBackIosIcon onClick={this.changeToPreviousDay} />
                      </span>
                      {this.state.displayedDate}
                      <span className="drop-icons-right">
                        <ArrowForwardIosIcon onClick={this.changeToNextDay} />
                      </span>
                    </span>
                  ) : null}

                  {this.state.showWeek ? (
                    <span className="week-date">{this.state.weeklyDate}</span>
                  ) : null}

                  {this.state.showMonth ? (
                    <span>
                      <span className="drop-icons-left">
                        <ArrowBackIosIcon
                          onClick={this.changeToPreviousMonth}
                        />
                      </span>
                      {this.state.currentMonth}
                      <span className="drop-icons-right">
                        <ArrowForwardIosIcon onClick={this.changeToNextMonth} />
                      </span>
                    </span>
                  ) : null}
                </div>
              </div>

              <div className="month_tab">
                <div className="monthtab_input_select">
                  <select
                    className="networking_custom_select"
                    style={{ border: "none" }}
                    onChange={this.changeNetworkType}
                    value={this.state.networkType}
                  >
                    <option value="AllNetworks">All Networks</option>
                    <option value="LAN">LAN</option>
                    <option value="Cellular">Cellular</option>
                  </select>
                </div>
              </div>
            </div>

            <section className="section_graph_header">
              <div className="container">
                <div className="row">
                  {/* --------- Header Graph --------- */}
                  <div className="header_graph mr-t50 col-md-12">
                    {this.state.showChart ? (
                      <ReactApexChart
                        options={this.state.options}
                        series={this.state.series}
                        type="bar"
                        height={280}
                      />
                    ) : (
                      <div
                        style={{
                          height: "100px",
                          marginLeft: "40%",
                          marginTop: "8%",
                        }}
                      >
                        <h6 style={{ color: "#E3C710" }}>
                          No chart data available.
                        </h6>
                      </div>
                    )}
                  </div>

                  {/* ------ Bandwidth Data -------- */}
                  <div className="bandwidth_main mr-t35 mr-l30 bandwidth_main_home">
                    {this.state.bandwidthData === "No information available" ? (
                      <div className="text-center registered-title mr-b20 mr-t20">
                        <h6 style={{ color: "#C12A37", fontSize: "15px" }}>
                          No Data Available
                        </h6>
                      </div>
                    ) : (
                      <div>
                        <div className="row flex-row-reverse mr-2">
                          <CustomToolTip message={bandWidth} />
                        </div>
                        <div className="bandwidth_limiter">
                          <div className=" bandwidth_card1">
                            {/* <div className="band_subgrid">
                          <div className="bandwidth_sub_limiter">
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M15 0C23.28 0 30 6.72 30 15C30 23.28 23.28 30 15 30C6.72 30 0 23.28 0 15C0 6.72 6.72 0 15 0ZM15 27C21.63 27 27 21.63 27 15C27 8.37 21.63 3 15 3C8.37 3 3 8.37 3 15C3 21.63 8.37 27 15 27ZM16.5 15H21L15 21L9 15H13.5V9H16.5V15Z"
                                fill="#2196F3"
                              />
                            </svg>
                            <h5>
                              {this.state.bandwidthData.bandwidth_download_max}
                              &nbsp;Mbps
                            </h5>
                            <h6>
                              {
                                this.state.bandwidthData
                                  .bandwidth_download_usage
                              }
                              &nbsp;Mbps
                            </h6>
                          </div>

                          <div className="bandwidth_sub_limiter">
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M15 30C6.72 30 0 23.28 0 15C0 6.72 6.72 0 15 0C23.28 0 30 6.72 30 15C30 23.28 23.28 30 15 30ZM15 3C8.37 3 3 8.37 3 15C3 21.63 8.37 27 15 27C21.63 27 27 21.63 27 15C27 8.37 21.63 3 15 3ZM13.5 15H9L15 9L21 15H16.5V21H13.5V15Z"
                                fill="#C12A37"
                              />
                            </svg>

                            <h5>
                              {this.state.bandwidthData.bandwidth_upload_max}
                              &nbsp;Mbps
                            </h5>
                            <h6>
                              {this.state.bandwidthData.bandwidth_upload_usage}
                              &nbsp;Mbps
                            </h6>
                          </div>
                        </div> */}
                            {/* <div className="bandwidth_sub_limiter_text">
                          <h6>Bandwidth Limiter</h6>
                        </div> */}

                            <div className="band_subgrid mr-t20 pd-l30">
                              <div style={{ textAlign: "justify" }}>
                                <div>
                                  <h4 className="bandwidth-data mr-t20">
                                    Used Data:
                                    <label className="mr-l20 bandwidth-label">
                                      {/* {this.state.bandwidthData.used_data / 1024
                                        ? (
                                            this.state.bandwidthData.used_data /
                                            1024
                                          ).toFixed(2)
                                        : 0}{" "}
                                      &nbsp;GB */}
                                      {this.state?.bandwidthData?.used_data}
                                    </label>
                                  </h4>
                                </div>

                                <div>
                                  <h4 className="bandwidth-data mr-t20">
                                    Avail Data:
                                    <label className="mr-l20 bandwidth-label">
                                      {/* {this.state.bandwidthData.left_data / 1024
                                        ? (
                                            this.state.bandwidthData.left_data /
                                            1024
                                          ).toFixed(2)
                                        : 0}{" "}
                                      &nbsp;GB */}
                                      {this.state?.bandwidthData?.left_data}
                                    </label>
                                  </h4>
                                </div>

                                <div>
                                  <h4 className="bandwidth-data mr-t20">
                                    Exp Data:
                                    <label className="mr-l20 bandwidth-label">
                                      {this.state.bandwidthData.expiry_date}
                                    </label>
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="bandwidth_card2">
                            <ReactApexChart
                              options={this.state.optionsDonut}
                              series={this.state.seriesDonut}
                              type="donut"
                              height={200}
                            />
                          </div>
                          <div className=" bandwidth_card3">
                            <div className="internet_head text-center">
                              <h5>Internet</h5>
                            </div>
                            <div className="internet_grid">
                              <div className="internet_img">
                                <img src={tower} alt="" />
                                <h6>
                                  {
                                    this.state?.bandwidthData
                                      ?.cellular_data_usage
                                  }

                                  {/* {this.state.cellular_data_usage < 1000
                                    ? this.state.cellular_data_usage + " B"
                                    : this.state.cellular_data_usage > 1000 &&
                                      this.state.lan_data_usage < 999999
                                    ? (
                                        this.state.cellular_data_usage / 1024
                                      ).toFixed(2) + " MB"
                                    : this.state.cellular_data_usage > 1000000
                                    ? (
                                        this.state.cellular_data_usage / 1024000
                                      ).toFixed(2) + "GB"
                                    : this.state.cellular_data_usage === 0
                                    ? "0.0 MB"
                                    : null} */}
                                </h6>
                              </div>
                              <div className="internet_img">
                                <img src={wired_img} alt="" />
                                <h6>
                                  {this.state?.bandwidthData?.lan_data_usage}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* ------ Wireless Networks -------- */}
                  <div className="wireless_net_main mr-t20 mr-l30 wireless_net_main_home">
                    <div className="row flex-row-reverse mr-2">
                      <CustomToolTip message={wirelessNetwork} />
                    </div>
                    <div className="wireless_header text-center">
                      <h5>Wireless Networks</h5>
                    </div>
                    {this.state.bandwidthData === "No information available" ? (
                      <div className="text-center registered-title mr-b20 mr-t20">
                        <h6 style={{ color: "#C12A37", fontSize: "15px" }}>
                          No Data Available
                        </h6>
                      </div>
                    ) : (
                      <div className="wireless_content">
                        {/* <div className="col-sm-3 col-md-auto bluetooth_card">
                      <svg
                        width="15"
                        height="24"
                        viewBox="0 0 15 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.122 6.757L5.62201 0V10.485L0 6.01601V8.552L4.336 12L0 15.447V17.983L5.62201 13.514V24L14.122 17.243L7.52701 12L14.122 6.757ZM7.46902 3.877L11.092 6.757L7.46902 9.63699V3.877ZM11.092 17.242L7.46902 20.122V14.362L11.092 17.242Z"
                          fill="#BC2835"
                        />
                      </svg>
                      <span>200.0 MB</span>
                    </div> */}
                        <div className="col-sm-3 col-md-auto bluetooth_card">
                          <svg
                            width="29"
                            height="23"
                            viewBox="0 0 29 23"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0 5.1073C4.08772 1.79571 9.19085 -0.00775741 14.4517 2.50826e-05C19.9269 2.50826e-05 24.955 1.91286 28.9033 5.1073L26.4947 8.08835C23.0881 5.32903 18.8355 3.82652 14.4517 3.83335C9.88999 3.83335 5.69888 5.42802 2.40861 8.08835L0 5.1073ZM4.0135 10.0778C6.96593 7.68585 10.6518 6.38321 14.4517 6.38891C18.4051 6.38891 22.0365 7.77018 24.8898 10.0766L22.4812 13.0589C20.2099 11.2192 17.3745 10.2175 14.4517 10.2222C11.4105 10.2222 8.61732 11.2853 6.4221 13.0589L4.0135 10.0766V10.0778ZM8.02827 15.0484C9.84507 13.5763 12.1133 12.7745 14.4517 12.7778C16.8845 12.7778 19.1194 13.6275 20.875 15.0471L18.4664 18.0295C17.3307 17.1097 15.9131 16.6088 14.4517 16.6111C12.9902 16.6088 11.5726 17.1097 10.4369 18.0295L8.02827 15.0471V15.0484ZM12.043 20.0189C12.7242 19.4666 13.5747 19.1656 14.4517 19.1667C15.364 19.1667 16.2022 19.4861 16.8603 20.0177L14.4517 23L12.043 20.0177V20.0189Z"
                              fill="#C12A37"
                            />
                          </svg>

                          <span>
                            {/* {this.state.bandwidthData.lan_data_usage
                            ? this.state.bandwidthData.lan_data_usage + " MB"
                            : "0.0 MB"} */}
                            {this.state?.bandwidthData?.lan_data_usage}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* ------ Connected and Available buttons -------- */}
                  <div className="connect_avail_buttons_main connect_avail_buttons_main_home mr-l30">
                    <div className="connect_avail_buttons_wrap connect_avail_btns_home3">
                      <button
                        type="button"
                        className="connect_avail_buttons connected_btn"
                      >
                        <h6>Connected Devices</h6>
                        <span>{this.state.iotDevices.length}</span>
                      </button>
                      {/* <button
                      type="button"
                      className="connect_avail_buttons available_btn"
                      >
                      <h6>Available</h6>
                      <span>7</span>
                    </button> */}
                    </div>
                  </div>

                  {/* ------ Device Lists -------- */}

                  {this.state.iotDevices.length === 0 ? (
                    <div className="wifi_routers_main mr-t20 mr-l30 wifi_routers_main_home">
                      <div className="text-center registered-title mr-b20 mr-t20">
                        <h6 style={{ color: "#C12A37", fontSize: "15px" }}>
                          No Device Connected
                        </h6>
                      </div>
                    </div>
                  ) : (
                    this.state.iotDevices.map((device, index) => (
                      <div
                        className="wifi_routers_main mr-t20 mr-l30 wifi_routers_main_home"
                        key={index}
                      >
                        <div className="row flex-row-reverse mr-2 h-10">
                          <CustomToolTip message={connectedDevices} />
                        </div>
                        <div className="wifi_router-wrap">
                          <div className="col-1 col-md-1 wifi_image_routers">
                            <svg
                              width="45"
                              height="35"
                              viewBox="0 0 57 45"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0 9.99254C8.06137 3.51334 18.1252 -0.0151775 28.5 4.90747e-05C39.2977 4.90747e-05 49.2135 3.74255 57 9.99254L52.25 15.825C45.532 10.4264 37.1454 7.48666 28.5 7.50004C19.504 7.50004 11.2387 10.62 4.75 15.825L0 9.99254ZM7.91499 19.7175C13.7375 15.0375 21.0064 12.4889 28.5 12.5C36.2966 12.5 43.4581 15.2025 49.085 19.715L44.335 25.55C39.8559 21.9505 34.2642 19.9907 28.5 20C22.5027 20 16.9942 22.08 12.665 25.55L7.91499 19.715V19.7175ZM15.8325 29.4425C19.4154 26.5623 23.8885 24.9935 28.5 25C33.2979 25 37.7052 26.6625 41.1675 29.44L36.4175 35.275C34.1778 33.4755 31.3821 32.4956 28.5 32.5C25.6179 32.4956 22.8222 33.4755 20.5825 35.275L15.8325 29.44V29.4425ZM23.75 39.1675C25.0932 38.0868 26.7706 37.498 28.5 37.5C30.2992 37.5 31.9523 38.125 33.25 39.165L28.5 45L23.75 39.165V39.1675Z"
                                fill="#C12A37"
                              />
                            </svg>
                          </div>
                          <div className="col-11 col-md-11 header_button_flex">
                            <div className="routers_headingname w-100">
                              <div className="head_btn_flex">
                                <h5
                                  onClick={() =>
                                    this.redirectToIOTDevices(device)
                                  }
                                >
                                  {device.name}
                                </h5>
                                <div className="routers_button">
                                  <a>
                                    <button
                                      type="button"
                                      className="red_btn deny_btn"
                                      // onClick={() => this.blockDevice(device)}
                                      onClick={() =>
                                        this.openBlockDevicesList(device)
                                      }
                                    >
                                      Block
                                    </button>
                                  </a>
                                </div>
                              </div>
                              <h6>
                                Time Connected &nbsp;
                                {/* {moment(device.connected_time).format("hh:mm")} */}
                                {moment
                                  .duration(device.connected_time)
                                  .asHours()
                                  .toFixed(2)}
                                &nbsp; hrs
                              </h6>
                              <div className="row progress_bar_row">
                                {/* <svg
                                width="652"
                                height="7"
                                viewBox="0 0 652 7"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  x="652"
                                  width="6.48493"
                                  height="652"
                                  rx="3.24247"
                                  transform="rotate(90 652 0)"
                                  fill="#2196F3"
                                />
                                <rect
                                  x="130"
                                  width="6.48493"
                                  height="130"
                                  rx="3.24247"
                                  transform="rotate(90 130 0)"
                                  fill="#BC2835"
                                />
                              </svg> 
                              <span>54.6 MB</span>
                              */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                  {/* ---------------------------------Blocked devices  */}

                  <div className="connect_avail_buttons_main connect_avail_buttons_main_home mr-l30">
                    <div className="connect_avail_buttons_wrap connect_avail_btns_home3">
                      <button
                        type="button"
                        className="connect_avail_buttons connected_btn"
                      >
                        <h6>Blocked Devices</h6>
                        {/* <span>{this.state.blocked_devices.length}</span> */}
                      </button>
                      {/* <button
                      type="button"
                      className="connect_avail_buttons available_btn"
                    >
                      <h6>Available</h6>
                      <span>7</span>
                    </button> */}
                    </div>
                  </div>
                  {this.state.blocked_devices.length === 0 ? (
                    <div className="wifi_routers_main mr-t20 mr-l30 wifi_routers_main_home">
                      <div className="text-center registered-title mr-b20 mr-t20">
                        <h6 style={{ color: "#C12A37", fontSize: "15px" }}>
                          No Blocked Devices
                        </h6>
                      </div>
                    </div>
                  ) : (
                    this.state.blocked_devices.map((device, index) => (
                      <div
                        className="wifi_routers_main mr-t20 mr-l30 wifi_routers_main_home"
                        key={index}
                      >
                        <div className="row flex-row-reverse mr-2 h-10">
                          <CustomToolTip message={blockedDevices} />
                        </div>
                        <div className="wifi_router-wrap">
                          <div className="col-1 col-md-1 wifi_image_routers">
                            <svg
                              width="45"
                              height="35"
                              viewBox="0 0 57 45"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0 9.99254C8.06137 3.51334 18.1252 -0.0151775 28.5 4.90747e-05C39.2977 4.90747e-05 49.2135 3.74255 57 9.99254L52.25 15.825C45.532 10.4264 37.1454 7.48666 28.5 7.50004C19.504 7.50004 11.2387 10.62 4.75 15.825L0 9.99254ZM7.91499 19.7175C13.7375 15.0375 21.0064 12.4889 28.5 12.5C36.2966 12.5 43.4581 15.2025 49.085 19.715L44.335 25.55C39.8559 21.9505 34.2642 19.9907 28.5 20C22.5027 20 16.9942 22.08 12.665 25.55L7.91499 19.715V19.7175ZM15.8325 29.4425C19.4154 26.5623 23.8885 24.9935 28.5 25C33.2979 25 37.7052 26.6625 41.1675 29.44L36.4175 35.275C34.1778 33.4755 31.3821 32.4956 28.5 32.5C25.6179 32.4956 22.8222 33.4755 20.5825 35.275L15.8325 29.44V29.4425ZM23.75 39.1675C25.0932 38.0868 26.7706 37.498 28.5 37.5C30.2992 37.5 31.9523 38.125 33.25 39.165L28.5 45L23.75 39.165V39.1675Z"
                                fill="#C12A37"
                              />
                            </svg>
                          </div>
                          <div className="col-11 col-md-11 header_button_flex">
                            <div className="routers_headingname w-100">
                              <div className="head_btn_flex">
                                <h5
                                // onClick={() => this.redirectToIOTDevices(device)}
                                >
                                  {device.name}
                                </h5>
                                <div className="routers_button">
                                  <a>
                                    <button
                                      type="button"
                                      className="red_btn deny_btn"
                                      // onClick={() => this.blockDevice(device)}
                                      onClick={() => this.unblockDevice(device)}
                                    >
                                      Unblock
                                    </button>
                                  </a>
                                </div>
                              </div>
                              {/* <h6>
                                Time Connected &nbsp;
                                {moment(device.connected_time).format("hh:mm")}
                                &nbsp; hrs
                              </h6> */}
                              {/* <div className="row progress_bar_row"> */}
                              {/* <svg
                                width="652"
                                height="7"
                                viewBox="0 0 652 7"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  x="652"
                                  width="6.48493"
                                  height="652"
                                  rx="3.24247"
                                  transform="rotate(90 652 0)"
                                  fill="#2196F3"
                                />
                                <rect
                                  x="130"
                                  width="6.48493"
                                  height="130"
                                  rx="3.24247"
                                  transform="rotate(90 130 0)"
                                  fill="#BC2835"
                                />
                              </svg> 
                              <span>54.6 MB</span>
                              */}
                            </div>
                          </div>
                        </div>
                      </div>
                      // </div>
                    ))
                  )}
                </div>
              </div>
            </section>
          </div>
        ) : (
          <div
            style={{
              paddingTop: "20px",
              display: "flex",
              justifyContent: "center",
              fontSize: "20px",
              color: "#BC2835",
            }}
          >
            No device connected
          </div>
        )}

        {this.state.showBlockTimeModal ? (
          <Modal
            open={this.state.showBlockTimeModal}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw"
              role="document"
            >
              <div className="modal-content modal_fp_content">
                <div className="modal-header forget_pw_header">
                  <button
                    type="button"
                    className="close modal_fp_close_btn"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.handleClose}
                  >
                    <span aria-hidden="true">
                      <svg
                        width="10"
                        height="18"
                        viewBox="0 0 10 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2.91937 9.00008L9.82886 1.70718L8.48899 0.292969L0.23964 9.00008L8.48899 17.7072L9.82886 16.293L2.91937 9.00008Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </button>
                  <h5
                    className="modal-title modal_fp_title "
                    id="exampleModalLabel"
                  >
                    Block Device
                  </h5>
                </div>

                <div className="modal-body fp_modal_body">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body">
                    <div className="fp_label">
                      <select
                        id="invite"
                        className="fp_modal_input"
                        onChange={this.changeBlockingTime}
                        value={this.state.blockingTime}
                      >
                        <option value="" defaultValue>
                          Blocking Period
                        </option>
                        <option value="15 Minutes">15 Minutes</option>
                        <option value="30 Minutes">30 Minutes</option>
                        <option value="1 Hour">1 Hour</option>
                        <option value="2 Hours">2 Hours</option>
                        <option value="Permanent">Permanent</option>
                      </select>
                    </div>
                    <div className="color-red">
                      <i>{this.state.errorModal}</i>
                    </div>
                  </div>
                </div>
                <div className="modal-footer footer_fp_modal">
                  <button
                    type="button"
                    className="btn-fp-footer btn-Reset"
                    onClick={this.blockDevice}
                    disabled={this.state.loader}
                  >
                    Block
                    {this.state.loader ? (
                      <i className="fa fa-spinner fa-spin"></i>
                    ) : null}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}

        {this.state.showLogout ? (
          <Modal
            open={this.state.showLogout}
            aria-labelledby="DeleteUserProfile"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw"
              role="document"
            >
              <div className="modal-content modal_fp_content">
                <div className="modal-header forget_pw_header">
                  <h5
                    className="modal-title modal_fp_title "
                    id="exampleModalLabel"
                  >
                    Session Expired
                  </h5>
                </div>
                <div className="modal-body fp_modal_body">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body mr-t20 mr-b10">
                    <h6 className="delete-dialog">
                      Please login again.
                      <br />
                      <br />
                    </h6>
                  </div>
                </div>
                <div className="modal-footer footer_fp_modal">
                  <button
                    type="button"
                    className="btn-fp-footer btn-Reset"
                    onClick={this.logoutUser}
                    // disabled={this.state.loader}
                  >
                    Okay
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default Home;
