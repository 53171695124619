import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Login from "./containers/login/login";
import Dashboard from "./containers/dashboard/dashboard";
import Register from "./containers/register/register";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  // toast.error(
  //   "Success asdahsdhiasdasidas maskasdiohasma kasdasdh asdkas k āśdjiasd madkjasdlkn as ,mdasiod lknasmndasihodlasdm asildad zx sdihofd ,mczX cdshv jnc"
  //   +" Here is testing can you this !"
  // );
  return (
    <BrowserRouter>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light"
      />
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/dashboard" component={Dashboard} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
