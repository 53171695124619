import React, { Component } from "react";
import fileSharingMethods from "../../../services/fileSharingApi";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBack from "@material-ui/icons/ArrowBack";
import mqtt from "mqtt";
import FolderIcon from "@material-ui/icons/Folder";
import moment from "moment";
import bytes from "bytes";
import { toast } from "react-toastify";

let currentPath1 = "";

class ToCloud extends Component {
  state = {
    currentPath: "",
    fileStructureList: [],
    selectedDevice: JSON.parse(window.sessionStorage.getItem("ConnectedDevice"))
      ?.device_id,
    token: JSON.parse(window.sessionStorage.getItem("UserDetails")).token,
    showLoader: false,
  };

  componentDidMount() {
    currentPath1 = "/dRisk/" + this.props.selectedDevice;
    this.setState({ currentPath: this.state.selectedDevice }, () => {
      this.getFileStructureList(currentPath1);
    });
    // this.getFileStructureList(this.props.initialPath);
  }

  getFileStructureList = async (pathName) => {
    this.setState({
      showLoader: true,
    });
    const response = await fileSharingMethods.folderStructure({
      path: pathName,
    });
    if (response.data.statusCode === 200) {
      this.setState({
        fileStructureList: response.data.result,
        loader: false,
        showLoader: false,
      });
    }
    // this.setState({ curentPath: response.data.filename });
  };

  goForwardInFileStructure = async (path) => {
    this.setState({ loader: true });
    currentPath1 = currentPath1 + "/" + path;
    // this.setState({ currentPath: this.state.currentPath + "/" + path });
    // this.setState({ currentPath: path });
    this.getFileStructureList(currentPath1);
  };

  goBackwardInFileStructure = async () => {
    this.setState({ loader: true });
    // let currentUrl = this.state.currentPath;
    // this.setState({ currentPath: currentUrl.substring(0, currentUrl.lastIndexOf("/")) });
    currentPath1 = currentPath1.substring(0, currentPath1.lastIndexOf("/"));
    this.getFileStructureList(currentPath1);
  };

  getCharCount(str) {
    let charCount = 0;
    for (let i = 0; i < str.length; i++) {
      if (str.charAt(i) === "/") charCount++;
    }
    return charCount;
  }

  copyFile = async () => {
    // var mosquitto = mqtt.connect(`${process.env.REACT_APP_NODE_MQTT_URL}`, {
    //   port: 8083,
    //   username: "mqttdonald",
    //   password: "mqttdonald",
    //   clean: true,
    //   retain: false,
    // });
    var filename = this.props.currentWorkingPath;
    var desPath = currentPath1;
    var device_id = this.state.selectedDevice;
    // selected_device_id = ;
    var token = this.state.token;

    var payload = {
      module: "copy_cloud",
      filename: filename,
      desPath: desPath,
      device_id: device_id,
    };
    const response = await fileSharingMethods.copyFilesToCloud(payload);
    if (response.data.statusCode === 200) {
      toast.success(response?.data?.message);
    } else toast.error(response?.data?.message);
    this.getFileStructureList(desPath);
    // mosquitto.on("message", (topic, sub_message) => {
    //   var payload = sub_message.toString();
    // });
  };

  resetValues = () => {
    this.setState({
      currentPath: "",
      userDevice: "",
      device_id: "",
      desPath: "",
    });
  };

  render() {
    console.log("copy to cloud", this.props.selectedDevice);
    return (
      <>
        <h6>
          <div>
            {this.getCharCount(currentPath1) > 2 ? (
              // <button
              //   style={{
              //     // backgroundColor: "red",
              //     // color: "white",
              //     borderRadius: "10px",
              //     height: "30px",
              //     width: "80px",
              //   }}
              <ArrowBack onClick={() => this.goBackwardInFileStructure()}>
                Back
              </ArrowBack>
            ) : null}
            Select Destinations
            <em className="mandatory">* </em>
          </div>
        </h6>
        <ul
          className="filesharing-card mr-t20 scroll"
          style={{ height: "300px" }}
        >
          {this.state.fileStructureList.length > 0 ? (
            this.state.fileStructureList.map((data, index) => {
              if (data.type === "directory") {
                return (
                  <li
                    key={data.fileid}
                    className="col-md-12"
                    onClick={() => this.goForwardInFileStructure(data.basename)}
                  >
                    <div className="col-2 col-sm-2 col-md-2">
                      <span className="drop-icons-left">
                        <FolderIcon />
                      </span>
                    </div>

                    <div className="col-6 col-sm-6 col-md-6 header_button_flex">
                      <div className="head_btn_flex">
                        <div className="head_btn_flex_headings">
                          <h5 className="drisk-name">{data.basename}</h5>
                          <h6
                            className="drisk-date"
                            style={{
                              fontSize: "13px !important",
                              fontWeight: "500 !important",
                              color: "#6bc4e2 !important",
                              fontFamily: "Poppins-Regular !important",
                            }}
                          >
                            {moment(data.lastmod).format("LL")}
                          </h6>
                        </div>
                      </div>
                    </div>

                    <div className="col-2 col-sm-2 col-md-2 header_button_flex mr-t10">
                      <div className="head_btn_flex">
                        <div className="head_btn_flex_headings">
                          <h6 className="drisk-date">
                            {bytes.format(data.size, {
                              unitSeparator: " ",
                            })}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              }
            })
          ) : this.state.showLoader === true ? (
            <CircularProgress
              color="secondary"
              style={{ margin: "0% 0% 0% 50%" }}
            />
          ) : (
            <div className="mr-b20 mr-t20">
              <h6
                style={{
                  color: "#C12A37",
                  fontSize: "15px",
                  display: "block !important",
                  width: "160px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  fontWeight: "600",
                }}
              >
                No Data Available
              </h6>
            </div>
          )}
        </ul>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            type="button"
            // className="btn-fp-footer btn-Reset"
            style={{
              backgroundColor: "#c12a37",
              color: "white",
              borderRadius: "10px",
              height: "38px",
              width: "150px",
            }}
            onClick={this.copyFile}
            disabled={this.state.loader}
          >
            Copy 
            {this.state.loader ? (
              <i className="fa fa-spinner fa-spin"></i>
            ) : null}
          </button>
        </div>
      </>
    );
  }
}

export default ToCloud;
