import Modal from "@material-ui/core/Modal";
import $ from "jquery";
import React, { Component } from "react";
import GoogleLogin from "react-google-login";
import logo from "../../assets/images/cervais.png";
import guardian from "../../assets/images/sgx.png";
import AuthContext from "../../context/auth-context";
import userMethods from "../../services/userApi";

class Login extends Component {
  state = {
    email: "",
    password: "",
    isEmailValid: false,
    errorEmail: "",
    errorPassword: "",
    registerUserObject: {
      full_name: "",
      password: "",
      email_address: "",
      phone_number: "",
      dob: "",
      loginType: "",
      matrix_captcha: "",
      firebase_token: "",
    },
    authenticated: false,
    forgetPassword: "",
    isForgetPasswordEmailValid: false,
    errorForgetPasswordEmail: "",
    loader: false,
    showModal: false,
    isLoginFailed: false,
    loginFailedMessage: "",
  };

  componentDidMount() {
    window.sessionStorage.clear();
    this.setState({ authenticated: false });
    window.sessionStorage.setItem("isAuthenticated", false);
  }

  responseGoogle = async (googleUser) => {
    // console.log(googleUser);
    if (googleUser) {
      // Get the Google profile data (basic)
      console.log(googleUser);
      var profile = googleUser.profileObj;

      const updatedData = {
        ...this.state.registerUserObject,
      };

      if (profile?.name !== "" && profile?.email !== "") {
        updatedData.first_name = profile.name;
        updatedData.email_address = profile.email;
        updatedData.loginType = "google";

        this.setState({ registerUserObject: updatedData });

        const registerResponse = await userMethods.userRegistration(
          this.state.registerUserObject
        );

        if (registerResponse.data.statusCode === 400) {
          this.setState({
            isLoginFailed: true,
            loginFailedMessage: registerResponse.data.message,
          });
        }

        if (registerResponse.data.statusCode === 200) {
          window.sessionStorage.setItem(
            "UserDetails",
            JSON.stringify(registerResponse.data.result)
          );
          this.loginHandler();
        }
      }
    }
  };

  responseFacebook = async (facebookUser) => {
    //
    console.log(facebookUser);
    // Get the Facebook profile data (basic)
    var profile = facebookUser;

    const updatedData = {
      ...this.state.registerUserObject,
    };

    if (profile.name !== "" && profile.email !== "") {
      updatedData.full_name = profile.name;
      updatedData.email_address = profile.email;
      updatedData.loginType = "facebook";

      this.setState({ registerUserObject: updatedData });

      const registerResponse = await userMethods.userRegistration(
        this.state.registerUserObject
      );

      if (registerResponse.data.statusCode === 400) {
        this.setState({
          isLoginFailed: true,
          loginFailedMessage: registerResponse.data.message,
        });
      }

      if (registerResponse.data.statusCode === 200) {
        window.sessionStorage.setItem(
          "UserDetails",
          JSON.stringify(registerResponse.data.result)
        );
        this.loginHandler();
      }
    }
  };

  handleEmailChange = async (event) => {
    this.setState({ email: event.target.value });

    // Checks if email is valid or not
    await this.setState({ isEmailValid: this.validateEmail(this.state.email) });

    if (!this.validateEmail(this.state.email)) {
      await this.setState({ errorEmail: "Please enter valid email-id" });
    } else {
      await this.setState({ errorEmail: "" });
    }
  };

  handlePasswordChange = (event) => {
    this.setState({ password: event.target.value });
  };

  userSignIn = async (e) => {
    this.setState({ loader: true });

    if (this.state.email === "") {
      this.setState({ errorEmail: "Please enter email-id", loader: false });
    }

    if (this.state.password === "") {
      this.setState({ errorPassword: "Please enter password", loader: false });
    } else {
      this.setState({
        errorPassword: "",
      });
    }

    if (
      this.validateEmail(this.state.email) &&
      this.state.email !== "" &&
      this.state.password !== ""
    ) {
      // var userObject = {
      //   email_address: "winjitdemo@yopmail.com",
      //   password: "Winjit@123",
      // };
      var userObject = {
        email_address: this.state.email,
        password: this.state.password,
      };

      const response = await userMethods.userLogin(userObject);

      if (response.data.statusCode === 200) {
        this.setState({
          loader: false,
          isLoginFailed: false,
          loginFailedMessage: "",
        });

        window.sessionStorage.setItem(
          "UserDetails",
          JSON.stringify(response.data.result)
        );

        window.sessionStorage.setItem("UserData", JSON.stringify(userObject));
        setTimeout(() => window.location.reload(), 5000);
        this.getConnectedDevice();
      } else {
        this.setState({
          isLoginFailed: true,
          loginFailedMessage: response.data.message,
          loader: false,
        });
      }
    }
  };

  getConnectedDevice = async () => {
    const deviceResponse = await userMethods.getAllDriskDevices();
    var connectedDevice = {};

    if (deviceResponse.data.statusCode === 200) {
      var result = deviceResponse.data.result.device_details;

      result.forEach((element) => {
        if (element.is_connected === "connected") {
          connectedDevice = element;
        }
      });

      window.sessionStorage.setItem(
        "ConnectedDevice",
        JSON.stringify(connectedDevice)
      );

      this.loginHandler();
    }
  };

  validateEmail(email) {
    const pattern =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

    return pattern.test(email);
  }

  loginHandler = () => {
    this.setState({ authenticated: true });

    window.sessionStorage.setItem("isAuthenticated", true);

    this.props.history.replace({
      pathname: "/dashboard/home",
    });
  };

  handleForgetPasswordChange = (event) => {
    // Checks if email is valid or not
    this.setState({
      isForgetPasswordEmailValid: this.validateEmail(this.state.forgetPassword),
    });

    if (!this.state.isForgetPasswordEmailValid) {
      this.setState({
        errorForgetPasswordEmail: "Please enter valid email-id",
      });
    } else {
      this.setState({ errorForgetPasswordEmail: "" });
    }

    this.setState({ forgetPassword: event.target.value });
  };

  forgetPasswordMethod = async () => {
    this.setState({
      loader: true,
    });

    const changePasswordObject = {
      email_address: this.state.forgetPassword,
    };

    const response = await userMethods.userForgotPassword(changePasswordObject);

    if (response.data.statusCode === 200) {
      $("#forgetPasswordModal").hide();
      $(".modal-backdrop").remove();
      this.setState({
        forgetPassword: "",
        loader: false,
      });
      this.handleClose();
    } else if (response.data.statusCode === 400) {
      this.setState({
        errorForgetPasswordEmail: response.data.message,
        forgetPassword: "",
        loader: false,
      });
    }
  };

  handleOpen = () => {
    this.setState({ showModal: true });
  };

  handleClose = () => {
    this.setState({ showModal: false });
  };

  togglePassword() {
    var pssInput = document.getElementById("toggle");
    var passIcon = document.getElementById("toggleIcon");

    if (pssInput.type === "password") {
      pssInput.type = "text";
      passIcon.className = "fa fa-eye";
    } else {
      pssInput.type = "password";
      passIcon.className = "fa fa-eye-slash";
    }
  }

  render() {
    return (
      <section className="sign-in-wrap">
        <div className="sign-form">
          <div className="form-head text-center mr-b10">
            {/* <h4 className="mr-t20 mr-b10 mb-3  armia-font"style={{fontSize:20,color:"black"}}>SG-X GuArDiAn</h4> */}

            <div className="guardian-logo">
              <img
                className="f-logo"
                src={guardian}
                alt="Cervais"
                title="Cervais"
              />
            </div>
            {/* <h4 className="mr-t20 mr-b10 armia-font">Welcome to ARMIA SG-X</h4> */}
            <h4 className=" mr-b10 armia-font">SG-X GuArDiAn</h4>
            <span>Sign in to continue</span>
          </div>

          <form action="">
            <div className="color-red">
              <i>{this.state.errorEmail}</i>
            </div>

            <div className="form-group black-input form_black_input mr-b10">
              <div className="input-w-icon">
                <div className="input-icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M2 5.25C2 4.69772 2.44772 4.25 3 4.25H21C21.5523 4.25 22 4.69772 22 5.25V18.75C22 19.3023 21.5523 19.75 21 19.75H3C2.44772 19.75 2 19.3023 2 18.75V5.25ZM4 6.25V17.75H20V6.25H4Z"
                      fill="#9098B1"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M2.24075 4.59922C2.60017 4.17989 3.23147 4.13132 3.6508 4.49074L12 11.6471L20.3492 4.49074C20.7686 4.13132 21.3999 4.17989 21.7593 4.59922C22.1187 5.01855 22.0701 5.64985 21.6508 6.00927L12.6508 13.7234C12.2763 14.0444 11.7237 14.0444 11.3492 13.7234L2.34923 6.00927C1.92991 5.64985 1.88134 5.01855 2.24075 4.59922Z"
                      fill="#9098B1"
                    />
                  </svg>
                </div>
                <input
                  type="email"
                  className="form-control black-input-control"
                  placeholder="Your Email"
                  value={this.state.email}
                  name="email"
                  onChange={this.handleEmailChange}
                  autoFocus
                  autoComplete="off"
                  required
                />
              </div>
            </div>

            <div className="color-red">
              <i>{this.state.errorPassword}</i>
            </div>
            <div className="form-group black-input form_black_input mr-b15">
              <div className="input-w-icon">
                <div className="input-icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M2 9.75C2 9.19772 2.44772 8.75 3 8.75H21C21.5523 8.75 22 9.19772 22 9.75V21C22 21.5523 21.5523 22 21 22H3C2.44772 22 2 21.5523 2 21V9.75ZM4 10.75V20H20V10.75H4Z"
                      fill="#9098B1"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 4C9.48134 4 7.375 6.13206 7.375 8.841C7.375 9.39328 6.92728 9.841 6.375 9.841C5.82272 9.841 5.375 9.39328 5.375 8.841C5.375 5.09919 8.30641 2 12 2C15.6936 2 18.625 5.09919 18.625 8.841C18.625 9.39328 18.1773 9.841 17.625 9.841C17.0727 9.841 16.625 9.39328 16.625 8.841C16.625 6.13206 14.5187 4 12 4Z"
                      fill="#9098B1"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 13.7257C12.5523 13.7257 13 14.1734 13 14.7257V15.8507C13 16.403 12.5523 16.8507 12 16.8507C11.4477 16.8507 11 16.403 11 15.8507V14.7257C11 14.1734 11.4477 13.7257 12 13.7257Z"
                      fill="#9098B1"
                    />
                  </svg>
                </div>
                <input
                  id="toggle"
                  type="password"
                  className="form-control black-input-control"
                  placeholder="Password"
                  name="password"
                  onChange={this.handlePasswordChange}
                  autoComplete="off"
                  required
                />
                <a href="#" onClick={this.togglePassword}>
                  <i className="fa fa-eye-slash" id="toggleIcon"></i>
                </a>
              </div>
            </div>
            <button
              type="button"
              className="red-btn f-width l-hight"
              onClick={this.userSignIn}
              disabled={this.state.loader}
            >
              Sign In
              {this.state.loader ? (
                <i className="fa fa-spinner fa-spin"></i>
              ) : null}
            </button>
            <div>
              {this.state.isLoginFailed ? (
                <span className="color-red">
                  <i>{this.state.loginFailedMessage}</i>
                </span>
              ) : null}
            </div>
            <div className="form-divider">
              <span>OR</span>
              <hr />
            </div>

            <div className="social-sign-btn">
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                onSuccess={this.responseGoogle}
                onFailure={this.responseGoogle}
                cookiePolicy={"single_host_origin"}
                responseType="code,token"
                render={(renderProps) => (
                  <button
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    // className="mr-b10"
                  >
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.8445 10.9332H19.2001V10.9H12V14.1H16.5213C15.8617 15.9628 14.0892 17.3001 12 17.3001C9.34922 17.3001 7.20001 15.1508 7.20001 12.5C7.20001 9.84922 9.34922 7.70001 12 7.70001C13.2236 7.70001 14.3368 8.16162 15.1844 8.91562L17.4473 6.65281C16.0185 5.3212 14.1072 4.5 12 4.5C7.58202 4.5 4 8.08202 4 12.5C4 16.9181 7.58202 20.5001 12 20.5001C16.4181 20.5001 20.0001 16.9181 20.0001 12.5C20.0001 11.9636 19.9449 11.44 19.8445 10.9332Z"
                        fill="#FFC107"
                      />
                      <path
                        d="M4.92188 8.77642L7.55029 10.704C8.26149 8.94322 9.9839 7.70001 11.9995 7.70001C13.2231 7.70001 14.3363 8.16162 15.1839 8.91562L17.4467 6.65281C16.0179 5.3212 14.1067 4.5 11.9995 4.5C8.92669 4.5 6.26188 6.23481 4.92188 8.77642Z"
                        fill="#FF3D00"
                      />
                      <path
                        d="M12 20.5003C14.0664 20.5003 15.944 19.7095 17.3636 18.4235L14.8876 16.3283C14.0844 16.9367 13.086 17.3003 12 17.3003C9.91915 17.3003 8.15234 15.9735 7.48674 14.1219L4.87793 16.1319C6.20194 18.7227 8.89075 20.5003 12 20.5003Z"
                        fill="#4CAF50"
                      />
                      <path
                        d="M19.8444 10.9332H19.2V10.9H12V14.1H16.5212C16.2044 14.9948 15.6288 15.7664 14.8864 16.3284C14.8868 16.328 14.8872 16.328 14.8876 16.3276L17.3636 18.4229C17.1884 18.5821 20 16.5 20 12.5C20 11.9636 19.9448 11.44 19.8444 10.9332Z"
                        fill="#1976D2"
                      />
                    </svg>
                    <span>Login with Google</span>
                  </button>
                )}
              ></GoogleLogin>
              {/* 
              <FacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
                fields="name,email,picture"
                callback={this.responseFacebook}
                icon={
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginRight: "70px" }}
                  >
                    <path
                      d="M18 2.5H15C13.6739 2.5 12.4021 3.02678 11.4645 3.96447C10.5268 4.90215 10 6.17392 10 7.5V10.5H7V14.5H10V22.5H14V14.5H17L18 10.5H14V7.5C14 7.23478 14.1054 6.98043 14.2929 6.79289C14.4804 6.60536 14.7348 6.5 15 6.5H18V2.5Z"
                      fill="#4092FF"
                    />
                  </svg>
                }
                cssClass="my-facebook-button-class"
              /> */}
            </div>
            <div className="s-foot-link text-center">
              <a onClick={this.handleOpen} style={{ color: "#C12A37" }}>
                Forgot Password?
              </a>
              <p className="mr-b0 mr-t10">
                Don’t have a account? <a href="/register">Register</a>
              </p>
            </div>
            <div className="guardian-logo1">
              <img
                className="f-logo mt-2"
                src={logo}
                alt="Cervais"
                title="Cervais"
              />
            </div>
          </form>
        </div>

        <AuthContext.Provider
          value={{
            authenticated: this.state.authenticated,
            login: this.loginHandler,
          }}
        ></AuthContext.Provider>

        {/* Modal  */}
        {this.state.showModal ? (
          <Modal
            open={this.state.showModal}
            aria-labelledby="forgetPasswordModal"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw"
              role="document"
            >
              <div className="modal-content modal_fp_content">
                <div className="modal-header forget_pw_header">
                  <button
                    type="button"
                    className="close modal_fp_close_btn"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.handleClose}
                  >
                    <span aria-hidden="true">
                      <svg
                        width="10"
                        height="18"
                        viewBox="0 0 10 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2.91937 9.00008L9.82886 1.70718L8.48899 0.292969L0.23964 9.00008L8.48899 17.7072L9.82886 16.293L2.91937 9.00008Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </button>
                  <h5
                    className="modal-title modal_fp_title "
                    id="exampleModalLabel"
                  >
                    Forgot Password
                  </h5>
                </div>

                <div className="modal-body fp_modal_body">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body mr-t20">
                    <div className="fp_label">
                      <h6>Enter Email Id*</h6>
                      <input
                        type="email"
                        className="fp_modal_input"
                        value={this.state.forgetPassword}
                        name="forgetPassword"
                        onChange={this.handleForgetPasswordChange}
                        autoFocus
                        autoComplete="off"
                        required
                      />
                    </div>
                  </div>
                  <span
                    style={{
                      color: "#C12A37",
                      fontSize: "15px",
                      fontWeight: "600",
                    }}
                  >
                    <i>{this.state.errorForgetPasswordEmail}</i>
                  </span>
                </div>
                <div className="modal-footer footer_fp_modal">
                  <button
                    type="button"
                    className="btn-fp-footer btn-Reset"
                    onClick={this.forgetPasswordMethod}
                    disabled={this.state.loader}
                  >
                    Reset
                    {this.state.loader ? (
                      <i className="fa fa-spinner fa-spin"></i>
                    ) : null}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}
      </section>
    );
  }
}

export default Login;
