import api from "./api";

function unRegisterDevice(deviceId) {
  return new Promise((resolve, reject) => {
    api.post("/device/unRegisterDevice", deviceId).then((response) => {
      resolve(response);
    });
  });
}

const devicesMethods = {
  unRegisterDevice,
};

export default devicesMethods;
