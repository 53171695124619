import React, { Component } from "react";
import "./404.css";

class Error404 extends Component {
  render() {
    return (
      <div className="ErrorTemplate">
        <h1>Oops!</h1>
        <h2>404 Not Found</h2>
        <div>Sorry, an error has occured, Requested page not found!</div>
        <div className="ErrorActions">
          <a href="/" className="btn btn-lg">
            <i className="fa fa-home" aria-hidden="true"></i>
             Back to login
          </a>
        </div>
      </div>
    );
  }
}

export default Error404;
