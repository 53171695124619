import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

class DataUsage extends Component {
  state = {
    enabled: true,
    seriesDonut: [25, 75],
    optionsDonut: {
      labels: ["data upload", "data download"],
      chart: {
        type: "donut",
        height: "100%",
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                label: "462.2",
                name: "MB",
              },
              tooltip: {
                enabled: false,
              },
            },
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
              show: false,
            },
          },
        },
      ],

      fill: {
        colors: ["#C12A37", "#2196F3"],
      },
    },
  };

  handleChange = (event) => {
    this.setState({
      enabled: event.target.checked,
    });
  };

  render() {
    return (
      <div className="registered-users mr-t20">
        <div className="text-center registered-title">
          <h6> Data Usage </h6>
        </div>
        <div className="row  mr-t30 mr-l50">
          <span className="col-md-6">
            <ReactApexChart
              options={this.state.optionsDonut}
              series={this.state.seriesDonut}
              type="donut"
              height={150}
              width={170}
            />
            <label className="mr-l40">
              <b>D-RISK Usage</b>
            </label>
          </span>

          <span className="col-md-4">
            <label className="mr-l50">
              <b style={{ color: "#fff" }}>Lori's D-RISK 4</b>
            </label>
            <div className="mr-l50">
              <div className="row cus_trial_toggle mr-t10">
                <span>Mobile Data</span>
                <label className="switch switch-trusted mr-l10">
                  <input
                    type="checkbox"
                    id="togBtn"
                    defaultValue="is_cot_disable"
                    checked={this.state.enabled}
                    onChange={this.handleChange}
                  />
                  <div className="slider round">
                    <span className="on">ON</span>
                    <span className="off">OFF</span>
                  </div>
                </label>
              </div>
            </div>
          </span>
        </div>
      </div>
    );
  }
}

export default DataUsage;
