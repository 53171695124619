import React, { Component } from "react";
import ToggleSideNav from "../UI/ToggleSideNav/ToggleSideNav";
import userMethods from "../../services/userApi";
import Modal from "@material-ui/core/Modal";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Feedback extends Component {
  state = {
    feedback: "",
    loader: false,
    successModal: false,
    showLogout: false,
    open: false,
    message: "",
  };

  changeFeedback = (event) => {
    this.setState({
      feedback: event.target.value,
    });
  };

  submitFeedback = async () => {
    this.setState({ loader: true });

    const response = await userMethods.userFeedback({
      feedback: this.state.feedback,
    });

    if (
      response.data.statusCode === 401 &&
      response.data.message == "Session expires. Please login again"
    ) {
      this.setState({
        showLogout: true,
      });
    }
    if (
      response.data.statusCode === 401 &&
      response.data.message != "Session expires. Please login again"
    ) {
      this.setState({
        open: true,
        message: response.data.message,
        loader: false,
      });
    }

    if (response.data.statusCode === 200) {
      this.setState({
        feedback: "",
        loader: false,
        successModal: true,
      });
    }
  };

  logoutUser = () => {
    window.sessionStorage.clear();
    this.props.history.replace({
      pathname: "/",
    });
  };

  handleClose = () => {
    this.setState({ successModal: false });
  };

  render() {
    return (
      <div className="routing-main-wrap">
        <div className="page-head">
          <Snackbar
            open={this.state.open}
            autoHideDuration={3000}
            onClose={() => this.setState({ open: false })}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Alert
              // onClose={handleClose}
              severity="info"
              sx={{ width: "100%" }}
            >
              {this.state.message}
            </Alert>
          </Snackbar>
          <h3>Feedback</h3>
          <ToggleSideNav batteryRefresh="false" />
        </div>

        <div className="rout-body">
          <div className="row mr-t30">
            <div className="col-md-12">
              <div className="device-details">
                <div className="row ">
                  <div className="col-md-12">
                    <h5 className="feedback-title">Enter Feedback*</h5>
                    <textarea
                      className="feedback-inputs mr-t30"
                      value={this.state.feedback}
                      onChange={this.changeFeedback}
                    />
                  </div>
                </div>
                <div className="col-md-12 mr-t20 mr-b0">
                  <button
                    type="button"
                    className="registration_btn last_reg_red_btn"
                    onClick={this.submitFeedback}
                  >
                    Submit
                    {this.state.loader ? (
                      <i className="fa fa-spinner fa-spin"></i>
                    ) : null}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.successModal ? (
          <Modal
            open={this.state.successModal}
            aria-labelledby="SuccessModal"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw"
              role="document"
            >
              <div className="modal-content modal_fp_content">
                <div className="modal-header forget_pw_header">
                  <button
                    type="button"
                    className="close modal_fp_close_btn"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.handleClose}
                  >
                    <span aria-hidden="true">
                      <svg
                        width="10"
                        height="18"
                        viewBox="0 0 10 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2.91937 9.00008L9.82886 1.70718L8.48899 0.292969L0.23964 9.00008L8.48899 17.7072L9.82886 16.293L2.91937 9.00008Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </button>
                  <h5
                    className="modal-title modal_fp_title "
                    id="exampleModalLabel"
                  >
                    Success!
                  </h5>
                </div>

                <div className="modal-body fp_modal_body">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body mr-t20 mr-b20 text-center">
                    <h6 style={{ color: "white" }}>Recieved Feedback!</h6>
                  </div>
                </div>

                <div className="modal-footer footer_fp_modal">
                  <button
                    type="button"
                    className="btn-fp-footer btn-Reset"
                    onClick={this.handleClose}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}

        {this.state.showLogout ? (
          <Modal
            open={this.state.showLogout}
            aria-labelledby="DeleteUserProfile"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw"
              role="document"
            >
              <div className="modal-content modal_fp_content">
                <div className="modal-header forget_pw_header">
                  <h5
                    className="modal-title modal_fp_title "
                    id="exampleModalLabel"
                  >
                    Session Expired
                  </h5>
                </div>
                <div className="modal-body fp_modal_body">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body mr-t20 mr-b10">
                    <h6 className="delete-dialog">
                      Please login again.
                      <br />
                      <br />
                    </h6>
                  </div>
                </div>
                <div className="modal-footer footer_fp_modal">
                  <button
                    type="button"
                    className="btn-fp-footer btn-Reset"
                    onClick={this.logoutUser}
                    // disabled={this.state.loader}
                  >
                    Okay
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default Feedback;
