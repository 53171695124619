import React from "react";

const CheckBox = (props) => {
  return (
    <div className="col-6 col-sm-auto">
      <div className="d-info-card">
        <label>{props.name}</label>
        <div className="cus_trial_toggle">
          <label className="switch switch-trusted">
            <input
              key={props.id}
              onChange={props.onChange}
              type="checkbox"
              checked={props.isChecked}
              value={props.value}
              id="togBtn"
              disabled={props.isDisabled}
            />
            <div className="slider round">
              <span className="on">ON</span>
              <span className="off">OFF</span>
            </div>
          </label>
        </div>
      </div>
    </div>
  );
};

export default CheckBox;
