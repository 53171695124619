import React, { Component } from "react";
import ToggleSideNav from "../../UI/ToggleSideNav/ToggleSideNav";
import BackButton from "../../UI/BackButton/BackButton";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker,
} from "@material-ui/pickers";
import Moment from "@date-io/moment";
import userMethods from "../../../services/userApi";
import Modal from "@material-ui/core/Modal";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class IOTDevices extends Component {
  state = {
    device: {},
    issuesDate: moment(new Date()).format("MM/DD/yyyy"),
    snortIssuesList: [],
    device_id: JSON.parse(window.sessionStorage.getItem("ConnectedDevice"))
      ?.device_id,
    openEditNameModal: false,
    deviceName: "",
    errorDeviceName: "",
    loader: false,
    openExportModal: false,
    startDate: moment(),
    endDate: moment(),
    btnloader: false,
    name: "", //name to show and change
    showLoader: false,
    open: false,
  };

  componentDidMount = async () => {
    await this.setState({
      device: this.props.location.state.device,
      name: this.props.location.state.device.name,
    });

    this.fetchVulnerabilityLogsList();
  };

  handleSnortIssuesDate = async (date) => {
    await this.setState({
      issuesDate: moment(date).format("MM/DD/yyyy"),
    });

    this.fetchVulnerabilityLogsList();
  };

  fetchVulnerabilityLogsList = async () => {
    this.setState({
      showLoader: true,
    });
    let logsObject = {
      device_id: this.state.device_id,
      date: this.state.issuesDate,
      ip_address: this.state.device.ip_address,
    };

    const response = await userMethods.getVulnerabilityLogsForSnort(logsObject);

    if (response.data.statusCode === 200) {
      this.setState({
        snortIssuesList: response.data.result,
        showLoader: false,
      });
    }
  };

  handleOpen = () => {
    this.setState({
      openEditNameModal: true,
      deviceName: this.state.device.name,
    });
  };

  handleNameChange = (event) => {
    if (event.target.value === "") {
      this.setState({ errorDeviceName: "Please enter device name" });
    } else {
      this.setState({
        errorDeviceName: "",
        deviceName: event.target.value,
      });
    }
  };

  renameDevice = async () => {
    this.setState({ loader: true });

    var renameObject = {
      device_id: this.state.device_id,
      name: this.state.deviceName,
      mac_address: this.state.device.mac_address,
    };

    const response = await userMethods.iotRename(renameObject);

    if (response.data.statusCode === 200) {
      this.setState({ loader: false, openEditNameModal: false });
      this.setState({ name: response.data.result[0].name });
      this.fetchVulnerabilityLogsList();
    }
  };

  handleClose = () => {
    this.setState({ openEditNameModal: false, openExportModal: false });
  };

  handleOpenExportPopup = () => {
    this.setState({ openExportModal: true });
  };

  handleStartDate = async (date) => {
    await this.setState({
      startDate: moment(date).format("MM/DD/yyyy"),
    });
  };

  handleEndDate = async (date) => {
    await this.setState({
      endDate: moment(date).format("MM/DD/yyyy"),
    });
  };

  handleExportToCSV = async () => {
    this.setState({
      btnloader: true,
    });

    let exportObject = {
      device_id: this.state.device_id,
      ip_address: this.state.device.ip_address,
      start_date: moment(this.state.startDate).format("MM/DD/yyyy"),
      end_date: moment(this.state.endDate).format("MM/DD/yyyy"),
    };

    const response = await userMethods.exportCSV(exportObject);

    if (response.data.statusCode === 200) {
      let node = response.data.result ? response.data.result.url : "";
      window.open(node);
      this.setState({ btnloader: false, openExportModal: false });
    } else {
      this.setState({
        btnloader: false,
        openExportModal: false,
        message: "No data to export",
        open: true,
      });
    }
  };

  render() {
    return (
      <div className="routing-main-wrap">
        <Snackbar
          open={this.state.open}
          autoHideDuration={3000}
          onClose={() => this.setState({ open: false })}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert
            // onClose={handleClose}
            severity="error"
            sx={{ width: "100%" }}
          >
            {this.state.message}
          </Alert>
        </Snackbar>
        <div className="page-head">
          <div className="page_head_btn ">
            <div className="aboutus-flex d-flex align-items-center">
              <BackButton count={0} getPath="/dashboard/home" />
              <h3 className="pd-l10"> IoT Device Details</h3>
            </div>
          </div>
          <ToggleSideNav batteryRefresh="false" />
        </div>
        <div className="rout-body">
          <div className="row">
            <div className="col-md-12">
              <div className="iot-device-details">
                <div className="black-card d-d-card">
                  <div className="d-d-card-head mr-t10 ">
                    <div className="card_wifi_name d-flex align-items-center">
                      <svg
                        width="20"
                        height="18"
                        viewBox="0 0 20 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 3.99702C2.82855 1.40534 6.35972 -0.00607102 10 1.96299e-05C13.7887 1.96299e-05 17.2679 1.49702 20 3.99702L18.3333 6.33001C15.9761 4.17054 13.0335 2.99466 10 3.00002C6.8435 3.00002 3.94341 4.24801 1.66667 6.33001L0 3.99702ZM2.77719 7.88701C4.82016 6.01501 7.37068 4.99555 10 5.00001C12.7356 5.00001 15.2485 6.08101 17.2228 7.88601L15.5561 10.22C13.9845 8.78022 12.0225 7.99629 10 8.00001C7.89567 8.00001 5.96286 8.83201 4.44386 10.22L2.77719 7.88601V7.88701ZM5.55526 11.777C6.81242 10.6249 8.38194 9.99741 10 10C11.6835 10 13.2299 10.665 14.4447 11.776L12.7781 14.11C11.9922 13.3902 11.0113 12.9982 10 13C8.98875 12.9982 8.00778 13.3902 7.22193 14.11L5.55526 11.776V11.777ZM8.33333 15.667C8.80464 15.2347 9.39319 14.9992 10 15C10.6313 15 11.2113 15.25 11.6667 15.666L10 18L8.33333 15.667Z"
                          fill="#C12A37"
                        />
                      </svg>

                      <h4 className="mr-l20 ">{this.state.name}</h4>
                    </div>

                    <div>
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => this.handleOpen()}
                        style={{ float: "right" }}
                        className="col-12"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M20 18V11H18V18H2V2H9V0H2C0.89543 0 0 0.89543 0 2V18C0 19.1046 0.89543 20 2 20H18C19.1046 20 20 19.1046 20 18ZM17.1781 0.723423C16.7197 0.26142 16.0921 0 15.4374 0C14.7834 0 14.1564 0.26083 13.6954 0.724628L5.3265 9.09342C4.57867 9.75233 4.08844 10.7328 4.00325 11.7873L4 15.0023V16.0023H8.1346C9.2689 15.9245 10.259 15.4295 10.9575 14.6238L19.279 6.30584C19.7407 5.84416 20.0001 5.21799 20.0001 4.56508C20.0001 3.91217 19.7407 3.286 19.279 2.82432L17.1781 0.723423ZM8.06398 14.0048C8.59821 13.967 9.09549 13.7184 9.49479 13.2616L15.5567 7.19972L12.8024 4.44527L6.6961 10.5496C6.29095 10.9079 6.04031 11.4092 6 11.8678V14.0029L8.06398 14.0048ZM14.2169 3.03128L16.9709 5.78551L17.8648 4.89162C17.9514 4.80502 18.0001 4.68756 18.0001 4.56508C18.0001 4.4426 17.9514 4.32514 17.8648 4.23854L15.7611 2.13486C15.6755 2.04855 15.5589 2 15.4374 2C15.3158 2 15.1992 2.04855 15.1136 2.13486L14.2169 3.03128Z"
                          fill="#c12a37"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="device-info-wrap mr-t20 mr-l40 mr-b10">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="d-info-card d-info-card-iot-device">
                          <label>IP Address</label>
                          <h4>{this.state.device.ip_address}</h4>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="d-info-card">
                          <label>MAC</label>
                          <h4>{this.state.device.mac_address}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="logs-wrap mr-t30">
                <div className="logs-list-wrap mr-t15">
                  <div className="iot-device-details">
                    <div className="black-card d-d-card">
                      <div className="row">
                        <div className="col-6 text-left">
                          <h4> Issues </h4>
                        </div>
                        <div className="col-6 text-right">
                          <button
                            type="button"
                            className="red_btn deny_btn"
                            disabled={this.state.loader}
                            onClick={this.handleOpenExportPopup}
                          >
                            <h6 style={{ fontSize: "12px" }}>
                              Export Issues
                              {this.state.loader ? (
                                <i className="fa fa-spinner fa-spin"></i>
                              ) : null}
                            </h6>
                          </button>
                        </div>
                      </div>
                      <div className="mr-t20 text-center">
                        <MuiPickersUtilsProvider utils={Moment}>
                          <KeyboardDatePicker
                            value={this.state.issuesDate}
                            onChange={this.handleSnortIssuesDate}
                            format="MM/DD/yyyy"
                            variant="inline"
                            className="datepicker_input"
                          />
                        </MuiPickersUtilsProvider>
                      </div>

                      {this.state.snortIssuesList.length > 0 ? (
                        <div className="logs-list-wrap mr-t15">
                          <ul className="logs-list">
                            {this.state.snortIssuesList.map((issue, index) => (
                              <li key={index}>
                                <div className="l-icon">
                                  <svg
                                    width="22"
                                    height="22"
                                    viewBox="0 0 22 22"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M11 22C4.92487 22 0 17.0751 0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22ZM18.0319 16.6177C19.2635 15.078 20 13.125 20 11C20 6.02944 15.9706 2 11 2C8.87499 2 6.92199 2.73647 5.38231 3.9681L18.0319 16.6177ZM16.6177 18.0319C15.078 19.2635 13.125 20 11 20C6.02944 20 2 15.9706 2 11C2 8.87499 2.73647 6.92199 3.9681 5.38231L16.6177 18.0319Z"
                                      fill="#C12A37"
                                    />
                                  </svg>
                                </div>
                                <div className="logs-details">
                                  <h5>Blocked</h5>
                                  <p>
                                    {issue.website_access}: {issue.description}
                                  </p>

                                  <time>
                                    {moment(issue.date).format(
                                      "MM/DD/yyyy HH:MM A"
                                    )}
                                  </time>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ) : this.state.showLoader === true ? (
                        <CircularProgress
                          color="secondary"
                          style={{ margin: "5% 0% 5% 50%" }}
                        />
                      ) : (
                        <div className="text-center mr-b20 mr-t20">
                          <h6 style={{ color: "#C12A37" }}>
                            No logs available
                          </h6>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.openEditNameModal ? (
          <Modal
            open={this.state.openEditNameModal}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw"
              role="document"
            >
              <div className="modal-content modal_fp_content">
                <div className="modal-header forget_pw_header">
                  <button
                    type="button"
                    className="close modal_fp_close_btn"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.handleClose}
                  >
                    <span aria-hidden="true">
                      <svg
                        width="10"
                        height="18"
                        viewBox="0 0 10 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2.91937 9.00008L9.82886 1.70718L8.48899 0.292969L0.23964 9.00008L8.48899 17.7072L9.82886 16.293L2.91937 9.00008Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </button>
                  <h5
                    className="modal-title modal_fp_title "
                    id="exampleModalLabel"
                  >
                    Rename Device
                  </h5>
                </div>

                <div className="modal-body fp_modal_body">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body">
                    <div className="fp_label">
                      <h6>
                        Device Name<em className="mandatory">* </em>
                      </h6>
                      <input
                        type="text"
                        className="fp_modal_input"
                        defaultValue={this.state.name}
                        onChange={this.handleNameChange}
                      />
                    </div>
                    <div className="color-red">
                      <i>{this.state.errorDeviceName}</i>
                    </div>
                  </div>
                </div>
                <div className="modal-footer footer_fp_modal">
                  <button
                    type="button"
                    className="btn-fp-footer btn-Reset"
                    onClick={this.renameDevice}
                    disabled={this.state.loader}
                  >
                    Update
                    {this.state.loader ? (
                      <i className="fa fa-spinner fa-spin"></i>
                    ) : null}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}

        {this.state.openExportModal ? (
          <Modal
            open={this.state.openExportModal}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content modal_fp_content">
                <div className="modal-header forget_pw_header">
                  <button
                    type="button"
                    className="close modal_fp_close_btn"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.handleClose}
                  >
                    <span aria-hidden="true">
                      <svg
                        width="10"
                        height="18"
                        viewBox="0 0 10 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2.91937 9.00008L9.82886 1.70718L8.48899 0.292969L0.23964 9.00008L8.48899 17.7072L9.82886 16.293L2.91937 9.00008Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </button>
                  <h5
                    className="modal-title modal_fp_title "
                    id="exampleModalLabel"
                  >
                    Export Issues
                  </h5>
                </div>

                <div className="modal-body fp_modal_body">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body d-flex">
                    <MuiPickersUtilsProvider utils={Moment}>
                      <div className="col-6 text-left">
                        <div className="d-info-card">
                          <label>Start Date</label>
                        </div>
                        <DatePicker
                          value={this.state.startDate}
                          onChange={this.handleStartDate}
                          format="MM/DD/yyyy"
                          className="datepicker-input "
                          inputVariant="outlined"
                        />
                      </div>

                      <div className="col-6 text-right">
                        <div className="d-info-card text-left">
                          <label>End Date</label>
                        </div>
                        <DatePicker
                          value={this.state.endDate}
                          onChange={this.handleEndDate}
                          format="MM/DD/yyyy"
                          className="datepicker-input"
                          inputVariant="outlined"
                          minDate={this.state.startDate}
                        />
                      </div>
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
                <div className="modal-footer footer_fp_modal">
                  <button
                    type="button"
                    className="btn-fp-footer btn-Reset"
                    onClick={this.handleClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn-fp-footer btn-Reset"
                    onClick={this.handleExportToCSV}
                    disabled={this.state.btnloader}
                  >
                    Download
                    {this.state.btnloader ? (
                      <i className="fa fa-spinner fa-spin"></i>
                    ) : null}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default IOTDevices;
