import React, { Component } from "react";
import ToggleSideNav from "../UI/ToggleSideNav/ToggleSideNav";
import settingsMethods from "../../services/settingsApi";
import {
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import Moment from "@date-io/moment";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import userMethods from "../../services/userApi";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Access extends Component {
  state = {
    parentalSettings: [],
    device_id: JSON.parse(window.sessionStorage.getItem("ConnectedDevice"))
      ?.device_id,
    daysList: [
      { id: 1, name: "Monday", isChecked: false },
      { id: 2, name: "Tuesday", isChecked: false },
      { id: 3, name: "Wednesday", isChecked: false },
      { id: 4, name: "Thursday", isChecked: false },
      { id: 5, name: "Friday", isChecked: false },
      { id: 6, name: "Saturday", isChecked: false },
      { id: 7, name: "Sunday", isChecked: false },
    ],
    dayRule: [],
    selectedLimitedAccess: "limited_access_1",
    scheduleTime: "active_schedule_time",
    keywords: [],
    startTime: "",
    newStartTime: "",
    endTime: "",
    newEndTime: "",
    loader: false,
    devicesList: [],
    openListModal: false,
    selectDevicesList: [],
    selected: [],
    isUpdatedList: 0,
    connectedDevices: [],
    showLogout: false,
    open: false,
    message: "",
  };

  componentDidMount() {
    this.fetchParentalControl();
    this.fetchAllDriskDevices();
  }

  fetchParentalControl = async () => {
    const response = await settingsMethods.getParentalSettings({
      device_id: this.state.device_id,
    });

    if (response.data.statusCode === 200) {
      this.setState({
        parentalSettings: response.data.result[0],
        dayRule: response.data.result[0].day_rule,
        keywords: response.data.result[0].keyword,
        startTime: response.data.result[0].start_time,
        endTime: response.data.result[0].end_time,
        devicesList: response.data.result[0].devices,
      });

      this.setValues();
    }
    if (
      response.data.statusCode === 401 &&
      response.data.message == "Session expires. Please login again"
    ) {
      this.setState({
        showLogout: true,
      });
    }
    if (
      response.data.statusCode === 401 &&
      response.data.message != "Session expires. Please login again"
    ) {
      this.setState({
        open: true,
        message: response.data.message,
        loader: false,
      });
    }
  };

  logoutUser = () => {
    window.sessionStorage.clear();
    this.props.history.replace({
      pathname: "/",
    });
  };

  fetchAllDriskDevices = async () => {
    const response = await userMethods.getAllDriskDevices();
    var connectedArray = [];

    if (response.data.statusCode === 200) {
      var result = response.data.result.device_details;

      result.forEach((element) => {
        if (element.is_connected === "connected") {
          connectedArray.push(element);
        }
      });

      {
        connectedArray[0] != null
          ? this.setState({
              connectedDevices: connectedArray[0].name,
            })
          : this.setState({
              connectedDevices: "",
            });
      }
      // console.log(this.state.connectedDevices);
    }
    if (
      response.data.statusCode === 401 &&
      response.data.message == "Session expires. Please login again"
    ) {
      this.setState({
        showLogout: true,
      });
    }
  };

  setValues() {
    const daysList = this.state.daysList;
    const dayRule = this.state.dayRule;

    daysList.forEach((day) => {
      dayRule.forEach((rule) => {
        if (day.name === rule) {
          day.isChecked = true;
        }
      });
    });

    this.setState({
      daysList: daysList,
    });

    var limitedAccess = this.state.parentalSettings;

    if (limitedAccess.limited_access_1 === 1) {
      this.setState({ selectedLimitedAccess: "limited_access_1" });
    }

    if (limitedAccess.limited_access_2 === 1) {
      this.setState({ selectedLimitedAccess: "limited_access_2" });
    }

    if (limitedAccess.limited_access_3 === 1) {
      this.setState({ selectedLimitedAccess: "limited_access_3" });
    }

    if (limitedAccess.active_schedule_time === 1) {
      this.setState({ scheduleTime: "active_schedule_time" });
    }

    if (limitedAccess.inactive_schedule_time === 1) {
      this.setState({ scheduleTime: "inactive_schedule_time" });
    }

    if (this.state.startTime !== null || this.state.startTime !== "") {
      var starttimeStr = this.state.startTime;

      var timestart = moment(starttimeStr, "HH:mm A");

      this.setState({
        newStartTime: timestart,
      });
    }

    if (this.state.endTime !== null || this.state.endTime !== "") {
      var endtimeStr = this.state.endTime;

      var timeend = moment(endtimeStr, "HH:mm A");

      this.setState({
        newEndTime: timeend,
      });
    }
  }

  handleChange = (event, isChecked) => {
    const daysList = this.state.daysList;

    daysList.forEach((day) => {
      if (day.name === event.target.value) {
        day.isChecked = isChecked;
      }
    });

    this.setState({
      daysList: daysList,
    });
    // console.log(daysList);
  };

  onChangeStartTime = (hours) => {
    this.setState({
      newStartTime: hours,
    });
    // console.log(this.state.newStartTime);
  };

  onChangeEndTime = (hours) => {
    this.setState({
      newEndTime: hours,
    });
  };

  changeLimitAccess = (event) => {
    this.setState({
      selectedLimitedAccess: event.target.value,
    });
  };

  changeScheduleTime = (event) => {
    this.setState({
      scheduleTime: event.target.value,
    });
  };

  changeKeywords = (i, event) => {
    var arrKey = [...this.state.keywords];

    arrKey[i] = event.target.value;

    this.setState({
      keywords: arrKey,
    });
  };

  addNewInput = () => {
    this.setState((prevState) => ({ keywords: [...prevState.keywords, ""] }));
  };

  updateParentalControlSettings = async () => {
    this.setState({ loader: true });

    const daysList = this.state.daysList;

    var daysArr = [];

    daysList.forEach((day) => {
      if (day.isChecked === true) {
        daysArr.push(day.name);
      }
    });

    let listOfDevices = this.state.selectDevicesList;
    let devices = [];

    listOfDevices.forEach((device) => {
      if (device.isSelected === "true") {
        devices.push({
          name: device.name,
          ip_address: device.ip_address,
        });
      }
    });

    var updatedObject = {
      device_id: this.state.device_id,
      limited_access_1:
        this.state.selectedLimitedAccess === "limited_access_1" ? 1 : 0,
      // limited_access_2:
      //   this.state.selectedLimitedAccess === "limited_access_2" ? 1 : 0,
      limited_access_3:
        this.state.selectedLimitedAccess === "limited_access_3" ? 1 : 0,
      day_rule: daysArr.join(),
      active_schedule_time:
        this.state.scheduleTime === "active_schedule_time" ? 1 : 0,
      inactive_schedule_time:
        this.state.scheduleTime === "inactive_schedule_time" ? 1 : 0,
      end_time: moment(this.state.newEndTime).format("hh:mm A"),
      start_time: moment(this.state.newStartTime).format("hh:mm A"),
      keyword: this.state.keywords.join(),
      devices: devices.length === 0 ? this.state.devicesList : devices,
    };

    const response = await settingsMethods.updateParentalSettings(
      updatedObject
    );

    if (response.data.statusCode === 200) {
      this.fetchParentalControl();
      this.setState({
        loader: false,
        open: true,
        message: response.data.message,
      });
    } else {
      this.setState({
        // loader: false,
        open: true,
        message: response.data.message,
      });
    }
  };

  handleOpenList = async () => {
    if (this.state.isUpdatedList === 0) {
      const response = await settingsMethods.getAllIOTDevices({
        device_id: this.state.device_id,
      });

      if (response.data.statusCode === 200) {
        this.setState({
          openListModal: true,
          selectDevicesList: response.data.result.devices,
        });
      }
    } else {
      this.setState({
        openListModal: true,
      });
    }
  };

  handleClose = () => {
    this.setState({ openListModal: false });
  };

  handleClickSelection = (event) => {
    const selectedDevicesList = this.state.selectDevicesList;
    let updatedDevicesList = [];

    selectedDevicesList.forEach((device) => {
      if (device.name === event.target.value) {
        device.isSelected = JSON.stringify(event.target.checked);
      }

      if (device.isSelected === "true") {
        updatedDevicesList.push(device);
      }
    });

    this.setState({
      selectDevicesList: selectedDevicesList,
      devicesList: updatedDevicesList,
    });
  };

  updateSelections = async () => {
    await this.setState({
      openListModal: false,
      selectDevicesList: this.state.selectDevicesList,
      isUpdatedList: 1,
    });
  };

  deleteKeyword = (keyword, i) => {
    let updatedKeywords = this.state.keywords;

    let filteredKeywords = updatedKeywords.filter(function (value, index) {
      return value !== keyword && i !== index;
    });

    this.setState({
      keywords: filteredKeywords,
    });
  };

  render() {
    return (
      <MuiPickersUtilsProvider utils={Moment}>
        <div className="routing-main-wrap">
          <div className="page-head">
            <Snackbar
              open={this.state.open}
              autoHideDuration={3000}
              onClose={() => this.setState({ open: false })}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
            >
              <Alert
                // onClose={handleClose}
                severity="info"
                sx={{ width: "100%" }}
              >
                {this.state.message}
              </Alert>
            </Snackbar>
            <h3>Access Controls</h3>
            {this.state.connectedDevices != null ? (
              <h3>{this.state.connectedDevices}</h3>
            ) : null}

            <ToggleSideNav batteryRefresh="false" />
          </div>
          {this.state.connectedDevices.length > 0 ? (
            <div className="rout-body">
              <div className="controls-wrap">
                <div className="black-card d-d-card">
                  <div className="controls-card">
                    <div className="c-title">
                      <h4>1. Select the Device</h4>
                    </div>
                    <div className="net-option mr-t20">
                      {this.state.devicesList.length > 0 ? (
                        <div className="row">
                          {this.state.devicesList.map((device, index) => (
                            <div className="col-sm-4" key={index}>
                              <h5>
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M2.07491 9.1005C2.04983 9.12559 2.02486 9.15075 2 9.17598L3.41426 10.5902C3.43908 10.565 3.46404 10.5398 3.48913 10.5147C8.15033 5.85352 15.6921 5.82856 20.3842 10.4399L21.7984 9.02559C16.3253 3.63328 7.51717 3.65825 2.07491 9.1005ZM4.75295 11.9289C8.6582 8.02369 14.9898 8.02369 18.8951 11.9289L17.4809 13.3431C14.3567 10.219 9.29136 10.219 6.16717 13.3431L4.75295 11.9289ZM16.0667 14.7574C13.7235 12.4142 9.92453 12.4142 7.58138 14.7574L8.99559 16.1716C10.5577 14.6095 13.0904 14.6095 14.6524 16.1716L16.0667 14.7574ZM13.2382 17.5858C12.4572 16.8047 11.1909 16.8047 10.4098 17.5858L11.824 19L13.2382 17.5858Z"
                                    fill="white"
                                  />
                                </svg>
                                {device.name}
                              </h5>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="text-center registered-title mr-b20 mr-t20">
                          <h6 style={{ color: "#7C8F97", fontSize: "12px" }}>
                            No Devices Selected
                          </h6>
                        </div>
                      )}

                      <button
                        type="button"
                        className="trans-red-btn mr-t20"
                        onClick={this.handleOpenList}
                      >
                        Add/Remove device
                      </button>
                    </div>
                  </div>
                </div>

                <div className="black-card d-d-card">
                  <div className="controls-card">
                    <div className="c-title mr-b20">
                      <h4>2. Create the Access Control Rules and Schedules </h4>
                      <h5 className="mr-t20 mr-b20">Limit access by</h5>
                    </div>
                    <div className="block-option controls-option">
                      <RadioGroup
                        aria-label="limitedAccess"
                        name="selectedLimitedAccess"
                        value={this.state.selectedLimitedAccess}
                        onChange={this.changeLimitAccess}
                        row
                      >
                        <FormControlLabel
                          value="limited_access_1"
                          control={<Radio />}
                          label="Block websites and embedded keywords"
                          checked={
                            this.state.selectedLimitedAccess ===
                            "limited_access_1"
                          }
                        />
                        {/* <FormControlLabel
                        value="limited_access_2"
                        control={<Radio />}
                        label="Allow websites and embedded keywords"
                        checked={
                          this.state.selectedLimitedAccess ===
                          "limited_access_2"
                        }
                      /> */}
                        <FormControlLabel
                          value="limited_access_3"
                          control={<Radio />}
                          label="Blocking all internet access"
                          checked={
                            this.state.selectedLimitedAccess ===
                            "limited_access_3"
                          }
                        />
                      </RadioGroup>
                    </div>
                  </div>
                </div>

                <div className="black-card d-d-card">
                  <div className="controls-card">
                    <div className="c-title mr-b20">
                      <h4>3. Add keyword or website</h4>
                    </div>
                    <div className="web-input mr-b10">
                      <div className="row">
                        {this.state.keywords.map((keyword, i) => (
                          <div className="col-sm-6 col-md-6" key={i}>
                            <div className="form-group black-input mr-b10 d-flex">
                              <input
                                type="text"
                                className="form-control black-input-control "
                                placeholder="www.example.com"
                                name={keyword || ""}
                                value={keyword || ""}
                                onChange={this.changeKeywords.bind(this, i)}
                              />
                              <i
                                className="fa fa-trash mr-l10"
                                aria-hidden="true"
                                onClick={() => this.deleteKeyword(keyword, i)}
                                style={{ color: "#C12A37" }}
                              ></i>
                            </div>
                          </div>
                        ))}
                      </div>
                      <button
                        type="button"
                        className="trans-red-btn mr-t20"
                        onClick={this.addNewInput}
                      >
                        Add more
                      </button>
                    </div>
                  </div>
                </div>

                <div className="black-card d-d-card">
                  <div className="controls-card ">
                    <div className="c-title mr-b20">
                      <h4>3. Create Schedule</h4>
                    </div>
                    <div className="web-input mr-b10 memebr_head">
                      <h4 className="mr-t20 mr-b10 ">Day</h4>
                      <div className="col-sm-6 col-md-12">
                        {this.state.daysList.map((element, i) => (
                          <label className="checkbox-label" key={i}>
                            <Checkbox
                              key={element.id}
                              value={element.name}
                              onChange={this.handleChange}
                              checked={element.isChecked}
                              inputProps={{
                                "aria-label": "primary checkbox",
                              }}
                            />
                            <span className="mr-r5"></span>
                            {element.name}
                          </label>
                        ))}
                      </div>

                      <h4 className="mr-t10 mr-b10">Time</h4>

                      <div className="col-sm-6 col-md-12 mr-l10">
                        <RadioGroup
                          aria-label="scheduleTime"
                          name="scheduleTime"
                          value={this.state.scheduleTime}
                          onChange={this.changeScheduleTime}
                          row
                        >
                          <FormControlLabel
                            value="active_schedule_time"
                            control={<Radio />}
                            label="Rule will be active at the schedule time"
                            checked={
                              this.state.scheduleTime === "active_schedule_time"
                            }
                          />
                          <FormControlLabel
                            value="inactive_schedule_time"
                            control={<Radio />}
                            label="Rule will be inactive at the schedule time"
                            checked={
                              this.state.scheduleTime ===
                              "inactive_schedule_time"
                            }
                          />
                        </RadioGroup>
                      </div>

                      <h4 className="mr-t20 mr-b20">Start Time</h4>
                      <div className="row mr-l20">
                        <KeyboardTimePicker
                          openTo="hours"
                          id="time-picker"
                          value={this.state.newStartTime}
                          onChange={this.onChangeStartTime}
                          KeyboardButtonProps={{
                            "aria-label": "change time",
                          }}
                        />
                      </div>

                      <h4 className="mr-t20 mr-b20">End Time</h4>
                      <div className="row mr-l20">
                        <KeyboardTimePicker
                          openTo="hours"
                          id="time-picker"
                          value={this.state.newEndTime}
                          onChange={this.onChangeEndTime}
                          KeyboardButtonProps={{
                            "aria-label": "change time",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  type="button"
                  className="btn-fp-footer btn-Reset mr-b20"
                  style={{ float: "right" }}
                  onClick={this.updateParentalControlSettings}
                  disabled={this.state.loader}
                >
                  <h6>
                    Save
                    {this.state.loader ? (
                      <i className="fa fa-spinner fa-spin"></i>
                    ) : null}
                  </h6>
                </button>
              </div>
            </div>
          ) : (
            <div
              style={{
                paddingTop: "20px",
                display: "flex",
                justifyContent: "center",
                fontSize: "20px",
                color: "#BC2835",
              }}
            >
              No device connected
            </div>
          )}

          {this.state.openListModal ? (
            <Modal
              open={this.state.openListModal}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw"
                role="document"
              >
                <div className="modal-content modal_fp_content">
                  <div className="modal-header forget_pw_header">
                    <button
                      type="button"
                      className="close modal_fp_close_btn"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={this.handleClose}
                    >
                      <span aria-hidden="true">
                        <svg
                          width="10"
                          height="18"
                          viewBox="0 0 10 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.91937 9.00008L9.82886 1.70718L8.48899 0.292969L0.23964 9.00008L8.48899 17.7072L9.82886 16.293L2.91937 9.00008Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                    </button>
                    <h5
                      className="modal-title modal_fp_title "
                      id="exampleModalLabel"
                    >
                      Paired IOT Devices
                    </h5>
                  </div>

                  <div className="modal-body fp_modal_body custom-modal">
                    <div className="col-12  modal_fp_body">
                      {this.state.selectDevicesList.map((device, index) => (
                        <div key={index}>
                          <label className="checkbox-label">
                            <Checkbox
                              key={index}
                              onChange={(event) =>
                                this.handleClickSelection(event, device)
                              }
                              inputProps={{
                                "aria-label": "primary checkbox",
                              }}
                              checked={
                                device.isSelected === "true" ? true : false
                              }
                              value={device.name}
                            />
                            <span className="mr-r20"></span>
                            {device.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="modal-footer footer_fp_modal">
                    <button
                      type="button"
                      className="btn-fp-footer btn-Reset"
                      onClick={this.updateSelections}
                      disabled={this.state.loader}
                    >
                      Update
                      {/* &nbsp;
                      {this.state.loader ? (
                        <i className="fa fa-spinner fa-spin"></i>
                      ) : null} */}
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
          ) : null}

          {this.state.showLogout ? (
            <Modal
              open={this.state.showLogout}
              aria-labelledby="DeleteUserProfile"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw"
                role="document"
              >
                <div className="modal-content modal_fp_content">
                  <div className="modal-header forget_pw_header">
                    <h5
                      className="modal-title modal_fp_title "
                      id="exampleModalLabel"
                    >
                      Session Expired
                    </h5>
                  </div>
                  <div className="modal-body fp_modal_body">
                    <div className="col-12 col-sm-12 col-md-12 modal_fp_body mr-t20 mr-b10">
                      <h6 className="delete-dialog">
                        Please login again.
                        <br />
                        <br />
                      </h6>
                    </div>
                  </div>
                  <div className="modal-footer footer_fp_modal">
                    <button
                      type="button"
                      className="btn-fp-footer btn-Reset"
                      onClick={this.logoutUser}
                    >
                      Okay
                      {/* {this.state.loader ? <i className="fa fa-spinner fa-spin"></i> : null} */}
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
          ) : null}
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

export default Access;
