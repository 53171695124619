import React, { Component } from "react";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import Moment from "@date-io/moment";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import Modal from "@material-ui/core/Modal";
class NetworkSecurity extends Component {
  state = {
    startDate: moment(),
    endDate: moment(),
    series: [
      {
        name: "Threats",
        data: [10, 90, 35, 100, 29, 70, 39, 91, 128],
      },
      {
        name: "Mitigated",
        data: [20, 65, 55, 81, 49, 50, 69, 71, 118],
      },
      {
        name: "Quarantined",
        data: [30, 50, 75, 61, 69, 30, 99, 81, 108],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      colors: ["#c12a37", "#62b3cf", "#228B22"],
      dataLabels: {
        enabled: true,
        offsetX: -2,
        background: {
          borderRadius: 5,
        },
        style: {
          fontSize: "10px",
        },
      },
      legend: {
        show: true,
        labels: {
          colors: ["#fff"],
          useSeriesColors: true,
        },
      },
      stroke: {
        curve: "straight",
      },
      title: {
        text: "",
        align: "left",
      },
      markers: {
        size: 0,
      },
      xaxis: {
        categories: [
          "2 Aug",
          "4 Aug",
          "6 Aug",
          "8 Aug",
          "10 Aug",
          "12 Aug",
          "14 Aug",
          "16 Aug",
          "18 Aug",
        ],
        labels: {
          style: {
            colors: "#969696",
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            cssClass: "apexcharts-yaxis-label",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#969696",
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            cssClass: "apexcharts-yaxis-label",
          },
        },
      },
    },
    deviceSeries: [48, 37, 35, 57],
    deviceOptions: {
      chart: {
        width: 380,
        type: "pie",
        toolbar: {
          show: true,
        },
        events: {
          dataPointSelection: (event, chartContext, config) => {
            // this will print mango, apple etc. when clicked on respective datapoint
            var sliceName = config.w.config.labels[config.dataPointIndex];
            this.handleOpen(sliceName);
          },
        },
      },
      colors: ["#c12a37", "#add8e6", "#FFC0CB", "#CBC3E3"],
      labels: ["Server", "Desktop", "Mobile", "IoT"],
      legend: {
        show: true,
        labels: {
          colors: ["#fff"],
          useSeriesColors: true,
        },
      },
      dataLabels: {
        enabled: true,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
            colors: "#fff",
          },
        },
      ],
    },
    alertSeries: [44, 55, 13, 43, 22],
    alertOptions: {
      chart: {
        width: 380,
        type: "pie",
        toolbar: {
          show: true,
        },
        events: {
          dataPointSelection: (event, chartContext, config) => {
            // this will print mango, apple etc. when clicked on respective datapoint
            var sliceName = config.w.config.labels[config.dataPointIndex];
            this.handleOpen(sliceName);
          },
        },
      },
      colors: ["#ff9933", "#FFFF99", "#add8e6", "#CBC3E3", "#c12a37"],
      labels: ["Malware", "System", "Auth", "Admin", "Intrusion"],
      legend: {
        show: true,
        labels: {
          colors: ["#fff"],
          useSeriesColors: true,
        },
      },
      dataLabels: {
        enabled: true,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
            colors: "#fff",
          },
        },
      ],
    },
    impactSeries: [48, 37, 35, 57],
    impactOptions: {
      chart: {
        width: 380,
        type: "pie",
        toolbar: {
          show: true,
        },
        events: {
          dataPointSelection: (event, chartContext, config) => {
            // this will print mango, apple etc. when clicked on respective datapoint
            var sliceName = config.w.config.labels[config.dataPointIndex];
            this.handleOpen(sliceName);
          },
        },
      },
      colors: ["#c12a37", "#ff9933", "#FFFF99", "#228B22"],
      labels: ["Critical", "High", "Medium", "Low"],
      legend: {
        show: true,
        labels: {
          colors: ["#fff"],
          useSeriesColors: true,
        },
      },
      dataLabels: {
        enabled: true,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
            colors: "#fff",
          },
        },
      ],
    },
    hostSeries: [
      {
        name: "Low",
        data: [24, 55, 41, 64, 22],
      },
      {
        name: "Medium",
        data: [53, 32, 33, 52, 13],
      },
      {
        name: "High",
        data: [33, 42, 53, 42, 33],
      },
    ],
    hostOptions: {
      chart: {
        type: "bar",
      },
      colors: ["#228B22", "#ff9933", "#c12a37"],
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: "top",
          },
        },
      },
      legend: {
        show: true,
        labels: {
          colors: ["#fff"],
          useSeriesColors: true,
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "12px",
          colors: ["#fff"],
        },
      },
      stroke: {
        show: true,
        width: 0.9,
        colors: ["#fff"],
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      xaxis: {
        categories: [
          "198.0.0.1",
          "198.0.0.2",
          "198.0.0.3",
          "198.0.0.4",
          "198.0.0.5",
        ],
        labels: {
          style: {
            colors: "#969696",
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            cssClass: "apexcharts-yaxis-label",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#969696",
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            cssClass: "apexcharts-yaxis-label",
          },
        },
      },
    },
    showModal: false,
  };

  handleStartDate = async (date) => {
    await this.setState({
      startDate: moment(date).format("MM/DD/yyyy"),
    });
  };

  handleEndDate = async (date) => {
    await this.setState({
      endDate: moment(date).format("MM/DD/yyyy"),
    });
  };

  handleOpen = (sliceName) => {
    this.setState({ showModal: true });
  };

  handleClose = () => {
    this.setState({ showModal: false });
  };

  render() {
    return (
      <div className="registered-users">
        <div className="mr-l30">
          <MuiPickersUtilsProvider utils={Moment}>
            <DatePicker
              value={this.state.startDate}
              onChange={this.handleStartDate}
              format="MM/DD/yyyy"
              className="datepicker-input "
              inputVariant="outlined"
            />
            <DatePicker
              value={this.state.endDate}
              onChange={this.handleEndDate}
              format="MM/DD/yyyy"
              className="datepicker-input"
              inputVariant="outlined"
            />
          </MuiPickersUtilsProvider>

          <button
            type="button"
            className="red-btn col-md-4 f-width mr-l20 "
            style={{ height: "40px" }}
          >
            <h6>
              Search
              {this.state.loader ? (
                <i className="fa fa-spinner fa-spin"></i>
              ) : null}
            </h6>
          </button>
        </div>

        <div className="row mr-t40">
          <div className="column alerts-box">
            <h6>Critical Severity Alerts</h6>
            <h5 style={{ color: "#c12a37" }}>197</h5>
          </div>
          <div className="column alerts-box">
            <h6>High Severity Alerts</h6>
            <h5 style={{ color: "#ff9933" }}>1057</h5>
          </div>
          <div className="column alerts-box">
            <h6>Medium Severity Alerts</h6>
            <h5 style={{ color: "#FFFF99" }}>2201</h5>
          </div>
          <div className="column alerts-box">
            <h6>Low Severity Alerts</h6>
            <h5 style={{ color: "#228B22" }}>700</h5>
          </div>
        </div>

        <hr className="color-row mr-t30 mr-b30" />

        <div className="row line-chart ">
          <h6 className="ntwrk-labels">Threats Events Timeline</h6>
          <div id="chart" className="col-md-12">
            <ReactApexChart
              options={this.state.options}
              series={this.state.series}
              type="line"
              height={350}
              width={730}
            />
          </div>
        </div>

        <hr className="color-row mr-t30 mr-b30" />

        <div className="row line-chart mr-t20">
          <h6 className="ntwrk-labels">Threats By Device Type</h6>
          <div id="chart" className="mr-l150 mr-t20 col-md-12 ">
            <ReactApexChart
              options={this.state.deviceOptions}
              series={this.state.deviceSeries}
              type="pie"
              width={380}
            />
          </div>
        </div>

        <hr className="color-row mr-t30 mr-b30" />

        <div className="row line-chart mr-t20">
          <h6 className="ntwrk-labels">Threats By Alert Type</h6>
          <div id="chart" className="mr-l150 mr-t20 col-md-12 ">
            <ReactApexChart
              options={this.state.alertOptions}
              series={this.state.alertSeries}
              type="pie"
              width={380}
            />
          </div>
        </div>

        <hr className="color-row mr-t30 mr-b30" />

        <div className="row line-chart mr-t20">
          <h6 className="ntwrk-labels">Threats By Impact</h6>
          <div id="chart" className="mr-l150 mr-t20 col-md-12 ">
            <ReactApexChart
              options={this.state.impactOptions}
              series={this.state.impactSeries}
              type="pie"
              width={380}
            />
          </div>
        </div>

        <hr className="color-row mr-t30 mr-b30" />

        <div className="row line-chart mr-t20">
          <h6 className="ntwrk-labels">Top Threats By Host</h6>
          <div id="chart" className="mr-t20 col-md-12">
            <ReactApexChart
              options={this.state.hostOptions}
              series={this.state.hostSeries}
              type="bar"
              height={350}
            />
          </div>
        </div>

        {this.state.showModal ? (
          <Modal
            open={this.state.showModal}
            aria-labelledby="sliceModal"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-lg modal-dialog-centered"
              role="document"
            >
              <div className="modal-content modal_fp_content">
                <div className="modal-header forget_pw_header">
                  <button
                    type="button"
                    className="close modal_fp_close_btn"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.handleClose}
                  >
                    <span aria-hidden="true">
                      <svg
                        width="10"
                        height="18"
                        viewBox="0 0 10 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2.91937 9.00008L9.82886 1.70718L8.48899 0.292969L0.23964 9.00008L8.48899 17.7072L9.82886 16.293L2.91937 9.00008Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </button>
                  <h5
                    className="modal-title modal_fp_title "
                    id="exampleModalLabel"
                  >
                    Threat Alerts
                  </h5>
                </div>

                <div className="modal-body fp_modal_body network-modal-body scroll mr-b10">
                  <div className="mr-t10 row netwrk-slice">
                    <h6 className="nwtrk-title pd10 mr-l5">192.0.0.23</h6>

                    <div className="row table-headings mr-l5">
                      <div className="col-auto">
                        Alert Type
                        <br />
                        <span className="table-data">Intrusion</span>
                      </div>
                      <div className="col-auto">
                        Threat Impact <br />
                        <span className="table-data">Intrusion</span>
                      </div>
                      <div className="col-auto">
                        Threat Action <br />
                        <span className="table-data">N/A</span>
                      </div>
                      <div className="col-auto">
                        Device Type <br />
                        <span className="table-data">IoT</span>
                      </div>
                      <div className="col-auto">
                        Date Time <br />
                        <span className="table-data">2020/04/30</span>
                      </div>
                      <div className="col-auto">
                        Organization <br />
                        <span className="table-data">Company</span>
                      </div>
                      <div className="col-auto">
                        Device Name <br />
                        <span className="table-data">Drisk 1</span>
                      </div>
                    </div>
                  </div>
                  <div className="mr-t10 row netwrk-slice">
                    <h6 className="nwtrk-title pd10 mr-l5">192.0.0.23</h6>

                    <div className="row table-headings mr-l5">
                      <div className="col-auto">
                        Alert Type
                        <br />
                        <span className="table-data">Intrusion</span>
                      </div>
                      <div className="col-auto">
                        Threat Impact <br />
                        <span className="table-data">Intrusion</span>
                      </div>
                      <div className="col-auto">
                        Threat Action <br />
                        <span className="table-data">N/A</span>
                      </div>
                      <div className="col-auto">
                        Device Type <br />
                        <span className="table-data">IoT</span>
                      </div>
                      <div className="col-auto">
                        Date Time <br />
                        <span className="table-data">2020/04/30</span>
                      </div>
                      <div className="col-auto">
                        Organization <br />
                        <span className="table-data">Company</span>
                      </div>
                      <div className="col-auto">
                        Device Name <br />
                        <span className="table-data">Drisk 1</span>
                      </div>
                    </div>
                  </div>
                  <div className="mr-t10 row netwrk-slice">
                    <h6 className="nwtrk-title pd10 mr-l5">192.0.0.23</h6>

                    <div className="row table-headings mr-l5">
                      <div className="col-auto">
                        Alert Type
                        <br />
                        <span className="table-data">Intrusion</span>
                      </div>
                      <div className="col-auto">
                        Threat Impact <br />
                        <span className="table-data">Intrusion</span>
                      </div>
                      <div className="col-auto">
                        Threat Action <br />
                        <span className="table-data">N/A</span>
                      </div>
                      <div className="col-auto">
                        Device Type <br />
                        <span className="table-data">IoT</span>
                      </div>
                      <div className="col-auto">
                        Date Time <br />
                        <span className="table-data">2020/04/30</span>
                      </div>
                      <div className="col-auto">
                        Organization <br />
                        <span className="table-data">Company</span>
                      </div>
                      <div className="col-auto">
                        Device Name <br />
                        <span className="table-data">Drisk 1</span>
                      </div>
                    </div>
                  </div>
                  <div className="mr-t10 row netwrk-slice">
                    <h6 className="nwtrk-title pd10 mr-l5">192.0.0.23</h6>

                    <div className="row table-headings mr-l5">
                      <div className="col-auto">
                        Alert Type
                        <br />
                        <span className="table-data">Intrusion</span>
                      </div>
                      <div className="col-auto">
                        Threat Impact <br />
                        <span className="table-data">Intrusion</span>
                      </div>
                      <div className="col-auto">
                        Threat Action <br />
                        <span className="table-data">N/A</span>
                      </div>
                      <div className="col-auto">
                        Device Type <br />
                        <span className="table-data">IoT</span>
                      </div>
                      <div className="col-auto">
                        Date Time <br />
                        <span className="table-data">2020/04/30</span>
                      </div>
                      <div className="col-auto">
                        Organization <br />
                        <span className="table-data">Company</span>
                      </div>
                      <div className="col-auto">
                        Device Name <br />
                        <span className="table-data">Drisk 1</span>
                      </div>
                    </div>
                  </div>
                  <div className="mr-t10 row netwrk-slice">
                    <h6 className="nwtrk-title pd10 mr-l5">192.0.0.23</h6>

                    <div className="row table-headings mr-l5">
                      <div className="col-auto">
                        Alert Type
                        <br />
                        <span className="table-data">Intrusion</span>
                      </div>
                      <div className="col-auto">
                        Threat Impact <br />
                        <span className="table-data">Intrusion</span>
                      </div>
                      <div className="col-auto">
                        Threat Action <br />
                        <span className="table-data">N/A</span>
                      </div>
                      <div className="col-auto">
                        Device Type <br />
                        <span className="table-data">IoT</span>
                      </div>
                      <div className="col-auto">
                        Date Time <br />
                        <span className="table-data">2020/04/30</span>
                      </div>
                      <div className="col-auto">
                        Organization <br />
                        <span className="table-data">Company</span>
                      </div>
                      <div className="col-auto">
                        Device Name <br />
                        <span className="table-data">Drisk 1</span>
                      </div>
                    </div>
                  </div>
                  <div className="mr-t10 row netwrk-slice">
                    <h6 className="nwtrk-title pd10 mr-l5">192.0.0.23</h6>

                    <div className="row table-headings mr-l5">
                      <div className="col-auto">
                        Alert Type
                        <br />
                        <span className="table-data">Intrusion</span>
                      </div>
                      <div className="col-auto">
                        Threat Impact <br />
                        <span className="table-data">Intrusion</span>
                      </div>
                      <div className="col-auto">
                        Threat Action <br />
                        <span className="table-data">N/A</span>
                      </div>
                      <div className="col-auto">
                        Device Type <br />
                        <span className="table-data">IoT</span>
                      </div>
                      <div className="col-auto">
                        Date Time <br />
                        <span className="table-data">2020/04/30</span>
                      </div>
                      <div className="col-auto">
                        Organization <br />
                        <span className="table-data">Company</span>
                      </div>
                      <div className="col-auto">
                        Device Name <br />
                        <span className="table-data">Drisk 1</span>
                      </div>
                    </div>
                  </div>
                  <div className="mr-t10 row netwrk-slice">
                    <h6 className="nwtrk-title pd10 mr-l5">192.0.0.23</h6>

                    <div className="row table-headings mr-l5">
                      <div className="col-auto">
                        Alert Type
                        <br />
                        <span className="table-data">Intrusion</span>
                      </div>
                      <div className="col-auto">
                        Threat Impact <br />
                        <span className="table-data">Intrusion</span>
                      </div>
                      <div className="col-auto">
                        Threat Action <br />
                        <span className="table-data">N/A</span>
                      </div>
                      <div className="col-auto">
                        Device Type <br />
                        <span className="table-data">IoT</span>
                      </div>
                      <div className="col-auto">
                        Date Time <br />
                        <span className="table-data">2020/04/30</span>
                      </div>
                      <div className="col-auto">
                        Organization <br />
                        <span className="table-data">Company</span>
                      </div>
                      <div className="col-auto">
                        Device Name <br />
                        <span className="table-data">Drisk 1</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default NetworkSecurity;
