import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class BackButton extends Component {
  prevPath(path) {
    this.props.history.push(path);
  }

  render() {
    return (
      <button
        type="button"
        className="back-btn-about-us"
        onClick={() => (this.props.count === 0 ? this.prevPath(this.props.getPath) : this.props.redirectBack())}
      >
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3 9.62109H20" stroke="white" strokeWidth="2" />
          <path d="M11.3103 1L2 10.3103L11.3103 19.6207" stroke="white" strokeWidth="2" />
        </svg>
        
      </button>
    );
  }
}

export default withRouter(BackButton);
