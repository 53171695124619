import { Avatar, IconButton, Input, InputAdornment } from "@material-ui/core";
import React from "react";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import SendIcon from "@material-ui/icons/Send";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import { io } from "socket.io-client";
import { useEffect } from "react";
import { useRef } from "react";
import docx from "../../../assets/images/docs.svg";
import pdf from "../../../assets/images/pdf.png";
import folder from "../../../assets/images/folder.png";
import cotMethods from "../../../services/cotApi";
const socket = io.connect(`${process.env.REACT_APP_NODE_APP_URL}`);
export const Chat = () => {
  const history = useHistory();
  const { id, name } = useParams();
  const [message, setMessage] = useState([]);
  const [chatMessage, setChatMessage] = useState("");
  const [user, setUser] = useState(
    JSON.parse(
      sessionStorage.getItem("UserDetails")
        ? sessionStorage.getItem("UserDetails")
        : ""
    )
  );
  const handleRef = useRef(null);
  const scrollRef = useRef(null);
  const handleUpload = (event) => {
    const formData = new FormData();
    formData.append("files", event.target.files[0]);
    formData.append("group_id", id);
    formData.append("tinode_username", user.tinode_username);
    cotMethods
      .uploadChatMedia(formData)
      .then((result) => {})
      .catch((error) => {
        console.log(error);
      });
  };
  const onSubmit = () => {
    console.log("submited");
    if (chatMessage.length) {
      setChatMessage("");
      socket.emit("chat message", {
        msg: chatMessage,
        room: id,
        sender: user.tinode_username,
      });
    }
    scrollToBottom();
  };

  const joinRoom = () => {
    socket.emit("join", { username: user.tinode_username, room: id });
  };
  useEffect(() => {
    joinRoom();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [message]);

  useEffect(() => {
    socket.on("load chat", (data) => {
      setMessage(data);
    });

    // Remove event listener on component unmount
    return () => socket.off("load chat");
  }, [socket]);
  socket.on("chat message", (data) => {
    const tempchat = [...message];
    tempchat.push(data);
    setMessage(tempchat);
    console.log("chat message", data, tempchat);
  });
  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ smooth: true });
      scrollRef.current.scrollTo(0, scrollRef?.current?.scrollHeight);
      // scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };
  console.log(message);
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // Perform your action here
      onSubmit();
    }
  };
  return (
    <div className="routing-main-wrap">
      <div className="page-head text-center">
        <div className="d-flex align-item-center">
          <div className="mt-2 mr-2 ">
            <button
              type="button"
              className="back-btn-about-us"
              onClick={() => history.push("/dashboard/trusted")}
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M3 9.62109H20" stroke="white" strokeWidth="2" />
                <path
                  d="M11.3103 1L2 10.3103L11.3103 19.6207"
                  stroke="white"
                  strokeWidth="2"
                />
              </svg>
              
            </button>
          </div>
          <div>
            <Avatar alt={name}>{name ? name.charAt(0) : ""}</Avatar>
          </div>
          <div className="mt-2 ml-3">
            <h3 className="mr-3">{name}</h3>
          </div>
        </div>
      </div>
      {true ? (
        <>
          <div className="rout-body">
            <div className="row">
              <div className="col-sm-12">
                <div className="device-details h-80">
                  <div className="row chat" ref={scrollRef}>
                    {message.map((data, index) =>
                      data.sender !== user.tinode_username ? (
                        <div className="col-12 mt-2  p-0" key={index}>
                          <div className="col-8 p-0">
                            <div className="message-card">
                              <div>
                                <span>{data?.sender}</span>
                              </div>
                              <div>
                                {typeof data.msg == "string" ? (
                                  <h6>{data.msg}</h6>
                                ) : !data.msg?.mimetype?.search("image/") ? (
                                  <img src={data.msg.url} alt="" className="" />
                                ) : (
                                  <div className="mt-1 p-1 d-flex">
                                    <div className="mr-1">
                                      {data.msg?.mimetype?.search("pdf") !==
                                      -1 ? (
                                        <img
                                          alt=""
                                          src={pdf}
                                          className="chat-icon"
                                          onClick={() =>
                                            window.open(data.msg.url)
                                          }
                                        />
                                      ) : data?.msg.mimetype?.search(
                                          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        ) !== 1 ? (
                                        <img
                                          alt=""
                                          src={docx}
                                          className="chat-icon-docx"
                                          onClick={() =>
                                            window.open(data.msg.url)
                                          }
                                        />
                                      ) : (
                                        <img
                                          alt=""
                                          src={folder}
                                          className="chat-icon-folder"
                                          onClick={() =>
                                            window.open(data.msg.url)
                                          }
                                        />
                                      )}
                                      {/* </IconButton> */}
                                    </div>
                                    <div className="mt-1 mr-1">
                                      <h6
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "900",
                                        }}
                                      >
                                        {data.msg.originalFileName}
                                      </h6>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="col-12 mt-2 row justify-content-end p-0">
                          <div className="col-8 p-0 row justify-content-end mr-1">
                            <div className="message-card">
                              <div>
                                <span>{data?.sender}</span>
                              </div>
                              <div>
                                {" "}
                                {typeof data.msg == "string" ? (
                                  <h6>{data.msg}</h6>
                                ) : !data.msg?.mimetype?.search("image/") ? (
                                  <img src={data.msg.url} alt="" className="" />
                                ) : (
                                  <div className="mt-1 p-1 d-flex">
                                    <div className="mr-1">
                                      {data.msg?.mimetype?.search("pdf") !==
                                      -1 ? (
                                        <img
                                          alt=""
                                          src={pdf}
                                          className="chat-icon"
                                          onClick={() =>
                                            window.open(data.msg.url)
                                          }
                                        />
                                      ) : data?.msg.mimetype?.search(
                                          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        ) !== 1 ? (
                                        <img
                                          alt=""
                                          src={docx}
                                          className="chat-icon-docx"
                                          onClick={() =>
                                            window.open(data.msg.url)
                                          }
                                        />
                                      ) : (
                                        <img
                                          alt=""
                                          src={folder}
                                          className="chat-icon-folder"
                                          onClick={() =>
                                            window.open(data.msg.url)
                                          }
                                        />
                                      )}
                                      {/* </IconButton> */}
                                    </div>
                                    <div className="mt-1 mr-1">
                                      <h6
                                        style={{
                                          fontSize: "16px",
                                          fontWeight: "900",
                                        }}
                                      >
                                        {data.msg.originalFileName}
                                      </h6>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>

                  <div className="bottom d-flex">
                    <Input
                      placeholder="Type message here...."
                      onChange={(event) => {
                        setChatMessage(event.target.value);
                      }}
                      value={chatMessage}
                      fullWidth
                      type="outlined"
                      label="Message"
                      onKeyPress={handleKeyPress}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            // aria-label="toggle password visibility"
                            onClick={() => handleRef.current.click()}
                            // onMouseDown={handleMouseDownPassword}
                          >
                            <AttachFileIcon color="primary" />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <div>
                      <IconButton className="send-btn" onClick={onSubmit}>
                        <SendIcon />
                      </IconButton>
                    </div>
                  </div>
                  <input
                    type={"file"}
                    ref={handleRef}
                    onChange={handleUpload}
                    style={{ display: "none" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            paddingTop: "20px",
            display: "flex",
            justifyContent: "center",
            fontSize: "20px",
            color: "#BC2835",
          }}
        >
          No device connected
        </div>
      )}
    </div>
  );
};
