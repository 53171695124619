import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_NODE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(function (config) {
  const token = sessionStorage.getItem("UserDetails")
    ? JSON.parse(sessionStorage.getItem("UserDetails")).token
    : "";
  config.headers["Auth"] = token;
  return config;
});

export default api;
