import api from "./api";

function getCustomerDetails() {
  return new Promise((resolve, reject) => {
    api.get("/user/getCustomerDetails").then((response) => {
      resolve(response);
    });
  });
}

function getCustomerUserDetails(filter) {
  return new Promise((resolve, reject) => {
    api.get(`/user/getCustomerUserDetails?q=${filter}`).then((response) => {
      resolve(response);
    });
  });
}

function createCustomer(createCustProfile) {
  return new Promise((resolve, reject) => {
    api.post("/user/createCustomer", createCustProfile).then((response) => {
      resolve(response);
    });
  });
}

function saveCustomerDetails(editedCustomerObject) {
  return new Promise((resolve, reject) => {
    api.post("/user/saveCustomerDetails", editedCustomerObject).then((response) => {
      resolve(response);
    });
  });
}

function editCustomerProfile(customerData) {
  return new Promise((resolve, reject) => {
    api.post("/user/editCustomerProfile", customerData).then((response) => {
      resolve(response);
    });
  });
}

function deleteCustomer(deleteId) {
  return new Promise((resolve, reject) => {
    api.post("/user/deleteCustomer", deleteId).then((response) => {
      resolve(response);
    });
  });
}

function getCustomerDeviceDetails(filter, customerData) {
  return new Promise((resolve, reject) => {
    api.get(`/user/getCustomerDeviceDetails?q=${filter}`, customerData).then((response) => {
      resolve(response);
    });
  });
}

function updateCustomerDeviceDetails(updateData) {
  return new Promise((resolve, reject) => {
    api.post("/user/updateCustomerDeviceDetails", updateData).then((response) => {
      resolve(response);
    });
  });
}

function getCustomerNewDevice() {
  return new Promise((resolve, reject) => {
    api.get("/user/getCustomerDevicesList").then((response) => {
      resolve(response);
    });
  });
}

function addCustomerDeviceDetails(updateData) {
  return new Promise((resolve, reject) => {
    api.post("/user/insertCustomerDevices", updateData).then((response) => {
      resolve(response);
    });
  });
}

function addNewCustomer(data) {
  return new Promise((resolve, reject) => {
    api.post("/user/createUserProfile", data).then((response) => {
      resolve(response);
    });
  });
}

function editCustomer(data) {
  return new Promise((resolve, reject) => {
    api.post("/user/editUserProfile", data).then((response) => {
      resolve(response);
    });
  });
}

function getUserDevice(data) {
  console.log(data);
  return new Promise((resolve, reject) => {
    api.post("user/getUserProfileDeviceList", data).then((response) => {
      resolve(response);
    });
  });
}

function editUserDevice(data) {
  return new Promise((resolve, reject) => {
    api.post("user/updateUserProfileDeviceDetails", data).then((response) => {
      resolve(response);
    });
  });
}

function insertUserDevice(data) {
  return new Promise((resolve, reject) => {
    api.post("user/insertUserProfileDeviceDetails", data).then((response) => {
      resolve(response);
    });
  });
}

function updateUserProfile(data) {
  return new Promise((resolve, reject) => {
    api.post("user/updateUserProfile", data).then((response) => {
      resolve(response);
    });
  });
}

function addUserProfileDevices(data) {
  return new Promise((resolve, reject) => {
    api.post("user/addUserProfileDevices", data).then((response) => {
      resolve(response);
    });
  });
}

function deleteCustomerDevices(data) {
  return new Promise((resolve, reject) => {
    api.post("user/deleteCustomerDevice", data).then((response) => {
      resolve(response);
    });
  });
}

function deleteUserDevices(data) {
  return new Promise((resolve, reject) => {
    api.post("user/deleteUserDevice", data).then((response) => {
      resolve(response);
    });
  });
}

function deleteUser(data) {
  return new Promise((resolve, reject) => {
    api.post("user/deleteCustomerUser", data).then((response) => {
      resolve(response);
    });
  });
}

const adminMethods = {
  getCustomerDetails,
  getCustomerUserDetails,
  createCustomer,
  saveCustomerDetails,
  editCustomerProfile,
  deleteCustomer,
  getCustomerDeviceDetails,
  updateCustomerDeviceDetails,
  getCustomerNewDevice,
  addCustomerDeviceDetails,
  addNewCustomer,
  editCustomer,
  getUserDevice,
  editUserDevice,
  insertUserDevice,
  updateUserProfile,
  addUserProfileDevices,
  deleteCustomerDevices,
  deleteUserDevices,
  deleteUser,
};

export default adminMethods;
