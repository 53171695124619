import React, { Component } from "react";
import mqtt from "mqtt";

class test extends Component {
  state = {
    selectedDevice: this.props.selectedDevice.device_id,
  };
  run = () => {
    var mosquitto = mqtt.connect(`${process.env.REACT_APP_NODE_MQTT_URL}`, {
      port: 8083,
      username: "mqttdonald",
      password: "mqttdonald",
      clean: true,
      retain: false,
    });
    var device_id = this.state.selectedDevice;
    mosquitto.on("connect", function () {
      var successTopic = device_id + "/aws/drisk";

      mosquitto.subscribe(successTopic, { retain: false, clean: true, qos: 2 });
    });
    mosquitto.on("close", function () {});
    mosquitto.on("reconnect", function () {});
    mosquitto.on("offline", function () {});
    mosquitto.on("error", function (error) {});
    mosquitto.on("message", function (topic, sub_message) {
      var payload = sub_message.toString();
    });
  };
}
export default test;
