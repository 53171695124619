import React, { Component } from "react";
import ToggleSideNav from "../UI/ToggleSideNav/ToggleSideNav";
import cotMethods from "../../services/cotApi";
import { withRouter } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import deviceIcon from "../../assets/images/deviceIcon.svg";
import userMethods from "../../services/userApi";
import Modal from "@material-ui/core/Modal";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {
  Table,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  TextField,
  Button,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import CloseIcon from "@material-ui/icons/Close";
import { toast } from "react-toastify";
// import { DeleteOutlineIcon } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CustomToolTip from "../Common/CustomToolTip";
import { blockedDevices, cotInfo } from "../VulnerabilityScan/messages";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Trusted extends Component {
  state = {
    groupDetails: [],
    deleteModal: false,
    isCOTDisable: true,
    showGroups: true,
    device_id: JSON.parse(window.sessionStorage.getItem("ConnectedDevice"))
      ?.device_id,
    noData: false,
    connectedDevices: [],
    showLogout: false,
    open: false,
    message: "",
    modalOpen: false,
    userDetails: [],
    selectedUsers: [],
    groupName: "",
    nextButton: false,
    addMemberButton: false,
    manageModalOpen: false,
    loading: false,
    groupMember: [],
    payload: {},
    group_id: "",
  };

  componentDidMount() {
    this.fetchCOTGroupDetails();
    this.fetchAllDriskDevices();
    this.fetchUsersGroupDetails();
  }

  fetchAllDriskDevices = async () => {
    // console.log("refresh");
    const response = await userMethods.getAllDriskDevices();
    var connectedArray = [];

    if (response.data.statusCode === 200) {
      var result = response.data.result.device_details;
      result.forEach((element) => {
        if (element.is_connected === "connected") {
          connectedArray.push(element);
        }
      });
      {
        connectedArray[0] != null
          ? this.setState({
              connectedDevices: connectedArray[0].name,
            })
          : this.setState({
              connectedDevices: "",
            });
      }
      // console.log(this.state.connectedDevices);
    }
    if (
      response.data.statusCode === 401 &&
      response.data.message == "Session expires. Please login again"
    ) {
      this.setState({
        showLogout: true,
      });
    }
  };
  handleClose = () => {
    this.setState({ modalOpen: false });
    this.setState({ selectedUsers: [] });
    this.setState({ nextButton: false });
    this.setState({ search: "" });
    this.setState({ groupName: "" });
    this.handleSearch("");
  };
  fetchMemberGroup = async (group_id) => {
    const payload = {
      device_id: this.state.device_id,
      group_id: group_id,
    };

    this.setState({ manageModalOpen: true, group_id: group_id });

    const response = await cotMethods.getGroupMember(payload);
    if (response.data.statusCode == 200)
      this.setState({ groupMember: response.data.result.memberDetails });
    else this.setState({ groupMember: [] });

    if (
      response.data.statusCode === 401 &&
      response.data.message == "Session expires. Please login again"
    ) {
      this.setState({
        showLogout: true,
      });
    }
  };
  logoutUser = () => {
    window.sessionStorage.clear();
    this.props.history.replace({
      pathname: "/",
    });
  };

  fetchCOTGroupDetails = async () => {
    var deviceDetails = {
      device_id: this.state.device_id,
    };

    const response = await cotMethods.getGroupDetails(deviceDetails);

    if (response.data.message == "Device is Inactive") {
      this.setState({
        groupDetails: 0,
      });
    }

    if (response.data.statusCode === 200) {
      this.setState({
        groupDetails: response.data.result.groupList,
        isCOTDisable: response.data.result.isCOTDisable,
      });
    }

    if (
      response?.data?.result?.length === 0 ||
      response?.data?.result?.length === undefined
    ) {
      this.setState({ noData: true });
    }
    if (
      response.data.statusCode === 401 &&
      response.data.message == "Session expires. Please login again"
    ) {
      this.setState({
        showLogout: true,
      });
    }
    if (
      response.data.statusCode === 401 &&
      response.data.message != "Session expires. Please login again"
    ) {
      this.setState({
        open: true,
        message: response.data.message,
        loader: false,
      });
    }
  };
  fetchUsersGroupDetails = async () => {
    var deviceDetails = {
      value: "",
      group_id: "",
    };
    const response = await cotMethods.getUsers(deviceDetails);

    if (response.data.message == "Device is Inactive") {
      this.setState({
        groupDetails: 0,
      });
    }

    if (response.data.statusCode === 200) {
      this.setState({
        userDetails: response.data.result,
      });
    }
  };

  handleChange = async (event) => {
    await this.setState({
      isCOTDisable: event.target.checked,
    });

    var updateObject = {
      isCOTDisable: this.state.isCOTDisable ? 1 : 0,
      device_id: this.state.device_id,
    };

    const response = await cotMethods.updateCOTStatus(updateObject);

    if (response.data.statusCode === 200) {
      if (response.data.result.isCOTDisable === 0) {
        this.setState({
          showGroups: false,
        });
      } else {
        this.setState({
          showGroups: true,
          groupDetails: response.data.result.groupList,
        });
      }
    }

    if (
      response.data.result.length === 0 ||
      response.data.result.length === undefined
    ) {
      this.setState({ noData: true });
    }
  };

  redirectToDataSharing = (group) => {
    sessionStorage.setItem("Group", JSON.stringify(group));
    this.props.history.push({
      pathname: "/dashboard/dataSharing",
    });
  };

  handleCheckBox = (data, event) => {
    if (event.target.checked) {
      const tempArray = this.state.selectedUsers;
      tempArray.push(data);
      this.setState({ selectedUsers: tempArray });
    } else {
      const tempArray = this.state.selectedUsers.filter((record) => {
        return record.email_id != data.email_id;
      });

      this.setState({ selectedUsers: tempArray });
    }
  };

  addGroupName = (name) => {
    if (name.trim().length) {
      // console.log(name);
      this.setState({ groupNameError: "" });
      //  this.setState({nextButton:false})
      this.setState({ groupName: name });
    } else {
      this.setState({ groupName: name });
      this.setState({ groupNameError: "Invalid group name" });
    }
  };

  addGroupApi = async () => {
    const tempData = JSON.parse(sessionStorage.getItem("ConnectedDevice"));

    if (this.state.groupName.trim().length) {
      const payload = {
        group_name: this.state.groupName,
        device_id: tempData.device_id,
        topic_id: "grprgYUKAt_P3A",
        data: this.state.selectedUsers,
      };
      const response = await cotMethods.addGroup(payload);
      // console.log(response);
      if (response.data.statusCode === 200) {
        toast.success(response.data.message);
        this.setState({ modalOpen: false });
        await this.fetchCOTGroupDetails();
        this.handleClose();
      } else {
        toast.error(response.data.message);
      }
      this.setState({ groupName: "" });
      this.setState({ selectedUsers: [] });
      this.setState({ nextButton: false });
    } else {
      this.setState({ groupNameError: "Invalid group name" });
    }
  };
  addMemberToCot = async () => {
    // const tempData = JSON.parse(sessionStorage.getItem("ConnectedDevice"));

    const payload = {
      group_id: this.state.group_id,
      device_id: this.state.device_id,
      data: this.state.selectedUsers,
    };
    const response = await cotMethods.addMemberToCot(payload);
    // console.log(response);
    if (response.data.statusCode === 200) {
      toast.success(response.data.message);
      this.setState({ modalOpen: false });
      await this.fetchCOTGroupDetails();
    } else {
      toast.error(response.data.message);
    }
    this.setState({
      groupName: "",
      selectedUsers: [],
      addMemberButton: false,
      modalOpen: false,
      manageModalOpen: false,
    });
  };

  removeMemberFromCot = async () => {
    this.setState({ deleteModal: false });
    const response = await cotMethods.removeMemberFromCot(this.state.payload);
    // console.log(response);
    if (response.data.statusCode === 200) {
      toast.success(response.data.message);
      this.setState({ modalOpen: false });
      await this.fetchMemberGroup(this.state.payload.group_id);
      await this.fetchCOTGroupDetails();
    } else {
      toast.error(response.data.message);
    }
    this.setState({ payload: {} });
  };
  deleteCOT = async () => {
    this.setState({ deleteGroupModal: false });
    const response = await cotMethods.deleteCotApi(this.state.payload);
    // console.log(response);
    if (response.data.statusCode === 200) {
      toast.success(response.data.message);
      // this.setState({ modalOpen: false });
      // await this.fetchMemberGroup(this.state.payload.group_id);
      await this.fetchCOTGroupDetails();
    } else {
      toast.error(response.data.message);
    }
    this.setState({ payload: {} });
  };
  handleSearch = async (search) => {
    var deviceDetails = {
      value: search,
      group_id: "",
    };
    const response = await cotMethods.getUsers(deviceDetails);

    if (response.data.message == "Device is Inactive") {
      this.setState({
        groupDetails: 0,
      });
    }

    if (response.data.statusCode === 200) {
      this.setState({
        userDetails: response.data.result,
      });
    }
  };

  confirmDelete = async (data) => {
    const payload = {
      ...data,
      cot_ids: [data.cot_id],
    };
    this.setState({ payload: payload });
    this.setState({ deleteModal: true });
  };

  confirmDeleteCot = async (data) => {
    // console.log(data);
    const payload = {
      device_id: this.state.device_id,
      group_id: [data.group_id],
    };
    this.setState({ payload: payload });
    this.setState({ deleteGroupModal: true });
  };

  render() {
    console.log(this.state.device_id);
    return (
      <div className="routing-main-wrap">
        <div className="page-head">
          <Snackbar
            open={this.state.open}
            autoHideDuration={3000}
            onClose={() => this.setState({ open: false })}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Alert
              // onClose={handleClose}
              severity="info"
              sx={{ width: "100%" }}
            >
              {this.state.message}
            </Alert>
          </Snackbar>
          <h3>Trusted</h3>
          {this.state.connectedDevices != null ? (
            <h3>{this.state.connectedDevices}</h3>
          ) : null}
          <ToggleSideNav batteryRefresh="false" />
        </div>
        {this.state?.device_id != undefined ? (
          <>
            <div className="rout-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="device-details">
                    <div className="row flex-row-reverse mr-2 h-10"></div>
                    <div className="black-card settings-card mr-b20">
                      <div className="b-card-link">
                        <a style={{ color: "#C12A37" }}>
                          <div className="d-flex">
                            Circle of Trust (CoT){" "}
                            <CustomToolTip
                              message={cotInfo}
                              className=" ml-1"
                            />
                          </div>
                          <div className="cus_trial_toggle ">
                            <label className="switch switch-trusted">
                              <input
                                type="checkbox"
                                id="togBtn"
                                defaultValue="is_cot_disable"
                                checked={this.state.isCOTDisable}
                                onChange={this.handleChange}
                              />
                              <div className="slider round">
                                <span className="on">ON</span>
                                <span className="off">OFF</span>
                              </div>
                            </label>
                          </div>
                        </a>
                      </div>
                    </div>

                    {this.state.showGroups ? (
                      <div className="black-card settings-card mr-b10">
                        <div className="row">
                          <div className="d-d-card-head mr-b20 col-md-6">
                            <h4>CoT Groups</h4>
                          </div>
                          <div className="d-d-card-head mr-b20 col-md-2">
                            <h4>No. of users</h4>
                          </div>
                          <div className="d-d-card-head mr-b20 col-md-2 ">
                            <h4>Devices</h4>
                          </div>
                          <div className="d-d-card-head mr-b20 col-md-2 ">
                            <h4>Action</h4>
                          </div>
                        </div>

                        {this.state.groupDetails?.length == 0 ? (
                          // this.state.noData ? (
                          <div className="text-center registered-title mr-b20 mr-t20">
                            <h6 style={{ color: "#fff", "font-size": "12px" }}>
                              No Groups.
                            </h6>
                          </div>
                        ) : (
                          // ) : (
                          //   <CircularProgress color="secondary" style={{ margin: "10% 0% 10% 50%" }} />
                          // )
                          this.state.groupDetails &&
                          this.state.groupDetails.map((group, index) => {
                            return (
                              <div key={index} className="device-info-wrap ">
                                <div className="row">
                                  <div className="col-5 col-md-6">
                                    <div className="d-info-card">
                                      <h4
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          this.props.history.push(
                                            `/dashboard/chat/${group.group_id}/${group.group_name}`
                                          )
                                        }
                                      >
                                        {group.group_name}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className="col-2 col-md-2 pd-l50">
                                    <div className="d-info-card">
                                      <label>
                                        <h4 style={{ color: "#6BC4E2" }}>
                                          {group.no_member}
                                        </h4>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-2 col-md-2">
                                    <div className="d-info-card">
                                      <label
                                        onClick={() =>
                                          this.redirectToDataSharing(group)
                                        }
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      >
                                        <img
                                          src={deviceIcon}
                                          alt="Device Icon"
                                          height="25"
                                        />
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-2 col-md-2 ">
                                    <div className="d-info-card">
                                      <VisibilityIcon
                                        className={"m-icon"}
                                        onClick={() =>
                                          this.fetchMemberGroup(group.group_id)
                                        }
                                      />
                                      {/* <IconButton> */}
                                      {group?.showDelete ? (
                                        <DeleteIcon
                                          className={"m-icon ml-2"}
                                          onClick={() =>
                                            this.confirmDeleteCot(group)
                                          }
                                        />
                                      ) : null}
                                      {/* </IconButton> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        )}
                      </div>
                    ) : null}

                    <div
                      className="black-card settings-card"
                      onClick={() => this.setState({ modalOpen: true })}
                    >
                      <div className="b-card-link b-card-link-settings">
                        <a>
                          <div className="d-d-card-head">
                            <h4> Create group</h4>
                          </div>
                          <svg
                            width="23"
                            height="24"
                            viewBox="0 0 23 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M13.8189 12L6.90942 4.70711L8.24929 3.29289L16.4986 12L8.24929 20.7071L6.90942 19.2929L13.8189 12Z"
                              fill="white"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>

                    {/* <div className="black-card settings-card mr-b20"> 
                  <div className="b-card-link">
                    <a style={{ color: "#C12A37" }}>
                      Create group
                      <svg
                        width="23"
                        height="24"
                        viewBox="0 0 23 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M13.8189 12L6.90942 4.70711L8.24929 3.29289L16.4986 12L8.24929 20.7071L6.90942 19.2929L13.8189 12Z"
                          fill="white"
                        />
                      </svg>
                    </a>
                  </div>
                </div> */}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div
            style={{
              paddingTop: "20px",
              display: "flex",
              justifyContent: "center",
              fontSize: "20px",
              color: "#BC2835",
            }}
          >
            No device connected
          </div>
        )}
        <Modal
          open={this.state.manageModalOpen}
          aria-labelledby="DeleteUserProfile"
          aria-hidden="true"
        >
          {!this.state.addMemberButton ? (
            <div
              className="modal-dialog  modal_dialog_forgot_pw"
              role="document"
            >
              <div className="modal-content modal_fp_content">
                <div className="modal-header forget_pw_header">
                  <h5
                    className="modal-title modal_fp_title "
                    id="exampleModalLabel"
                  >
                    Members
                  </h5>
                  <IconButton
                    onClick={() => this.setState({ manageModalOpen: false })}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
                <div className="modal-body fp_modal_body ">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body  mr-b10">
                    {/* <TextField
                      className="input-100"
                      label={"Search"}
                      onChange={(event) =>
                        this.handleSearch(event.target.value)
                      }
                    /> */}
                    {this.state.groupMember?.length ? (
                      <TableContainer className="modal-table mt-2">
                        <Table aria-label="simple table">
                          {this.state.groupMember?.map((data, index) => (
                            <TableRow key={index}>
                              <TableCell colSpan={12} className="member">
                                <div className="spinner-place">
                                  <span> {data?.full_name} </span>
                                </div>
                              </TableCell>
                              <TableCell className="p-3">
                                {data.showDelete ? (
                                  <DeleteIcon
                                    className="icon-red"
                                    onClick={() => {
                                      this.confirmDelete(data);

                                      // this.setState({ deleteModal: true });
                                    }}
                                  />
                                ) : null}
                                {/* <Checkbox
                                  onChange={(event) =>
                                    this.handleCheckBox(data, event)
                                  }
                                /> */}
                              </TableCell>
                            </TableRow>
                          ))}
                        </Table>
                      </TableContainer>
                    ) : (
                      <div
                        style={{
                          paddingTop: "20px",
                          display: "flex",
                          justifyContent: "center",
                          fontSize: "20px",
                          color: "#BC2835",
                        }}
                      >
                        No data available
                      </div>
                    )}
                  </div>
                </div>

                <div className="modal-footer footer_fp_modal">
                  <Button
                    variant="contained"
                    color="primary"
                    href="#contained-buttons"
                    onClick={() => this.setState({ addMemberButton: true })}
                  >
                    Add Members
                  </Button>
                </div>
                {/* ) : null} */}
              </div>
            </div>
          ) : this.state.userDetails?.length ? (
            <div
              className="modal-dialog  modal_dialog_forgot_pw"
              role="document"
            >
              <div className="modal-content modal_fp_content">
                <div className="modal-header forget_pw_header">
                  <h5
                    className="modal-title modal_fp_title "
                    id="exampleModalLabel"
                  >
                    Members
                  </h5>
                  <IconButton
                    onClick={() => this.setState({ manageModalOpen: false })}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
                <div className="modal-body fp_modal_body ">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body  mr-b10">
                    <TextField
                      className="input-100"
                      label={"Search"}
                      onChange={(event) =>
                        this.handleSearch(event.target.value)
                      }
                    />
                    <TableContainer className="modal-table mt-2">
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow className="table-header ">
                            <TableCell>
                              <Checkbox />
                            </TableCell>
                            <TableCell>
                              <h6> Name</h6>
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        {this.state.userDetails?.map((data, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <Checkbox
                                onChange={(event) =>
                                  this.handleCheckBox(data, event)
                                }
                              />
                            </TableCell>

                            <TableCell colSpan={12}>
                              <div className="spinner-place">
                                <span> {data?.full_name} </span>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </Table>
                    </TableContainer>
                    {this.state.selectedUsers?.length ? (
                      <div className="modal-body fp_modal_body ">
                        <div className="col-12 col-sm-12 col-md-12 modal_fp_body  mr-b10">
                          <div className="modal-footer footer_fp_modal">
                            <Button
                              variant="contained"
                              color="primary"
                              href="#contained-buttons"
                              onClick={() => this.addMemberToCot()}
                            >
                              Next
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>{" "}
                </div>
              </div>
            </div>
          ) : (
            <div
              className="modal-dialog  modal_dialog_forgot_pw"
              role="document"
            >
              <div className="modal-content modal_fp_content">
                <div className="modal-header forget_pw_header">
                  <h5
                    className="modal-title modal_fp_title "
                    id="exampleModalLabel"
                  >
                    Members
                  </h5>
                  <IconButton
                    onClick={() => this.setState({ manageModalOpen: false })}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
                <div className="modal-body fp_modal_body ">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body  mr-b10">
                    <div className="modal-table">
                      <div
                        style={{
                          paddingTop: "20px",
                          display: "flex",
                          justifyContent: "center",
                          fontSize: "20px",
                          color: "#BC2835",
                        }}
                      >
                        No data available
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal>
        {this.state.deleteModal ? (
          <Modal
            open={this.state.deleteModal}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw"
              role="document"
            >
              <div className="modal-content modal_fp_content">
                <div className="modal-header forget_pw_header">
                  <button
                    type="button"
                    className="close modal_fp_close_btn"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => this.setState({ deleteModal: false })}
                  >
                    <span aria-hidden="true">
                      <svg
                        width="10"
                        height="18"
                        viewBox="0 0 10 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2.91937 9.00008L9.82886 1.70718L8.48899 0.292969L0.23964 9.00008L8.48899 17.7072L9.82886 16.293L2.91937 9.00008Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </button>
                  <h5
                    className="modal-title modal_fp_title "
                    id="exampleModalLabel"
                  >
                    Remove Member
                  </h5>
                </div>

                <div className="modal-body fp_modal_body">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body mr-t20 mr-b10">
                    <h6 className="delete-dialog">
                      Are you sure you want to remove this user?
                      <br />
                      <br />
                      <i>Note: This action cannot be undone!</i>
                    </h6>
                  </div>
                </div>
                <div className="modal-footer footer_fp_modal">
                  <button
                    type="button"
                    className="btn-fp-footer btn-Reset"
                    onClick={this.removeMemberFromCot}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}
        {this.state.deleteGroupModal ? (
          <Modal
            open={this.state.deleteGroupModal}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw"
              role="document"
            >
              <div className="modal-content modal_fp_content">
                <div className="modal-header forget_pw_header">
                  <button
                    type="button"
                    className="close modal_fp_close_btn"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => this.setState({ deleteGroupModal: false })}
                  >
                    <span aria-hidden="true">
                      <svg
                        width="10"
                        height="18"
                        viewBox="0 0 10 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2.91937 9.00008L9.82886 1.70718L8.48899 0.292969L0.23964 9.00008L8.48899 17.7072L9.82886 16.293L2.91937 9.00008Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </button>
                  <h5
                    className="modal-title modal_fp_title "
                    id="exampleModalLabel"
                  >
                    Delete COT Group
                  </h5>
                </div>

                <div className="modal-body fp_modal_body">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body mr-t20 mr-b10">
                    <h6 className="delete-dialog">
                      Are you sure you want to delete this group?
                      <br />
                      <br />
                      <i>Note: This action cannot be undone!</i>
                    </h6>
                  </div>
                </div>
                <div className="modal-footer footer_fp_modal">
                  <button
                    type="button"
                    className="btn-fp-footer btn-Reset"
                    onClick={this.deleteCOT}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}

        <Modal
          open={this.state.modalOpen}
          aria-labelledby="DeleteUserProfile"
          aria-hidden="true"
        >
          {!this.state.nextButton ? (
            <div
              className="modal-dialog  modal_dialog_forgot_pw"
              role="document"
            >
              <div className="modal-content modal_fp_content">
                <div className="modal-header forget_pw_header">
                  <h5
                    className="modal-title modal_fp_title "
                    id="exampleModalLabel"
                  >
                    Create Group
                  </h5>
                  <IconButton onClick={() => this.handleClose()}>
                    <CloseIcon />
                  </IconButton>
                </div>
                <div className="modal-body fp_modal_body ">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body  mr-b10">
                    <TextField
                      className="input-100"
                      label={"Search"}
                      // value={this.state.se}
                      onChange={(event) =>
                        this.handleSearch(event.target.value)
                      }
                    />
                    {this.state.userDetails?.length ? (
                      <TableContainer className="modal-table mt-2">
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow className="table-header ">
                              <TableCell>
                                <Checkbox />
                              </TableCell>
                              <TableCell>
                                <h6> Name</h6>
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          {this.state.userDetails?.map((data, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                <Checkbox
                                  onChange={(event) =>
                                    this.handleCheckBox(data, event)
                                  }
                                />
                              </TableCell>

                              <TableCell colSpan={12}>
                                <div className="spinner-place">
                                  <span> {data?.full_name} </span>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </Table>
                      </TableContainer>
                    ) : (
                      <div
                        style={{
                          paddingTop: "20px",
                          display: "flex",
                          justifyContent: "center",
                          fontSize: "20px",
                          color: "#BC2835",
                        }}
                      >
                        No data available
                      </div>
                    )}
                  </div>
                </div>
                {this.state.selectedUsers?.length ? (
                  <div className="modal-footer footer_fp_modal">
                    <Button
                      variant="contained"
                      color="primary"
                      href="#contained-buttons"
                      onClick={() => this.setState({ nextButton: true })}
                    >
                      Next
                    </Button>
                  </div>
                ) : null}
              </div>
            </div>
          ) : (
            <div
              className="modal-dialog  modal_dialog_forgot_pw"
              role="document"
            >
              <div className="modal-content modal_fp_content">
                <div className="modal-header forget_pw_header">
                  <h5
                    className="modal-title modal_fp_title "
                    id="exampleModalLabel"
                  >
                    Create Group
                  </h5>
                  <IconButton onClick={() => this.handleClose()}>
                    <CloseIcon />
                  </IconButton>
                </div>
                <div className="modal-body fp_modal_body ">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body  mr-b10">
                    <TextField
                      className="input-100"
                      label="Group Name"
                      value={this.state.groupName}
                      onChange={(event) =>
                        this.addGroupName(event.target.value)
                      }
                    />
                    <p className="text-danger  mt-2">
                      {" "}
                      {this.state.groupNameError?.length
                        ? this.state.groupNameError
                        : null}{" "}
                    </p>
                  </div>
                </div>
                <div className="modal-footer footer_fp_modal">
                  <Button
                    variant="contained"
                    color="primary"
                    href="#contained-buttons"
                    onClick={() => this.addGroupApi()}
                  >
                    Create
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Modal>
        {this.state.showLogout ? (
          <Modal
            open={this.state.showLogout}
            aria-labelledby="DeleteUserProfile"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw"
              role="document"
            >
              <div className="modal-content modal_fp_content">
                <div className="modal-header forget_pw_header">
                  <h5
                    className="modal-title modal_fp_title "
                    id="exampleModalLabel"
                  >
                    Session Expired
                  </h5>
                </div>
                <div className="modal-body fp_modal_body">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body mr-t20 mr-b10">
                    <h6 className="delete-dialog">
                      Please login again.
                      <br />
                      <br />
                    </h6>
                  </div>
                </div>
                <div className="modal-footer footer_fp_modal">
                  <button
                    type="button"
                    className="btn-fp-footer btn-Reset"
                    onClick={this.logoutUser}
                    // disabled={this.state.loader}
                  >
                    Okay 
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default withRouter(Trusted);
