import React, { Component } from "react";
import Modal from "@material-ui/core/Modal";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import CircularProgress from "@material-ui/core/CircularProgress";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import adminMethods from "../../../services/adminApi";
import Checkbox from "@material-ui/core/Checkbox";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class CustomerMgmt extends Component {
  state = {
    customerColumns: [
      // { name: "id", title: "Customer ID" },
      { name: "cname", title: "Customer Name" },
      { name: "dcount", title: "Device Count" },
      { name: "date", title: "Registered Date" },
      { name: "users", title: "Users" },
      { name: "action", title: "Action" },
      { name: "view", title: "View" },
    ],
    customerData: [],
    sortCustomerData: {
      sort_by: "id",
      order_by: "asc",
      offset: 0,
      limit: 5,
      status: 1,
    },
    customerFilter: "",
    custFilteredData: [],
    deviceColumns: [
      { name: "id", title: "Device ID" },
      { name: "dname", title: "Device Name" },
      { name: "status", title: "Status" },
      // { name: "admin", title: "Admin" },
      { name: "action", title: "Action" },
    ],
    deviceData: [],
    sortDeviceData: {
      sort_by: "id",
      order_by: "desc",
      offset: 0,
      limit: 5,
      status: 1,
    },
    deviceFilter: "",
    deviceFilteredData: [],
    openCustomers: true,
    openDevices: false,
    showCreateCustomer: false,
    showDeleteCustomer: false,
    showEditCustomer: false,
    showCreateDevice: false,
    showDeleteDevice: false,
    showEditDevice: false,
    loader: false,
    userType: "Admin",
    status: "Active",
    ctableCount: 10,
    crowsPerPage: 5,
    cpage: 0,
    dtableCount: 5,
    drowsPerPage: 5,
    dpage: 0,
    order: true,
    customer_id: "",
    custName: "",
    street: "",
    city: "",
    stateName: "",
    zip: "",
    telephone: "",
    editCustomerData: [],
    createloader: false,
    editloader: false,
    deviceName: "",
    deviceId: "",
    noData: false,
    id: "",
    deviceList: [],
    selecteddeviceList: [],
    Id: "", // for deleting devices
    device_id: "", //for deleting devices
    showLogout: false,
    open: false,
    message: "",
  };

  componentDidMount = () => {
    this.setState({
      deviceFilteredData: this.state.deviceData,
    });
    // console.log(this.state.id);
    this.getCustomerDetails();
  };

  openCustomer = () => {
    this.resetForm();

    this.setState({ showCreateCustomer: true });
  };

  editCustomer = async (customerId) => {
    var customerData = {
      customer_id: customerId,
    };

    const response = await adminMethods.editCustomerProfile(customerData);

    if (response.data.statusCode === 200) {
      var details = response.data.result[0];
      await this.setState({
        showEditCustomer: true,
        custName: details.customer_name,
        street: details.street_address,
        city: details.city,
        stateName: details.state,
        zip: details.zipcode,
        telephone: details.telephone,
        customer_id: details.customer_id,
      });
    }
  };

  deleteCustomer = async (customerId) => {
    await this.setState({ showDeleteCustomer: true, customer_id: customerId });
  };

  openDevice = async () => {
    var id = {
      id: this.state.id,
    };
    const response = await adminMethods.getCustomerNewDevice(id);
    if (response.data.statusCode === 200) {
      var details = response.data.result;
      await this.setState({
        deviceList: details,
      });
      // console.log(this.state.deviceList);
    }
    // console.log(this.state.status);
    this.setState({ showCreateDevice: true });
  };

  editDevice = (device) => {
    this.setState({
      showEditDevice: true,
      deviceId: device.device_id,
      deviceName: device.device_name,
      status: device.status,
      customer_id: device.customer_id,
    });
  };

  deleteDevice = (device) => {
    this.setState({ showDeleteDevice: true });
    // console.log(device);
    this.setState({
      Id: device.Id,
      device_id: device.device_id,
    });
  };

  handleClose = () => {
    this.setState({
      showCreateCustomer: false,
      showEditCustomer: false,
      showDeleteCustomer: false,
      showCreateDevice: false,
      showEditDevice: false,
      showDeleteDevice: false,
    });
    this.resetForm();
  };

  getCustomerDetails = async () => {
    const response = await adminMethods.getCustomerDetails();

    if (
      response.data.statusCode === 401 &&
      response.data.message == "Session expires. Please login again"
    ) {
      this.setState({
        showLogout: true,
      });
    }
    if (
      response.data.statusCode === 401 &&
      response.data.message != "Session expires. Please login again"
    ) {
      this.setState({
        open: true,
        message: response.data.message,
        loader: false,
      });
    }

    if (response.data.statusCode === 200) {
      // console.log(response.data.result);
      var result = response.data.result;
      var custArray = [];
      // ------------------------------------------------------------Company Management table
      result.forEach((element) => {
        custArray.push({
          id: element.CustomerId,
          // id: 1,
          cname: element.customer_name,
          users: element.user,
          dcount: element.devices,
          date: "06/05/2021",
        });
      });

      await this.setState({
        customerData: custArray,
        ctableCount: custArray.length,
        custFilteredData: custArray,
      });
    }
  };

  logoutUser = () => {
    // this.props.history.replace({
    //   pathname: "/",
    // });
    // window.sessionStorage.clear();
    this.setState({
      showLogout: false,
    });
  };

  createCustomer = async (event) => {
    await this.setState({
      [event.target.name]: event.target.value,
    });
  };

  createCustomerProfile = async () => {
    this.setState({ createloader: true });

    var createCustomerObject = {
      customer_name: this.state.custName,
      state: this.state.stateName,
      city: this.state.city,
      street_address: this.state.street,
      zipcode: this.state.zip,
      telephone: this.state.telephone,
    };

    const response = await adminMethods.createCustomer(createCustomerObject);

    if (response.data.statusCode === 200) {
      this.getCustomerDetails();
      this.setState({ createloader: false, showCreateCustomer: false });
    }
  };

  editCustomerDetails = async (event) => {
    await this.setState({
      [event.target.name]: event.target.value,
    });
  };

  editCustomerProfile = async () => {
    this.setState({ editloader: true });
    var editedCustomerObject = {
      customer_id: this.state.customer_id,
      customer_name: this.state.custName,
      state: this.state.stateName,
      city: this.state.city,
      street_address: this.state.street,
      zipcode: this.state.zip,
      telephone: this.state.telephone,
    };
    const response = await adminMethods.saveCustomerDetails(
      editedCustomerObject
    );

    if (response.data.statusCode === 200) {
      this.getCustomerDetails();
      this.setState({ editloader: false, showEditCustomer: false });
    }
  };

  deleteCustomerProfile = async () => {
    var deleteId = {
      customer_id: this.state.customer_id,
    };

    const response = await adminMethods.deleteCustomer(deleteId);

    if (response.data.statusCode === 200) {
      await this.setState({
        showDeleteCustomer: false,
      });
      this.getCustomerDetails();
    }
  };

  createDeviceProfile = async () => {
    var deviceList = {
      device_ids: this.state.selecteddeviceList,
      status: this.state.status,
      customer_id: this.state.id,
    };
    // console.log(deviceList);
    const response = await adminMethods.addCustomerDeviceDetails(deviceList);
    if (response.data.statusCode === 200) {
      await this.setState({
        showCreateDevice: false,
      });
      this.openDeviceTable(this.state.id);
    }
  };

  editDeviceProfile = async () => {
    var updateData = {
      device_id: this.state.deviceId,
      device_name: this.state.deviceName,
      status: this.state.status,
    };

    const response = await adminMethods.updateCustomerDeviceDetails(updateData);

    if (response.data.statusCode === 200) {
      this.setState({
        showEditDevice: false,
      });
      this.openDeviceTable(this.state.id);
    }
  };

  deleteDeviceProfile = async () => {
    var deleteData = {
      Id: this.state.Id,
      device_id: this.state.device_id,
    };
    // console.log(deleteData);
    const response = await adminMethods.deleteCustomerDevices(deleteData);

    if (response.data.statusCode === 200) {
      this.setState({
        showDeleteDevice: false,
      });
      this.openDeviceTable(this.state.id);
    }
  };

  changeStatus = async (event) => {
    await this.setState({
      status: event.target.value,
    });
    // console.log(this.state.status);
  };

  select = (event) => {
    // console.log(event.target.value);
    this.setState({ selecteddeviceList: event.target.value });
  };

  handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    this.setState({
      selecteddeviceList: value,
    });
  };

  handleEditDevice = async (event) => {
    this.setState({
      deviceName: event.target.value,
    });
  };

  openUserTable = (customerName, customerId) => {
    var switchTab = document.getElementById("wrapped-tab-four");
    switchTab.click();

    var customerDetails = {
      customerName: customerName,
      customerId: customerId,
    };

    window.sessionStorage.setItem(
      "CustomerDetails",
      JSON.stringify(customerDetails)
    );
  };

  openDeviceTable = async (customerId) => {
    // console.log(customerId.id);
    this.setState({
      id: customerId,
    });
    const response = await adminMethods.getCustomerDeviceDetails(
      this.state.deviceFilter
    );

    if (response.data.statusCode === 200) {
      await this.setState({
        openCustomers: false,
        openDevices: true,
        deviceData: response.data.result,
        dtableCount: response.data.result.length,
        dpage: 0,
      });

      if (response.data.result > 0) {
        this.setState({ noData: false });
      } else {
        this.setState({ noData: true });
      }
    }
  };

  openCustomerTable = () => {
    this.setState({
      openCustomers: true,
      openDevices: false,
    });
  };

  handleCustomerChangePage = (event, newPage) => {
    this.setState({
      cpage: newPage,
    });
  };

  handleCustomerChangeRowsPerPage = (event) => {
    this.setState({
      crowsPerPage: parseInt(event.target.value),
      cpage: 0,
    });
  };

  handleDeviceChangePage = (event, newPage) => {
    this.setState({
      dpage: newPage,
    });
  };

  handleDeviceChangeRowsPerPage = (event) => {
    this.setState({
      drowsPerPage: parseInt(event.target.value),
      dpage: 0,
    });
  };

  createCustomerSortHandler = (property) => (event) => {
    let newSortedData = { ...this.state.sortCustomerData };
    newSortedData.sort_by = property;
    newSortedData.order_by =
      this.state.sortCustomerData.order_by === "asc" ? "desc" : "asc";

    var sortedData = this.stableSort(
      this.state.customerData,
      this.getComparator(newSortedData.order_by, property)
    );

    this.setState({
      sortCustomerData: newSortedData,
      customerData: [...sortedData],
    });
  };

  createDeviceSortHandler = (property) => (event) => {
    let newSortedData = { ...this.state.sortDeviceData };
    newSortedData.sort_by = property;
    newSortedData.order_by =
      this.state.sortDeviceData.order_by === "asc" ? "desc" : "asc";

    var sortedData = this.stableSort(
      this.state.deviceData,
      this.getComparator(newSortedData.order_by, property)
    );

    this.setState({
      sortDeviceData: newSortedData,
      deviceData: [...sortedData],
    });
  };

  stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  }

  getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  }

  descendingComparator(a, b, orderBy) {
    if (orderBy === "id") {
      return b[orderBy] - a[orderBy] < 0 ? -1 : 1;
    } else {
      if (b[orderBy] > a[orderBy]) {
        return -1;
      }
      if (b[orderBy] < a[orderBy]) {
        return 1;
      }
      return 0;
    }
  }

  handleCustomerSearchData = (event) => {
    this.setState({ customerFilter: event.target.value });

    var newArray = [];

    this.state.customerData.filter((data) => {
      if (event.target.value === "") {
        this.setState({
          customerData: this.state.custFilteredData,
          ctableCount: this.state.custFilteredData.length,
          cpage: 0,
        });
      }
      if (
        data.id === event.target.value ||
        data.cname.toLowerCase() === event.target.value.toLowerCase() ||
        data.dcount === event.target.value ||
        data.date === event.target.value ||
        data.users === event.target.value
      ) {
        newArray.push(data);
        this.setState({
          customerData: newArray,
          ctableCount: newArray.length,
          cpage: 0,
        });
      }
    });
  };

  handleDeviceSearchData = (event) => {
    this.setState({ deviceFilter: event.target.value }, () => {
      this.openDeviceTable(this.state.id);
    });

    // var newArray = [];

    // this.state.deviceData.filter((data) => {
    //   if (event.target.value === "") {
    //     this.setState({
    //       deviceData: this.state.deviceFilteredData,
    //       dtableCount: this.state.deviceFilteredData.length,
    //       dpage: 0,
    //     });
    //   }
    //   if (
    //     data.id === event.target.value ||
    //     data.dname.toLowerCase() === event.target.value.toLowerCase() ||
    //     data.status.toLowerCase() === event.target.value.toLowerCase() ||
    //     data.admin.toLowerCase() === event.target.value.toLowerCase()
    //   ) {
    //     newArray.push(data);
    //     this.setState({
    //       deviceData: newArray,
    //       dtableCount: newArray.length,
    //       dpage: 0,
    //     });
    //   }
    // });
  };

  resetForm = () => {
    this.setState({
      custName: "",
      street: "",
      zip: "",
      telephone: "",
      stateName: "",
      city: "",
      createloader: false,
      editloader: false,
      loader: false,
    });
  };

  render() {
    console.log(window.sessionStorage.getItem("role_id") );
    return (
      <div>
        <Snackbar
          open={this.state.open}
          autoHideDuration={3000}
          onClose={() => this.setState({ open: false })}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert
            // onClose={handleClose}
            severity="info"
            sx={{ width: "100%" }}
          >
            {this.state.message}
          </Alert>
        </Snackbar>
        {this.state.openCustomers ? (
          <div className="registered-users">
            <div className="text-center registered-title">
              <h6> Company Management </h6>
            </div>

            <div className="registered-user-table mr-t20">
              <div className="row mr-t20">
                <div className="column col-md-5 mr-t10">
                  {/* <input
                    type="text"
                    className="form-control black-input-control"
                    placeholder="Search"
                    name="Search"
                    value={this.state.customerFilter}
                    onChange={this.handleCustomerSearchData}
                  /> */}
                </div>

                <div align="right" className="column col-md-7">
                  {this.state.customerData.length == 0 &&
                  window.sessionStorage.getItem("role_id") == 3 ? (
                    <button
                      type="button"
                      className="btn btn-secondary customer-btn col-md-8 "
                      onClick={this.openCustomer}
                    >
                      + Create A Company Profile
                    </button>
                  ) : null}
                </div>
              </div>

              <TableContainer component={Paper} className="table-style">
                {this.state.customerData.length > 0 ? (
                  <Table aria-label="simple table" size="small">
                    <TableHead>
                      <TableRow>
                        {this.state.customerColumns.map((column, index) => (
                          <TableCell
                            key={column.name}
                            className="table-color"
                            align="center"
                          >
                            <TableSortLabel
                              active={
                                this.state.sortCustomerData.sort_by ===
                                column.name
                              }
                              direction={
                                this.state.sortCustomerData.sort_by ===
                                column.name
                                  ? this.state.sortCustomerData.order_by
                                  : "asc"
                              }
                              onClick={this.createCustomerSortHandler(
                                column.name
                              )}
                            >
                              {column.title}
                              {this.state.sortCustomerData.sort_by ===
                              column.name ? (
                                <span
                                  style={{
                                    border: 0,
                                    clip: "rect(0 0 0 0)",
                                    height: 1,
                                    overflow: "hidden",
                                    padding: 0,
                                    position: "absolute",
                                    top: 20,
                                    width: 1,
                                  }}
                                ></span>
                              ) : null}
                            </TableSortLabel>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {this.state.customerData
                        .slice(
                          this.state.cpage * this.state.crowsPerPage,
                          this.state.cpage * this.state.crowsPerPage +
                            this.state.crowsPerPage
                        )
                        .map((row) => (
                          <TableRow key={row.id} hover>
                            {/* <TableCell className="table-color" align="center">
                              {row.id}
                            </TableCell> */}
                            <TableCell className="table-color" align="center">
                              {row.cname}
                            </TableCell>
                            <TableCell className="table-color" align="center">
                              {/* <button className="user-button"> */}
                              {row.dcount}
                              {/* </button> */}
                            </TableCell>
                            <TableCell className="table-color" align="center">
                              {row.date}
                            </TableCell>
                            <TableCell className="table-color" align="center">
                              <button
                                className="user-button"
                                onClick={() =>
                                  this.openUserTable(row.cname, row.id)
                                }
                              >
                                {row.users}
                              </button>
                            </TableCell>
                            <TableCell className="table-color" align="center">
                              <i
                                className="fa fa-edit"
                                aria-hidden="true"
                                onClick={() => this.editCustomer(row.id)}
                              ></i>
                              &nbsp;
                              <i
                                className="fa fa-trash mr-l10"
                                aria-hidden="true"
                                onClick={() => this.deleteCustomer(row.id)}
                              ></i>
                            </TableCell>
                            <TableCell className="table-color" align="center">
                              <button
                                className="user-button"
                                onClick={() => this.openDeviceTable(row.id)}
                              >
                                Devices
                              </button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                    {/* <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          colSpan={8}
                          count={this.state.ctableCount}
                          rowsPerPage={this.state.crowsPerPage}
                          page={this.state.cpage}
                          SelectProps={{
                            inputProps: { "aria-label": "rows per page" },
                            native: true,
                          }}
                          onChangePage={this.handleCustomerChangePage}
                          onChangeRowsPerPage={this.handleCustomerChangeRowsPerPage}
                        />
                      </TableRow>
                    </TableFooter> */}
                  </Table>
                ) : // <CircularProgress style={{ margin: "10% 0% 10% 50%", color: "#c12a37" }} />
                null}
              </TableContainer>
            </div>

            {this.state.showCreateCustomer ? (
              <Modal
                open={this.state.showCreateCustomer}
                aria-labelledby="CreateCustomerProfile"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered modal-lg"
                  role="document"
                >
                  <div className="modal-content modal_fp_content">
                    <div className="modal-header forget_pw_header">
                      <button
                        type="button"
                        className="close modal_fp_close_btn"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={this.handleClose}
                      >
                        <span aria-hidden="true">
                          <svg
                            width="10"
                            height="18"
                            viewBox="0 0 10 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M2.91937 9.00008L9.82886 1.70718L8.48899 0.292969L0.23964 9.00008L8.48899 17.7072L9.82886 16.293L2.91937 9.00008Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                      </button>
                      <h5
                        className="modal-title modal_fp_title "
                        id="exampleModalLabel"
                      >
                        Create Company Profile
                      </h5>
                    </div>

                    <div className="modal-body fp_modal_body">
                      <div className="row register_detail_wrap ">
                        <div className="col-12 col-sm-6 col-md-6">
                          <h6>
                            Customer Name<em className="mandatory">* </em>
                          </h6>
                          <input
                            type="text"
                            className="customer-modal-inputs"
                            name="custName"
                            value={this.state.custName}
                            onChange={this.createCustomer}
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6">
                          <h6>Street</h6>
                          <input
                            type="text"
                            className="customer-modal-inputs"
                            name="street"
                            value={this.state.street}
                            onChange={this.createCustomer}
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6">
                          <h6>City</h6>
                          <input
                            type="text"
                            className="customer-modal-inputs"
                            name="city"
                            value={this.state.city}
                            onChange={this.createCustomer}
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6">
                          <h6>State</h6>
                          <input
                            type="text"
                            name="stateName"
                            className="customer-modal-inputs"
                            value={this.state.stateName}
                            onChange={this.createCustomer}
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6">
                          <h6>Zip</h6>
                          <input
                            type="text"
                            className="customer-modal-inputs"
                            name="zip"
                            value={this.state.zip}
                            onChange={this.createCustomer}
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6">
                          <h6>Telephone</h6>
                          <input
                            type="text"
                            className="customer-modal-inputs"
                            name="telephone"
                            value={this.state.telephone}
                            onChange={this.createCustomer}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer footer_fp_modal">
                      <button
                        type="button"
                        className="btn-fp-footer btn-Reset"
                        onClick={this.createCustomerProfile}
                        disabled={this.state.createloader}
                      >
                        Create &nbsp;
                        {this.state.createloader ? (
                          <i className="fa fa-spinner fa-spin"></i>
                        ) : null}
                      </button>
                      <button
                        type="button"
                        className="btn-fp-footer btn-Reset"
                        onClick={this.handleClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </Modal>
            ) : null}

            {this.state.showEditCustomer ? (
              <Modal
                open={this.state.showEditCustomer}
                aria-labelledby="EditCustomerProfile"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered modal-lg"
                  role="document"
                >
                  <div className="modal-content modal_fp_content">
                    <div className="modal-header forget_pw_header">
                      <button
                        type="button"
                        className="close modal_fp_close_btn"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={this.handleClose}
                      >
                        <span aria-hidden="true">
                          <svg
                            width="10"
                            height="18"
                            viewBox="0 0 10 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M2.91937 9.00008L9.82886 1.70718L8.48899 0.292969L0.23964 9.00008L8.48899 17.7072L9.82886 16.293L2.91937 9.00008Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                      </button>
                      <h5
                        className="modal-title modal_fp_title "
                        id="exampleModalLabel"
                      >
                        Edit Customer Profile
                      </h5>
                    </div>

                    <div className="modal-body fp_modal_body">
                      <div className="row register_detail_wrap ">
                        <div className="col-12 col-sm-6 col-md-6">
                          <h6>
                            Customer Id<em className="mandatory">* </em>
                          </h6>

                          <input
                            type="text"
                            className="customer-modal-inputs"
                            name="customer_id"
                            value={this.state.customer_id}
                            onChange={this.editCustomerDetails}
                            disabled
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6">
                          <h6>
                            Customer Name<em className="mandatory">* </em>
                          </h6>
                          <input
                            type="text"
                            className="customer-modal-inputs"
                            name="custName"
                            value={this.state.custName}
                            onChange={this.editCustomerDetails}
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6">
                          <h6>State</h6>
                          <input
                            type="text"
                            className="customer-modal-inputs"
                            name="stateName"
                            value={this.state.stateName}
                            onChange={this.editCustomerDetails}
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6">
                          <h6>City</h6>
                          <input
                            type="text"
                            className="customer-modal-inputs"
                            name="city"
                            value={this.state.city}
                            onChange={this.editCustomerDetails}
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6">
                          <h6>Street</h6>
                          <input
                            type="text"
                            className="customer-modal-inputs"
                            name="street"
                            value={this.state.street}
                            onChange={this.editCustomerDetails}
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6">
                          <h6>Zip</h6>
                          <input
                            type="text"
                            className="customer-modal-inputs"
                            name="zip"
                            value={this.state.zip}
                            onChange={this.editCustomerDetails}
                          />
                        </div>
                        <div className="col-12 col-sm-6 col-md-6">
                          <h6>Telephone</h6>
                          <input
                            type="text"
                            className="customer-modal-inputs"
                            name="telephone"
                            value={this.state.telephone}
                            onChange={this.editCustomerDetails}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer footer_fp_modal">
                      <button
                        type="button"
                        className="btn-fp-footer btn-Reset"
                        onClick={this.editCustomerProfile}
                        disabled={this.state.editloader}
                      >
                        Update &nbsp;
                        {this.state.editloader ? (
                          <i className="fa fa-spinner fa-spin"></i>
                        ) : null}
                      </button>
                      <button
                        type="button"
                        className="btn-fp-footer btn-Reset"
                        onClick={this.handleClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </Modal>
            ) : null}

            {this.state.showDeleteCustomer ? (
              <Modal
                open={this.state.showDeleteCustomer}
                aria-labelledby="DeleteCustomerProfile"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw"
                  role="document"
                >
                  <div className="modal-content modal_fp_content">
                    <div className="modal-header forget_pw_header">
                      <button
                        type="button"
                        className="close modal_fp_close_btn"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={this.handleClose}
                      >
                        <span aria-hidden="true">
                          <svg
                            width="10"
                            height="18"
                            viewBox="0 0 10 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M2.91937 9.00008L9.82886 1.70718L8.48899 0.292969L0.23964 9.00008L8.48899 17.7072L9.82886 16.293L2.91937 9.00008Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                      </button>
                      <h5
                        className="modal-title modal_fp_title "
                        id="exampleModalLabel"
                      >
                        Delete Customer?
                      </h5>
                    </div>

                    <div className="modal-body fp_modal_body">
                      <div className="col-12 col-sm-12 col-md-12 modal_fp_body mr-t20 mr-b10">
                        <h6 className="delete-dialog">
                          Are you sure you want to delete this company? All the
                          devices and users linked to the company will be
                          unlinked.
                          <br />
                          <br />
                          <i>Note: This action cannot be undone!</i>
                        </h6>
                      </div>
                    </div>

                    <div className="modal-footer footer_fp_modal">
                      <button
                        type="button"
                        className="btn-fp-footer btn-Reset"
                        onClick={this.deleteCustomerProfile}
                        disabled={this.state.loader}
                      >
                        Delete &nbsp;
                        {this.state.loader ? (
                          <i className="fa fa-spinner fa-spin"></i>
                        ) : null}
                      </button>
                      <button
                        type="button"
                        className="btn-fp-footer btn-Reset"
                        onClick={this.handleClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </Modal>
            ) : null}
          </div>
        ) : this.state.openDevices ? (
          <div className="registered-users ">
            <div className="text-center registered-title">
              <h6> Device Management </h6>
            </div>

            <div className="mr-t10 row ">
              <div
                className="column pd10 col-md-8"
                onClick={this.openCustomerTable}
              >
                <KeyboardBackspaceIcon /> Back to Company
              </div>
            </div>

            <div className="registered-user-table">
              <div className="row">
                <div className="column col-md-5 mr-t10">
                  <input
                    type="text"
                    className="form-control black-input-control"
                    placeholder="Search"
                    name="Search"
                    value={this.state.deviceFilter}
                    onChange={this.handleDeviceSearchData}
                  />
                </div>

                <div align="right" className="column col-md-7">
                  <button
                    type="button"
                    className="btn btn-secondary customer-btn  col-md-6"
                    onClick={this.openDevice}
                  >
                    +Add New Device
                  </button>
                </div>
              </div>
              <TableContainer component={Paper} className="table-style mr-t10">
                {this.state.deviceData.length > 0 ? (
                  <Table aria-label="simple table" size="small">
                    <TableHead>
                      <TableRow>
                        {this.state.deviceColumns.map((column, index) => (
                          <TableCell
                            key={column.name}
                            className="table-color"
                            align="center"
                          >
                            <TableSortLabel
                              active={
                                this.state.sortDeviceData.sort_by ===
                                column.name
                              }
                              direction={
                                this.state.sortDeviceData.sort_by ===
                                column.name
                                  ? this.state.sortDeviceData.order_by
                                  : "asc"
                              }
                              onClick={this.createDeviceSortHandler(
                                column.name
                              )}
                            >
                              {column.title}
                              {this.state.sortDeviceData.sort_by ===
                              column.name ? (
                                <span
                                  style={{
                                    border: 0,
                                    clip: "rect(0 0 0 0)",
                                    height: 1,
                                    overflow: "hidden",
                                    padding: 0,
                                    position: "absolute",
                                    top: 20,
                                    width: 1,
                                  }}
                                ></span>
                              ) : null}
                            </TableSortLabel>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.deviceData
                        .slice(
                          this.state.dpage * this.state.drowsPerPage,
                          this.state.dpage * this.state.drowsPerPage +
                            this.state.drowsPerPage
                        )
                        .map((row) => (
                          <TableRow key={row.device_id} hover>
                            <TableCell className="table-color" align="center">
                              {row.device_id}
                            </TableCell>
                            <TableCell className="table-color" align="center">
                              {row.device_name}
                            </TableCell>
                            <TableCell className="table-color" align="center">
                              <span>{row.status}</span>
                              &nbsp; &nbsp;
                              <span>
                                {row.status == "Active" ||
                                row.status == "active" ? (
                                  <i
                                    className="fa fa-circle green"
                                    aria-hidden="true"
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-circle red"
                                    aria-hidden="true"
                                  ></i>
                                )}
                              </span>
                            </TableCell>
                            {/* <TableCell className="table-color" align="center">
                            {row.admin}
                          </TableCell> */}
                            <TableCell className="table-color" align="center">
                              <i
                                className="fa fa-edit"
                                aria-hidden="true"
                                onClick={() => this.editDevice(row)}
                              ></i>
                              <i
                                className="fa fa-trash mr-l10"
                                aria-hidden="true"
                                onClick={() => this.deleteDevice(row)}
                              ></i>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          colSpan={8}
                          count={this.state.dtableCount}
                          rowsPerPage={this.state.drowsPerPage}
                          page={this.state.dpage}
                          SelectProps={{
                            inputProps: { "aria-label": "rows per page" },
                            native: true,
                          }}
                          onChangePage={this.handleDeviceChangePage}
                          onChangeRowsPerPage={
                            this.handleDeviceChangeRowsPerPage
                          }
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                ) : this.state.noData ? (
                  <div className="text-center registered-title mr-b20 mr-t20">
                    <label style={{ color: "#fff" }}>No data to display</label>
                  </div>
                ) : (
                  <CircularProgress
                    style={{ margin: "10% 0% 10% 50%", color: "#c12a37" }}
                  />
                )}
              </TableContainer>
            </div>

            {this.state.showCreateDevice ? (
              <Modal
                open={this.state.showCreateDevice}
                aria-labelledby="CreateDevice"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content modal_fp_content">
                    <div className="modal-header forget_pw_header">
                      <button
                        type="button"
                        className="close modal_fp_close_btn"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={this.handleClose}
                      >
                        <span aria-hidden="true">
                          <svg
                            width="10"
                            height="18"
                            viewBox="0 0 10 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M2.91937 9.00008L9.82886 1.70718L8.48899 0.292969L0.23964 9.00008L8.48899 17.7072L9.82886 16.293L2.91937 9.00008Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                      </button>
                      <h5
                        className="modal-title modal_fp_title "
                        id="exampleModalLabel"
                      >
                        Add Device
                      </h5>
                    </div>

                    <div className="modal-body fp_modal_body">
                      <div className="register_detail_wrap ">
                        <div className="col-12 ">
                          <h6>
                            Device Name<em className="mandatory">* </em>
                          </h6>
                          <Select
                            className="fp_modal_input"
                            onChange={this.select}
                            renderValue={(selected) => selected.join(", ")}
                            value={this.state.selecteddeviceList}
                            input={<Input id="select-multiple" />}
                            multiple
                          >
                            {this.state.deviceList.map((device, index) => (
                              <MenuItem value={device.device_id} key={index}>
                                <Checkbox
                                  checked={
                                    this.state.selecteddeviceList.indexOf(
                                      device.device_id
                                    ) > -1
                                  }
                                />
                                {device.device_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                        <div className="col-12 ">
                          <h6>
                            Status<em className="mandatory">* </em>
                          </h6>
                          <Select
                            id="invite"
                            className="fp_modal_input"
                            onChange={this.changeStatus}
                            value={this.state.status}
                          >
                            <MenuItem value="active" name="Active">
                              Active
                            </MenuItem>
                            <MenuItem value="in-active" name="In-active">
                              In-active
                            </MenuItem>
                          </Select>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer footer_fp_modal">
                      <button
                        type="button"
                        className="btn-fp-footer btn-Reset"
                        onClick={this.createDeviceProfile}
                        disabled={this.state.loader}
                      >
                        Confirm &nbsp;
                        {this.state.loader ? (
                          <i className="fa fa-spinner fa-spin"></i>
                        ) : null}
                      </button>
                      <button
                        type="button"
                        className="btn-fp-footer btn-Reset"
                        onClick={this.handleClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </Modal>
            ) : null}

            {this.state.showEditDevice ? (
              <Modal
                open={this.state.showEditDevice}
                aria-labelledby="EditDeviceProfile"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content modal_fp_content">
                    <div className="modal-header forget_pw_header">
                      <button
                        type="button"
                        className="close modal_fp_close_btn"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={this.handleClose}
                      >
                        <span aria-hidden="true">
                          <svg
                            width="10"
                            height="18"
                            viewBox="0 0 10 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M2.91937 9.00008L9.82886 1.70718L8.48899 0.292969L0.23964 9.00008L8.48899 17.7072L9.82886 16.293L2.91937 9.00008Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                      </button>
                      <h5
                        className="modal-title modal_fp_title "
                        id="exampleModalLabel"
                      >
                        Edit Device Details
                      </h5>
                    </div>

                    <div className="modal-body fp_modal_body">
                      <div className=" register_detail_wrap ">
                        <div className="col-12 ">
                          <h6>
                            Device Id<em className="mandatory">* </em>
                          </h6>

                          <input
                            type="text"
                            className="customer-modal-inputs"
                            name="deviceId"
                            value={this.state.deviceId}
                            disabled
                          />
                        </div>
                        <div className="col-12">
                          <h6>
                            Device Name<em className="mandatory">* </em>
                          </h6>
                          <input
                            type="text"
                            className="customer-modal-inputs"
                            name="deviceName"
                            value={this.state.deviceName}
                            onChange={this.handleEditDevice}
                          />
                        </div>
                        <div className="col-12">
                          <h6>
                            Status<em className="mandatory">* </em>
                          </h6>
                          <select
                            id="invite"
                            className="fp_modal_input"
                            onChange={this.changeStatus}
                            value={this.state.status}
                          >
                            <option value="active" name="Active">
                              Active
                            </option>
                            <option value="in-active" name="In-active">
                              In-active
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer footer_fp_modal">
                      <button
                        type="button"
                        className="btn-fp-footer btn-Reset"
                        onClick={this.editDeviceProfile}
                        disabled={this.state.loader}
                      >
                        Update &nbsp;
                        {this.state.loader ? (
                          <i className="fa fa-spinner fa-spin"></i>
                        ) : null}
                      </button>
                      <button
                        type="button"
                        className="btn-fp-footer btn-Reset"
                        onClick={this.handleClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </Modal>
            ) : null}

            {this.state.showDeleteDevice ? (
              <Modal
                open={this.state.showDeleteDevice}
                aria-labelledby="DeleteDeleteProfile"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw"
                  role="document"
                >
                  <div className="modal-content modal_fp_content">
                    <div className="modal-header forget_pw_header">
                      <button
                        type="button"
                        className="close modal_fp_close_btn"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={this.handleClose}
                      >
                        <span aria-hidden="true">
                          <svg
                            width="10"
                            height="18"
                            viewBox="0 0 10 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M2.91937 9.00008L9.82886 1.70718L8.48899 0.292969L0.23964 9.00008L8.48899 17.7072L9.82886 16.293L2.91937 9.00008Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                      </button>
                      <h5
                        className="modal-title modal_fp_title "
                        id="exampleModalLabel"
                      >
                        Delete Device?
                      </h5>
                    </div>

                    <div className="modal-body fp_modal_body">
                      <div className="col-12 col-sm-12 col-md-12 modal_fp_body mr-t20 mr-b10">
                        <h6 className="delete-dialog">
                          Are you sure you want to delete this device?
                          <br />
                          <br />
                          <i>Note: This action cannot be undone!</i>
                        </h6>
                      </div>
                    </div>

                    <div className="modal-footer footer_fp_modal">
                      <button
                        type="button"
                        className="btn-fp-footer btn-Reset"
                        onClick={this.deleteDeviceProfile}
                        disabled={this.state.loader}
                      >
                        Delete &nbsp;
                        {this.state.loader ? (
                          <i className="fa fa-spinner fa-spin"></i>
                        ) : null}
                      </button>
                      <button
                        type="button"
                        className="btn-fp-footer btn-Reset"
                        onClick={this.handleClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </Modal>
            ) : null}
          </div>
        ) : null}

        {/* User Timeout session storage logout  */}
        {this.state.showLogout ? (
          <Modal
            open={this.state.showLogout}
            aria-labelledby="DeleteUserProfile"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw"
              role="document"
            >
              <div className="modal-content modal_fp_content">
                <div className="modal-header forget_pw_header">
                  <h5
                    className="modal-title modal_fp_title "
                    id="exampleModalLabel"
                  >
                    Session Expired
                  </h5>
                </div>
                <div className="modal-body fp_modal_body">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body mr-t20 mr-b10">
                    <h6 className="delete-dialog">
                      Please login again.
                      <br />
                      <br />
                    </h6>
                  </div>
                </div>
                <div className="modal-footer footer_fp_modal">
                  <button
                    type="button"
                    className="btn-fp-footer btn-Reset"
                    onClick={this.logoutUser}
                    // disabled={this.state.loader}
                  >
                    Okay
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default CustomerMgmt;
