import React, { Component } from "react";
import ToggleSideNav from "../UI/ToggleSideNav/ToggleSideNav";
import userMethods from "../../services/userApi";
import devicesMethods from "../../services/deviceApi";
import Modal from "@material-ui/core/Modal";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Registration extends Component {
  state = {
    connectedDevices: [],
    availableDevices: [],
    editModal: false,
    deviceData: {},
    deviceName: "",
    deleteModal: false,
    errorModal: "",
    showLogout: false,
    open: false,
    message: "",
  };

  componentDidMount() {
    this.fetchAllDriskDevices();
  }

  fetchAllDriskDevices = async () => {
    const response = await userMethods.getAllDriskDevices();
    var connectedArray = [];
    var disconnectedArray = [];

    if (response.data.statusCode === 200) {
      var result = response.data.result.device_details;

      result.forEach((element) => {
        if (element.is_connected === "connected") {
          connectedArray.push(element);
        } else {
          disconnectedArray.push(element);
        }
      });

      await this.setState({
        connectedDevices: connectedArray,
        availableDevices: disconnectedArray,
      });
    }
    if (
      response.data.statusCode === 401 &&
      response.data.message == "Session expires. Please login again"
    ) {
      this.setState({
        showLogout: true,
      });
    }
    if (
      response.data.statusCode === 401 &&
      response.data.message != "Session expires. Please login again"
    ) {
      this.setState({
        open: true,
        message: response.data.message,
        loader: false,
      });
    }
  };

  logoutUser = () => {
    window.sessionStorage.clear();
    this.props.history.replace({
      pathname: "/",
    });
  };

  editDeviceName = async () => {
    await this.setState({ loader: true });

    var updateDevice = {
      device_name:
        this.state.deviceName === ""
          ? this.setState({ errorModal: "Enter a name", loader: false })
          : this.state.deviceName,

      device_id: this.state.deviceData.device_id,
    };

    const response = await userMethods.updateDriskDevices(updateDevice);

    if (response.data.statusCode === 200) {
      this.fetchAllDriskDevices();
      this.setState({ editModal: false, loader: false });
    }
  };

  handleOpen = (device) => {
    this.setState({
      editModal: true,
      deviceData: device,
      deviceName: device.name,
      errorModal: "",
    });
  };

  handleClose = () => {
    this.setState({ editModal: false, deleteModal: false });
  };

  handleNameChange = (event) => {
    this.setState({ deviceName: event.target.value });
  };

  handleOpenDeleteModal = (device) => {
    this.setState({
      deleteModal: true,
      deviceData: device,
      deviceName: device.name,
    });
  };

  deleteDevice = async () => {
    let deviceID = {
      device_id: this.state.deviceData.device_id,
    };

    const response = await devicesMethods.unRegisterDevice(deviceID);

    if (response.data.statusCode === 200) {
      this.fetchAllDriskDevices();
      this.setState({ deleteModal: false });
    }
  };

  redirectToUsers = (device) => {
    this.props.history.push({
      pathname: "/dashboard/connectedUsers",
      state: { device: device },
    });
  };

  render() {
    return (
      <div className="routing-main-wrap">
        <div className="page-head">
          <Snackbar
            open={this.state.open}
            autoHideDuration={3000}
            onClose={() => this.setState({ open: false })}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Alert
              // onClose={handleClose}
              severity="info"
              sx={{ width: "100%" }}
            >
              {this.state.message}
            </Alert>
          </Snackbar>
          <div className="page_head_btn">
            <h3>Registration</h3>
          </div>
          <div className="registration_btns">
            <ToggleSideNav batteryRefresh="false" />
          </div>
        </div>
        {this.state.connectedDevices?.length > 0 ||
        this.state.availableDevices?.length > 0 ? (
          <div className="rout-body">
            <div className="row">
              <div className="col-md-12">
                <h5 className="devices-title">Connected Device</h5>

                {this.state.connectedDevices.map((device, index) => (
                  <div
                    className="registration-device-details mr-t10"
                    key={index}
                  >
                    <div className="wifi_routers_main">
                      <div className="wifi_router-wrap">
                        <div className="col-1 col-sm-1 col-md-1 wifi_image_routers">
                          <svg
                            width="37"
                            height="37"
                            viewBox="0 0 37 37"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0)">
                              <path
                                d="M18.5 37C28.7173 37 37 28.7173 37 18.5C37 8.28273 28.7173 0 18.5 0C8.28273 0 0 8.28273 0 18.5C0 28.7173 8.28273 37 18.5 37Z"
                                fill="white"
                              />
                              <path
                                d="M14.3989 22.4847C13.5851 21.6587 12.6778 20.93 11.6957 20.3136C11.8773 19.1144 12.2332 17.9483 12.7523 16.8521C16.0001 18.6531 18.6364 21.3825 20.3235 24.6909C21.38 27.1127 24.2987 24.9584 23.5113 23.061C22.5441 21.0999 21.2818 19.2988 19.7685 17.7205L19.8465 17.6104C20.611 16.5402 21.2317 15.6733 21.9411 14.8401L21.9932 14.8905C24.5273 17.402 26.4158 20.4898 27.4973 23.8898C28.0064 26.1266 30.9985 25.0012 30.3151 22.4953C27.9973 13.9548 19.7654 7.01344 11.9878 6.10679C12.7324 5.34232 13.529 4.67417 14.3989 3.91736C15.4468 4.09121 16.4786 4.3505 17.4842 4.69255C19.0544 5.29495 20.8035 3.40217 19.1401 2.77225C16.4552 1.83654 13.5662 1.64943 10.7831 2.231C7.86883 2.92816 5.33323 4.71716 3.69945 7.22903C4.61375 6.17565 5.73548 5.32217 6.99452 4.72185C8.25356 4.12153 9.62287 3.7873 11.0169 3.74005C10.2376 4.45238 9.52894 5.23829 8.90082 6.08691C3.37681 6.7153 0.904535 10.3496 0.141602 15.8308C1.34028 7.81926 10.8855 6.42943 19.2884 12.5834C18.524 13.4747 17.8803 14.3721 17.1479 15.4042C14.7486 13.5299 11.9036 12.3109 8.89168 11.8663C5.84759 11.6201 4.83094 14.8522 7.64569 15.1886C8.33146 15.2547 9.00955 15.3842 9.67144 15.5754C9.18108 16.6495 8.8074 17.773 8.55685 18.9268C3.93185 17.7037 0.575957 20.3686 1.96728 25.5257C2.24096 26.391 3.34331 26.4675 3.30509 25.7321C3.22125 25.197 3.27246 24.6495 3.45402 24.1392C3.63559 23.629 3.94166 23.1721 4.34465 22.8103C6.27416 21.2248 9.6653 22.4785 12.0535 24.8438C14.4417 27.209 15.6726 30.6231 14.0871 32.5526C11.9833 35.1105 6.42244 32.7805 4.13058 28.3695C3.8248 27.6968 2.99769 27.9567 3.21326 28.4199C4.18675 30.0995 5.49427 31.5617 7.05499 32.7161C8.61571 33.8705 10.3966 34.6927 12.2875 35.1319C19.2243 36.2847 20.3388 28.3848 14.3989 22.4847Z"
                                fill="#00A2FF"
                              />
                              <path
                                d="M8.30816 30.6384C10.8676 31.8967 11.9119 29.3374 9.71334 27.1678C7.54533 24.9707 4.98282 26.0104 6.24265 28.5729C6.69874 29.4601 7.42094 30.1823 8.30816 30.6384Z"
                                fill="#C70000"
                              />
                              <path
                                d="M24.5076 27.6768C24.0581 27.5397 23.576 27.5528 23.1346 27.7142C22.6931 27.8755 22.3165 28.1765 22.0614 28.5712C21.9183 28.7197 21.8118 28.8997 21.7505 29.0966C21.6891 29.2935 21.6745 29.502 21.7079 29.7056C21.7413 29.9091 21.8218 30.1019 21.9429 30.2688C22.064 30.4358 22.2223 30.5723 22.4054 30.6673C22.8549 30.8041 23.3367 30.791 23.7781 30.6296C24.2194 30.4682 24.5963 30.1675 24.8516 29.7729C24.9947 29.6244 25.1012 29.4445 25.1626 29.2476C25.2239 29.0506 25.2383 28.8421 25.2049 28.6386C25.1715 28.435 25.0912 28.2421 24.9701 28.0751C24.8491 27.9081 24.6907 27.7718 24.5076 27.6768Z"
                                fill="#C70000"
                              />
                              <path
                                d="M21.97 6.7961L22.2207 6.94904C24.0554 7.94743 25.5492 6.03169 23.7787 4.9385C22.1978 4.07007 20.1368 5.51333 21.97 6.7961Z"
                                fill="#C70000"
                              />
                              <path
                                d="M28.0694 29.2962C27.9672 31.1657 27.2067 32.9385 25.9229 34.3013C24.6391 35.664 22.9147 36.5287 21.0547 36.7421C26.1292 36.0342 29.7328 33.8997 30.6762 28.7916C30.7847 26.6511 28.1061 27.4156 28.0694 29.2962Z"
                                fill="#C70000"
                              />
                              <path
                                d="M34.6526 26.1007C35.2061 23.479 35.0696 20.7584 34.2566 18.2053C34.6828 17.5732 35.0744 16.9184 35.4294 16.2437C36.5884 19.551 36.5074 23.1665 35.2015 26.4186C36.0064 24.743 36.5401 22.9502 36.7825 21.1071C36.9133 20.001 36.9133 18.8833 36.7825 17.7772C36.5446 13.165 34.6054 8.80481 31.3398 5.53922C28.0742 2.27362 23.714 0.33452 19.1019 0.0966677C17.9962 -0.0338502 16.8791 -0.0338502 15.7734 0.0966677C13.9304 0.339149 12.1376 0.872758 10.4619 1.67757C17.7411 -1.56528 29.5171 3.09489 34.4173 13.6261C34.0106 14.464 33.6527 15.1551 33.3316 15.7223C32.0414 12.8998 30.2341 10.3437 28.0033 8.18619C26.5264 6.82239 24.7392 8.75651 26.08 9.89709C26.1442 9.95213 26.4835 10.2778 26.5386 10.3374C33.486 17.4622 35.6999 28.0729 29.6515 33.1826C32.1642 31.5498 33.9543 29.0148 34.6526 26.1007Z"
                                fill="#C70000"
                              />
                              <path
                                d="M2.31571 16.7771C3.98683 15.2665 4.28808 12.3035 2.60015 13.4578C-0.242126 15.7818 -1.06933 20.3655 1.70261 26.1112C-0.0892886 22.1283 0.346457 18.699 2.31571 16.7771Z"
                                fill="#C70000"
                              />
                              <path
                                d="M20.9212 33.4757C20.8276 33.6394 20.7256 33.7982 20.6154 33.9513C18.8404 36.4297 15.1342 37.1374 10.7676 35.1743C16.8099 38.0793 21.5664 37.0228 23.7634 33.8182C24.8291 32.0813 22.0893 32.0783 20.9212 33.4757Z"
                                fill="#C70000"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0">
                                <rect width="37" height="37" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div className="col-11 col-sm-11 col-md-11 header_button_flex">
                          <div className="routers_headingname w-100">
                            <div className="head_btn_flex">
                              <div
                                className="head_btn_flex_headings"
                                onClick={() => this.redirectToUsers(device)}
                              >
                                <h5>{device.name}</h5>
                                {/* <h6>Time Connected 10.10 hrs</h6> */}
                              </div>
                              <div className="register_routers_button reg_rou_btn">
                                <div className="register_routers_button_content">
                                  {/* <h6>{device.phone_number}</h6> */}
                                  <p>Registered</p>
                                </div>
                                
                                <i
                                  className="fa fa-edit"
                                  aria-hidden="true"
                                  onClick={() => this.handleOpen(device)}
                                  style={{ color: "#fff" }}
                                ></i>
                                
                                <i
                                  className="fa fa-trash mr-l10"
                                  aria-hidden="true"
                                  onClick={() =>
                                    this.handleOpenDeleteModal(device)
                                  }
                                  style={{ color: "#fff" }}
                                ></i>
                              </div>
                            </div>

                            {/* <div className="row progress_bar_row">
                            <svg
                              width="652"
                              height="7"
                              viewBox="0 0 652 7"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="652"
                                width="6.48493"
                                height="652"
                                rx="3.24247"
                                transform="rotate(90 652 0)"
                                fill="#2196F3"
                              />
                              <rect
                                x="130"
                                width="6.48493"
                                height="130"
                                rx="3.24247"
                                transform="rotate(90 130 0)"
                                fill="#BC2835"
                              />
                            </svg>

                            <span>34.6 MB</span>
                          </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-md-12 mr-t20">
                <h5 className="devices-title">Available Device</h5>

                {this.state.availableDevices.map((device, index) => (
                  <div
                    className="registration-device-details mr-t10"
                    key={index}
                  >
                    <div className="wifi_routers_main">
                      <div className="wifi_router-wrap">
                        <div className="col-1 col-sm-1 col-md-1 wifi_image_routers">
                          <svg
                            width="37"
                            height="37"
                            viewBox="0 0 37 37"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0)">
                              <path
                                d="M18.5 37C28.7173 37 37 28.7173 37 18.5C37 8.28273 28.7173 0 18.5 0C8.28273 0 0 8.28273 0 18.5C0 28.7173 8.28273 37 18.5 37Z"
                                fill="white"
                              />
                              <path
                                d="M14.3989 22.4847C13.5851 21.6587 12.6778 20.93 11.6957 20.3136C11.8773 19.1144 12.2332 17.9483 12.7523 16.8521C16.0001 18.6531 18.6364 21.3825 20.3235 24.6909C21.38 27.1127 24.2987 24.9584 23.5113 23.061C22.5441 21.0999 21.2818 19.2988 19.7685 17.7205L19.8465 17.6104C20.611 16.5402 21.2317 15.6733 21.9411 14.8401L21.9932 14.8905C24.5273 17.402 26.4158 20.4898 27.4973 23.8898C28.0064 26.1266 30.9985 25.0012 30.3151 22.4953C27.9973 13.9548 19.7654 7.01344 11.9878 6.10679C12.7324 5.34232 13.529 4.67417 14.3989 3.91736C15.4468 4.09121 16.4786 4.3505 17.4842 4.69255C19.0544 5.29495 20.8035 3.40217 19.1401 2.77225C16.4552 1.83654 13.5662 1.64943 10.7831 2.231C7.86883 2.92816 5.33323 4.71716 3.69945 7.22903C4.61375 6.17565 5.73548 5.32217 6.99452 4.72185C8.25356 4.12153 9.62287 3.7873 11.0169 3.74005C10.2376 4.45238 9.52894 5.23829 8.90082 6.08691C3.37681 6.7153 0.904535 10.3496 0.141602 15.8308C1.34028 7.81926 10.8855 6.42943 19.2884 12.5834C18.524 13.4747 17.8803 14.3721 17.1479 15.4042C14.7486 13.5299 11.9036 12.3109 8.89168 11.8663C5.84759 11.6201 4.83094 14.8522 7.64569 15.1886C8.33146 15.2547 9.00955 15.3842 9.67144 15.5754C9.18108 16.6495 8.8074 17.773 8.55685 18.9268C3.93185 17.7037 0.575957 20.3686 1.96728 25.5257C2.24096 26.391 3.34331 26.4675 3.30509 25.7321C3.22125 25.197 3.27246 24.6495 3.45402 24.1392C3.63559 23.629 3.94166 23.1721 4.34465 22.8103C6.27416 21.2248 9.6653 22.4785 12.0535 24.8438C14.4417 27.209 15.6726 30.6231 14.0871 32.5526C11.9833 35.1105 6.42244 32.7805 4.13058 28.3695C3.8248 27.6968 2.99769 27.9567 3.21326 28.4199C4.18675 30.0995 5.49427 31.5617 7.05499 32.7161C8.61571 33.8705 10.3966 34.6927 12.2875 35.1319C19.2243 36.2847 20.3388 28.3848 14.3989 22.4847Z"
                                fill="#00A2FF"
                              />
                              <path
                                d="M8.30816 30.6384C10.8676 31.8967 11.9119 29.3374 9.71334 27.1678C7.54533 24.9707 4.98282 26.0104 6.24265 28.5729C6.69874 29.4601 7.42094 30.1823 8.30816 30.6384Z"
                                fill="#C70000"
                              />
                              <path
                                d="M24.5076 27.6768C24.0581 27.5397 23.576 27.5528 23.1346 27.7142C22.6931 27.8755 22.3165 28.1765 22.0614 28.5712C21.9183 28.7197 21.8118 28.8997 21.7505 29.0966C21.6891 29.2935 21.6745 29.502 21.7079 29.7056C21.7413 29.9091 21.8218 30.1019 21.9429 30.2688C22.064 30.4358 22.2223 30.5723 22.4054 30.6673C22.8549 30.8041 23.3367 30.791 23.7781 30.6296C24.2194 30.4682 24.5963 30.1675 24.8516 29.7729C24.9947 29.6244 25.1012 29.4445 25.1626 29.2476C25.2239 29.0506 25.2383 28.8421 25.2049 28.6386C25.1715 28.435 25.0912 28.2421 24.9701 28.0751C24.8491 27.9081 24.6907 27.7718 24.5076 27.6768Z"
                                fill="#C70000"
                              />
                              <path
                                d="M21.97 6.7961L22.2207 6.94904C24.0554 7.94743 25.5492 6.03169 23.7787 4.9385C22.1978 4.07007 20.1368 5.51333 21.97 6.7961Z"
                                fill="#C70000"
                              />
                              <path
                                d="M28.0694 29.2962C27.9672 31.1657 27.2067 32.9385 25.9229 34.3013C24.6391 35.664 22.9147 36.5287 21.0547 36.7421C26.1292 36.0342 29.7328 33.8997 30.6762 28.7916C30.7847 26.6511 28.1061 27.4156 28.0694 29.2962Z"
                                fill="#C70000"
                              />
                              <path
                                d="M34.6526 26.1007C35.2061 23.479 35.0696 20.7584 34.2566 18.2053C34.6828 17.5732 35.0744 16.9184 35.4294 16.2437C36.5884 19.551 36.5074 23.1665 35.2015 26.4186C36.0064 24.743 36.5401 22.9502 36.7825 21.1071C36.9133 20.001 36.9133 18.8833 36.7825 17.7772C36.5446 13.165 34.6054 8.80481 31.3398 5.53922C28.0742 2.27362 23.714 0.33452 19.1019 0.0966677C17.9962 -0.0338502 16.8791 -0.0338502 15.7734 0.0966677C13.9304 0.339149 12.1376 0.872758 10.4619 1.67757C17.7411 -1.56528 29.5171 3.09489 34.4173 13.6261C34.0106 14.464 33.6527 15.1551 33.3316 15.7223C32.0414 12.8998 30.2341 10.3437 28.0033 8.18619C26.5264 6.82239 24.7392 8.75651 26.08 9.89709C26.1442 9.95213 26.4835 10.2778 26.5386 10.3374C33.486 17.4622 35.6999 28.0729 29.6515 33.1826C32.1642 31.5498 33.9543 29.0148 34.6526 26.1007Z"
                                fill="#C70000"
                              />
                              <path
                                d="M2.31571 16.7771C3.98683 15.2665 4.28808 12.3035 2.60015 13.4578C-0.242126 15.7818 -1.06933 20.3655 1.70261 26.1112C-0.0892886 22.1283 0.346457 18.699 2.31571 16.7771Z"
                                fill="#C70000"
                              />
                              <path
                                d="M20.9212 33.4757C20.8276 33.6394 20.7256 33.7982 20.6154 33.9513C18.8404 36.4297 15.1342 37.1374 10.7676 35.1743C16.8099 38.0793 21.5664 37.0228 23.7634 33.8182C24.8291 32.0813 22.0893 32.0783 20.9212 33.4757Z"
                                fill="#C70000"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0">
                                <rect width="37" height="37" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div className="col-11 col-sm-11 col-md-11 header_button_flex">
                          <div className="routers_headingname w-100">
                            <div className="head_btn_flex">
                              <div
                                className="head_btn_flex_headings"
                                onClick={() => this.redirectToUsers(device)}
                              >
                                <h5>{device.name}</h5>
                                {/* <h6>Time Connected 10.10 hrs</h6> */}
                              </div>
                              <div className="register_routers_button reg_rou_btn">
                                <div className="register_routers_button_content">
                                  {/* <h6>{device.phone_number}</h6> */}
                                  <p>Registered</p>
                                </div>

                                <i
                                  className="fa fa-trash mr-l10"
                                  aria-hidden="true"
                                  onClick={() =>
                                    this.handleOpenDeleteModal(device)
                                  }
                                  style={{ color: "#fff" }}
                                ></i>
                              </div>
                            </div>
                            {/* <div className="row progress_bar_row">
                            <svg
                              width="652"
                              height="7"
                              viewBox="0 0 652 7"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="652"
                                width="6.48493"
                                height="652"
                                rx="3.24247"
                                transform="rotate(90 652 0)"
                                fill="#2196F3"
                              />
                              <rect
                                x="130"
                                width="6.48493"
                                height="130"
                                rx="3.24247"
                                transform="rotate(90 130 0)"
                                fill="#BC2835"
                              />
                            </svg>

                            <span>34.6 MB</span>
                          </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              paddingTop: "20px",
              display: "flex",
              justifyContent: "center",
              fontSize: "20px",
              color: "#BC2835",
            }}
          >
            No device connected
          </div>
        )}

        {this.state.editModal ? (
          <Modal
            open={this.state.editModal}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw"
              role="document"
            >
              <div className="modal-content modal_fp_content">
                <div className="modal-header forget_pw_header">
                  <button
                    type="button"
                    className="close modal_fp_close_btn"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.handleClose}
                  >
                    <span aria-hidden="true">
                      <svg
                        width="10"
                        height="18"
                        viewBox="0 0 10 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2.91937 9.00008L9.82886 1.70718L8.48899 0.292969L0.23964 9.00008L8.48899 17.7072L9.82886 16.293L2.91937 9.00008Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </button>
                  <h5
                    className="modal-title modal_fp_title "
                    id="exampleModalLabel"
                  >
                    Update Device Name
                  </h5>
                </div>

                <div className="modal-body fp_modal_body">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body">
                    <div className="fp_label">
                      <h6>
                        Enter new device name<em className="mandatory">* </em>
                      </h6>
                      <input
                        type="email"
                        className="fp_modal_input"
                        value={this.state.deviceName}
                        onChange={this.handleNameChange}
                      />
                    </div>
                    <div className="color-red">
                      <i>{this.state.errorModal}</i>
                    </div>
                  </div>
                </div>
                <div className="modal-footer footer_fp_modal">
                  <button
                    type="button"
                    className="btn-fp-footer btn-Reset"
                    onClick={this.editDeviceName}
                    disabled={this.state.loader}
                  >
                    Update
                    {this.state.loader ? (
                      <i className="fa fa-spinner fa-spin"></i>
                    ) : null}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}

        {this.state.deleteModal ? (
          <Modal
            open={this.state.deleteModal}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw"
              role="document"
            >
              <div className="modal-content modal_fp_content">
                <div className="modal-header forget_pw_header">
                  <button
                    type="button"
                    className="close modal_fp_close_btn"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.handleClose}
                  >
                    <span aria-hidden="true">
                      <svg
                        width="10"
                        height="18"
                        viewBox="0 0 10 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2.91937 9.00008L9.82886 1.70718L8.48899 0.292969L0.23964 9.00008L8.48899 17.7072L9.82886 16.293L2.91937 9.00008Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </button>
                  <h5
                    className="modal-title modal_fp_title "
                    id="exampleModalLabel"
                  >
                    Delete Device?
                  </h5>
                </div>

                <div className="modal-body fp_modal_body">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body mr-t20 mr-b10">
                    <h6 className="delete-dialog">
                      Are you sure you want to delete this device?
                      <br />
                      <br />
                      <i>Note: This action cannot be undone!</i>
                    </h6>
                  </div>
                </div>
                <div className="modal-footer footer_fp_modal">
                  <button
                    type="button"
                    className="btn-fp-footer btn-Reset"
                    onClick={this.deleteDevice}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}

        {this.state.showLogout ? (
          <Modal
            open={this.state.showLogout}
            aria-labelledby="DeleteUserProfile"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw"
              role="document"
            >
              <div className="modal-content modal_fp_content">
                <div className="modal-header forget_pw_header">
                  <h5
                    className="modal-title modal_fp_title "
                    id="exampleModalLabel"
                  >
                    Session Expired
                  </h5>
                </div>
                <div className="modal-body fp_modal_body">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body mr-t20 mr-b10">
                    <h6 className="delete-dialog">
                      Please login again.
                      <br />
                      <br />
                    </h6>
                  </div>
                </div>
                <div className="modal-footer footer_fp_modal">
                  <button
                    type="button"
                    className="btn-fp-footer btn-Reset"
                    onClick={this.logoutUser}
                    // disabled={this.state.loader}
                  >
                    Okay 
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default Registration;
