import { Avatar, IconButton, Input, InputAdornment } from "@material-ui/core";
import React, { Component, createRef } from "react";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import SendIcon from "@material-ui/icons/Send";
import { io } from "socket.io-client";
const socket = io(`${process.env.REACT_APP_NODE_APP_URL}`);

export default class ChatClass extends Component {
  state = {
    user: JSON.parse(
      sessionStorage.getItem("UserDetails")
        ? sessionStorage.getItem("UserDetails")
        : ""
    ),
    message: [],
    chatMessage: "",
  };
  handleRef = createRef(null);
  scrollRef = createRef(null);
  onSubmit = () => {
    console.log("submited");
    if (this.state.chatMessage.length) {
      this.setState({ chatMessage: "" });
      socket.emit("chat message", {
        msg: this.state.chatMessage,
        room: this.props.match.params.id,
        sender: this.state.user.tinode_username,
      });
    }
    // scrollToBottom();
  };
  joinRoom = () => {
    //   if (room !== "" && username !== "")
    socket.emit("join", {
      username: this.state.user.tinode_username,
      room: this.props.match.params.id,
    });
  };
  componentDidMount() {
    this.joinRoom();
    socket.on("load chat", (data) => {
      this.setState({ message: data });
    });
    socket.on("chat message", (data) => {
      //   this.setState({ message: data });
      console.log("chat message", data);
    });
  }

  componentWillUnmount() {
    socket.on("disconnect", (disconnect) => {});
  }
  render() {
    console.log(socket.active);
    return (
      <div className="routing-main-wrap">
        <div className="page-head text-center">
          <div className="d-flex align-item-center">
            <div className="mt-2 mr-2 ">
              <button
                type="button"
                className="back-btn-about-us"
                onClick={() => this.props.history.push("/dashboard/trusted")}
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M3 9.62109H20" stroke="white" strokeWidth="2" />
                  <path
                    d="M11.3103 1L2 10.3103L11.3103 19.6207"
                    stroke="white"
                    strokeWidth="2"
                  />
                </svg>
                
              </button>
            </div>
            <div>
              <Avatar alt={this.props.match.params.name}>
                {this.props.match.params.name
                  ? this.props.match.params.name
                  : ""}
              </Avatar>
            </div>
            <div className="mt-2 ml-3">
              <h3 className="mr-3">{this.props.match.params.name}</h3>
            </div>
          </div>
        </div>
        {true ? (
          <>
            <div className="rout-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="device-details h-80">
                    <div className="row chat" ref={this.scrollRef}>
                      {this.state.message.map((data, index) =>
                        data.sender !== this.state.user.tinode_username ? (
                          <div className="col-12 mt-2  p-0" key={index}>
                            <div className="col-8 p-0">
                              <div className="message-card">
                                <div>
                                  <span>{data?.sender}</span>
                                </div>
                                <div>
                                  <h6>
                                    {typeof data.msg == "string" ? (
                                      data.msg
                                    ) : data.msg.minetype !== undefined ? (
                                      <img src={data.msg.url} alt="" />
                                    ) : (
                                      ""
                                    )}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="col-12 mt-2 row justify-content-end p-0">
                            <div className="col-8 p-0 row justify-content-end mr-1">
                              <div className="message-card">
                                <div>
                                  <span>{data?.sender}</span>
                                </div>
                                <div>
                                  <h6>
                                    {" "}
                                    {typeof data.msg == "string" ? (
                                      data.msg
                                    ) : (
                                      <img
                                        src={data.msg.url}
                                        alt=""
                                        className=""
                                      />
                                    )}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>

                    <div className="bottom d-flex">
                      <Input
                        placeholder="Type message here...."
                        onChange={(event) => {
                          this.setState({ chatMessage: event.target.value });
                        }}
                        value={this.state.chatMessage}
                        fullWidth
                        type="outlined"
                        label="Message"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              // aria-label="toggle password visibility"
                              onClick={() => this.handleRef.current.click()}
                              // onMouseDown={handleMouseDownPassword}
                            >
                              <AttachFileIcon color="primary" />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <div>
                        <IconButton
                          className="send-btn"
                          onClick={this.onSubmit}
                        >
                          <SendIcon />
                        </IconButton>
                      </div>
                    </div>
                    <input
                      type={"file"}
                      ref={this.handleRef}
                      onChange={this.handleUpload}
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div
            style={{
              paddingTop: "20px",
              display: "flex",
              justifyContent: "center",
              fontSize: "20px",
              color: "#BC2835",
            }}
          >
            No device connected
          </div>
        )}
      </div>
    );
  }
}
