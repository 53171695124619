import React, { Component } from "react";
import VulnerabilityScan from "../../VulnerabilityScan/VulnerabilityScan";
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from "@material-ui/pickers";
import Moment from "@date-io/moment";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
class VulnerabilityScanHistory extends Component {
  state = {
    scheduleTime: moment("08:30 AM", "HH:mm A"),
    selectedDate: moment(),
    weekDays: [
      {
        id: 1,
        day: "S",
      },
      {
        id: 2,
        day: "M",
      },
      {
        id: 3,
        day: "T",
      },
      {
        id: 4,
        day: "W",
      },
      {
        id: 5,
        day: "T",
      },
      {
        id: 6,
        day: "F",
      },
      {
        id: 7,
        day: "S",
      },
    ],
  };

  onChangeScheduleTime = (hours) => {
    this.setState({
      scheduleTime: hours,
    });
  };

  handleDateChange = (date) => {};

  render() {
    return (
      <div>
        {/* <div className="registered-users mr-t20">
          <div className="text-center registered-title">
            <h6> Vulnerability Scan History </h6>
          </div>

          <div className="registered-user-table text-center table-responsive col-md-12">
            <table className="table table-bordered table-sm">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Targets</th>
                  <th>User</th>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Schedule 1</td>
                  <td>10.15.0.3, 10.15.0.10 - 10.15.3.32</td>
                  <td>James</td>
                  <td>12/28/2021</td>
                  <td>
                    Complete
                    <i className="fa fa-share mr-l10" aria-hidden="true"></i>
                  </td>
                </tr>
                <tr>
                  <td>Schedule 2</td>
                  <td>10.15.0.3, 10.15.0.10 - 10.15.3.32</td>
                  <td>Janelin</td>
                  <td>12/28/2021</td>
                  <td>
                    Pending
                    <i className="fa fa-share mr-l10" aria-hidden="true"></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> */}

        <div className="registered-users mr-t20 ">
          <div className="text-center">
            <div className="col-md-12 scan-msg text-center">
              <h4>Scanning in 16 hours 34 minutes</h4>
              <button type="button" className="scan-btn mr-t10">
                Scan Now
              </button>
            </div>

            <hr className="color-row" />

            <div className="timepicker mr-t30">
              <MuiPickersUtilsProvider utils={Moment}>
                <KeyboardTimePicker
                  openTo="hours"
                  id="time-picker"
                  KeyboardButtonProps={{
                    "aria-label": "change time",
                  }}
                  value={this.state.scheduleTime}
                  onChange={this.onChangeScheduleTime}
                />
              </MuiPickersUtilsProvider>
            </div>

            <hr className="color-row mr-t30" />

            <div className="row days-name">
              <span className="col-md-8">
                <h6>Every Sun, Mon, Tues</h6>
              </span>
              {/* <span className="col-md-4">
                <MuiPickersUtilsProvider utils={Moment}>
                  <KeyboardDatePicker
                    clearable
                    value={this.state.selectedDate}
                    placeholder="10/10/2018"
                    onChange={(date) => this.handleDateChange(date)}
                    minDate={new Date()}
                    format="MM/DD/yyyy"
                  />
                </MuiPickersUtilsProvider>
              </span> */}
            </div>

            <div className="days-name text-center col-md-12">
              <span className="row grid-icons-row mr-t20">
                <span className="c-check-input ex-check-input y-check">
                  <input type="checkbox" name="calendar" id="c10" />
                  <label htmlFor="c10">S</label>
                </span>
                <span className="c-check-input ex-check-input y-check">
                  <input type="checkbox" name="calendar" id="c20" />
                  <label htmlFor="c20">M</label>
                </span>
                <span className="c-check-input ex-check-input y-check">
                  <input type="checkbox" name="calendar" id="c30" />
                  <label htmlFor="c30">T</label>
                </span>
                <span className="c-check-input ex-check-input y-check">
                  <input type="checkbox" name="calendar" id="c40" />
                  <label htmlFor="c40">W</label>
                </span>
                <span className="c-check-input ex-check-input y-check">
                  <input type="checkbox" name="calendar" id="c50" />
                  <label htmlFor="c50">T</label>
                </span>
                <span className="c-check-input ex-check-input y-check">
                  <input type="checkbox" name="calendar" id="c60" />
                  <label htmlFor="c60">F</label>
                </span>
                <span className="c-check-input ex-check-input y-check">
                  <input type="checkbox" name="calendar" id="c70" />
                  <label htmlFor="c70">S</label>
                </span>
              </span>

              <div className="mr-t20 text-center text-button-save">
                <TextField id="standard-helperText" placeholder="Schedule Name" variant="standard" />
                <button type="button" className="scan-btn scan-btn-save mr-l10">
                  Save
                </button>
              </div>
            </div>

            <div className="mr-t30 text-center scanning-schedules">
              <h6> Scanning Schedules </h6>

              <div className="mr-t20 ">
                <ul className="black-scan-cards1 col-md-12">
                  <li className="mr-b10">
                    <div className="col-md-4">
                      <div className="schedule-label">
                        <label>8:30 AM</label>
                        <h4>Schedule Name</h4>
                      </div>
                    </div>
                    <div className="col-md-4 schedule-label-days">
                      {this.state.weekDays.map((data) => (
                        <div className="schedule-label-list" key={data.id}>
                          <label className="pd-l4">
                            <b>.</b>
                          </label>
                          <label>{data.day}</label>
                        </div>
                      ))}
                    </div>
                    <div className="col-md-2 mr-l70 mr-t20">
                      <div className="cus_trial_toggle">
                        <label className="switch switch-trusted">
                          <input type="checkbox" id="togBtn" defaultValue="is_cot_disable" />
                          <div className="slider round">
                            <span className="on">ON</span>
                            <span className="off">OFF</span>
                          </div>
                        </label>
                      </div>
                    </div>

                    {/* <div className="col-md-2 mr-t15">
                      <i className="fa fa-share" aria-hidden="true"></i>
                    </div> */}
                  </li>

                  <li>
                    <div className="col-md-4">
                      <div className="schedule-label">
                        <label>8:30 AM</label>
                        <h4>Schedule Name</h4>
                      </div>
                    </div>
                    <div className="col-md-4 schedule-label-days">
                      {this.state.weekDays.map((data) => (
                        <div className="schedule-label-list" key={data.id}>
                          <label className="pd-l4">
                            <b>.</b>
                          </label>
                          <label>{data.day}</label>
                        </div>
                      ))}
                    </div>
                    <div className="col-md-2 mr-l70 mr-t20">
                      <div className="cus_trial_toggle">
                        <label className="switch switch-trusted">
                          <input type="checkbox" id="togBtn1" defaultValue="ON" />
                          <div className="slider round">
                            <span className="on">ON</span>
                            <span className="off">OFF</span>
                          </div>
                        </label>
                      </div>
                    </div>

                    {/* <div className="col-md-2 mr-t15">
                      <i className="fa fa-share" aria-hidden="true"></i>
                    </div> */}
                  </li>
                </ul>
              </div>
            </div>

            <hr className="color-row mr-t30" />

            <div className="mr-t30 text-center scanning-schedules">
              <h6> Scan History </h6>
            </div>

            <div className="registered-user-table text-center table-responsive col-md-12">
              <table className="table table-bordered mr-t10 table-sm">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Target</th>
                    <th>User</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Scan 1</td>
                    <td>Device</td>
                    <td>Demo User</td>
                    <td>10/05/2021</td>
                    <td>
                      Pending
                      <i className="fa fa-share mr-l10" aria-hidden="true"></i>
                    </td>
                  </tr>
                  <tr>
                    <td>Scan 2</td>
                    <td>Device</td>
                    <td>Demo User</td>
                    <td>10/05/2021</td>
                    <td>
                      Pending
                      <i className="fa fa-share mr-l10" aria-hidden="true"></i>
                    </td>
                  </tr>
                  <tr>
                    <td>Scan 3</td>
                    <td>Device</td>
                    <td>Demo User</td>
                    <td>10/05/2021</td>
                    <td>
                      Pending
                      <i className="fa fa-share mr-l10" aria-hidden="true"></i>
                    </td>
                  </tr>
                  <tr>
                    <td>Scan 4</td>
                    <td>Device</td>
                    <td>Demo User</td>
                    <td>10/05/2021</td>
                    <td>
                      Pending
                      <i className="fa fa-share mr-l10" aria-hidden="true"></i>
                    </td>
                  </tr>
                  <tr>
                    <td>Scan 5</td>
                    <td>Device</td>
                    <td>Demo User</td>
                    <td>10/05/2021</td>
                    <td>
                      Pending
                      <i className="fa fa-share mr-l10" aria-hidden="true"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VulnerabilityScanHistory;
