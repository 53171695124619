import React, { Component } from "react";
import ToggleSideNav from "../UI/ToggleSideNav/ToggleSideNav";
import userMethods from "../../services/userApi";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";

class Help extends Component {
  state = {
    getData: [],
    showLogout: false,
  };

  componentDidMount() {
    this.fetchFAQ();
  }

  fetchFAQ = async () => {
    const response = await userMethods.getFAQ();

    if (response.data.statusCode === 200) {
      this.setState({ getData: response.data.result });
    }
    if (response.data.statusCode === 401) {
      this.setState({
        showLogout: true,
      });
    }
  };

  logoutUser = () => {
    window.sessionStorage.clear();
    this.props.history.replace({
      pathname: "/",
    });
  };

  render() {
    return (
      <div className="routing-main-wrap">
        <div className="page-head">
          <h3>Help</h3>
          <ToggleSideNav batteryRefresh="false" />
        </div>

        <div className="rout-body">
          {this.state.getData.length === 0 ? (
            <CircularProgress
              color="secondary"
              style={{ margin: "20% 0% 0% 50%" }}
            />
          ) : (
            this.state.getData.map((data, index) => (
              <div className="row mr-t10 col-md-12" key={index}>
                <Accordion className="help-questions">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>{data.question}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{data.answer}</Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))
          )}
        </div>
        {this.state.showLogout ? (
          <Modal
            open={this.state.showLogout}
            aria-labelledby="DeleteUserProfile"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw"
              role="document"
            >
              <div className="modal-content modal_fp_content">
                <div className="modal-header forget_pw_header">
                  <h5
                    className="modal-title modal_fp_title "
                    id="exampleModalLabel"
                  >
                    Session Expired
                  </h5>
                </div>
                <div className="modal-body fp_modal_body">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body mr-t20 mr-b10">
                    <h6 className="delete-dialog">
                      Please login again.
                      <br />
                      <br />
                    </h6>
                  </div>
                </div>
                <div className="modal-footer footer_fp_modal">
                  <button
                    type="button"
                    className="btn-fp-footer btn-Reset"
                    onClick={this.logoutUser}
                    // disabled={this.state.loader}
                  >
                    Okay
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default Help;
